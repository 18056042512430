export const MAINTENANCE_STATUS_ROUTES = {
  account: false,
  event: false,
  exam: false,
  rank: false,
  statistic: false,
  enrollment: false,
  content: false,
  store: false,
  order: false,
  payment: false,
  all: false
};
