import Vue from "vue";
import Vuex from "vuex";
import VueClipboard from "vue-clipboard2";
import VueNumber from "vue-number-animation";
import Buefy from "buefy";
import VueYoutube from "vue-youtube";
import VueScrollTo from "vue-scrollto";
import VueLazyload from "vue-lazyload";
import PinchZoom from "vue-pinch-zoom";

import ModalGeneral from "@/components/modal/ModalGeneral";
import Input from "@/components/Input/Input";
import Field from "@/components/Input/Field";
import Select from "@/components/Input/Select";
import AutoComplete from "@/components/Input/AutoComplete";

import App from "./App.vue";
import store from "./store";
import router from "./router";
import { setVueInstance } from "@/utils/plugins";
import "./icons"; // icon
import "./permission";
import "swiper/swiper-bundle.css";
import SnackbarProgrammatic from "./components/Snackbar";
import NotificationProgrammatic from "./components/Notification";
import MessageProgrammatic from "./components/Message";

// // register all global components
// const registerAllGlobalComponents = () => {
//   const req = require.context('./components', true, /\.vue$/)
//   req.keys().map(item => {
//     let name = '';
//     const splittedPath = item.split('/');
//     // const filename = splittedPath[-1]
//     if (splittedPath[splittedPath.length - 1] === 'index.vue') {
//       // move one directory,
//       // ex /Modal/index.vue, then the name should be Modal
//       name = splittedPath[splittedPath.length - 2]
//     } else {
//       name = splittedPath[splittedPath.length - 1]
//     }

//     Vue.component(name, () => import(item))
//   })
// }
// registerAllGlobalComponents()

const firebase = window.firebase;

navigator.serviceWorker
  .register("firebase-messaging-sw.js", { scope: "firebase-cloud-messaging-push-scope" })
  .then(registration => {
    const messaging = firebase.messaging();
    registration.update();
    messaging.useServiceWorker(registration);
  })
  .catch(err => {
    console.error(err);
  });

Vue.use(VueNumber);
Vue.use(VueLazyload);
Vue.use(VueYoutube);
Vue.use(Vuex);
Vue.use(VueClipboard);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
Vue.use(SnackbarProgrammatic);
Vue.use(NotificationProgrammatic);
Vue.use(MessageProgrammatic);

Vue.use(Buefy, {
  defaultIconPack: "fas"
});

Vue.component("ModalGeneral", ModalGeneral);
Vue.component("CustomInput", Input);
Vue.component("CustomField", Field);
Vue.component("CustomSelect", Select);
Vue.component("CustomAutoComplete", AutoComplete);
Vue.component("pinch-zoom", PinchZoom);

Vue.config.productionTip = false;

const vueInstance = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");

setVueInstance(vueInstance);
