<script>
export default {
  name: "ReportSemesterReferral",
  props: {
    referral: {
      type: String,
      default: ""
    }
  }
};
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <p :class="[$style.text, $style.first]">
        <span :class="$style.highlight">Mau dapet 7 hari GRATIS</span><br />Full Akses 1.400+
        Rangkuman<br />dan 14.000+ Bank Soal?
      </p>
      <p :class="[$style.text, $style.second]">
        Yuk daftar Buwhan Edu<br />dan pakai kode di bawah!
      </p>

      <div :class="$style.referral">{{ referral }}</div>
    </div>
    <p :class="[$style.text, $style.third]">
      Yang ngajak dan diajak,<br />semua dapet GRATIS 7 hari! <span>❤</span>
    </p>
  </div>
</template>

<style lang="scss" module>
.container {
  background: url("https://assets.edukasystem.com/semester-report/bg_referral.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.content {
  width: 100%;
  padding: 8rem 1.5rem 0;

  @media (min-width: 426px) {
    padding: 5rem 1.5rem 0;
  }
}

.text {
  width: 100%;
  text-align: center;
  font-weight: 600;
  .highlight {
    font-weight: 800;
    font-size: 1.25rem;
  }

  &.second {
    margin-top: 2rem;
  }

  &.third {
    position: absolute;
    bottom: 56px;

    @media (min-width: 426px) {
      bottom: 24px;
    }
    span {
      color: #ff3527;
    }
  }
}

.referral {
  margin: 1.75rem auto 0;
  background: #ffffff;
  border-radius: 16px;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 800;

  width: fit-content;
  min-width: 256px;
  text-align: center;
}
</style>
