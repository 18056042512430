// initial state
const state = {
  concept_title: "",
  show_modal_reminder: false,
  fromConcept: false,
  show_drawer: true
};

// getters
const getters = {
  getConceptTitle: state => state.concept_title,
  isShowModalReminder: state => state.show_modal_reminder,
  getFromConcept: state => state.fromConcept,
  isShowDrawer: state => state.show_drawer
};

// mutations
const mutations = {
  setConceptTitle(state, payload) {
    state.concept_title = payload;
  },
  setModalReminder(state, payload) {
    state.show_modal_reminder = payload;
  },
  setFromConcept(state, payload) {
    state.fromConcept = payload;
  },
  setDrawer(state, payload) {
    state.show_drawer = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
