import Vue from "vue";

const INIT_ORDER_VUEX = {
  subtotal: 0,
  baseSubTotal: 0,
  numItem: 1,
  fillDataLater: false,
  selfRegistrant: false,
  registrant: [],
  paymentChannelId: null,
  useCoin: false,
  voucher: null,
  step: 0 // 0 for detail and 1 for payment in responsive mobile view,
};

const getDefaultState = () => {
  return {
    currentOrder: { ...INIT_ORDER_VUEX }
  };
};

const state = getDefaultState();

const getters = {
  getCurrentOrder: state => state.currentOrder
};

const mutations = {
  setCurrentOrder(state, payload) {
    Vue.set(state.currentOrder, payload.key, payload.value);
  },
  setInitialOrder(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
