import service from "@/utils/request";
export default class Account {
  static baseGroupURL = "/account";

  static async reverifyUserPhone(id_user) {
    const resp = await service.post(`${this.baseGroupURL}/user/${id_user}/verification/phone`);
    return resp;
  }

  static async verifyOTPCode(id_user, otp_code) {
    const resp = await service.post(
      `${this.baseGroupURL}/user/${id_user}/verification/phone/${otp_code}`
    );
    return resp;
  }

  static async postSendChangePasswordForm(data) {
    const resp = await service.post(`${this.baseGroupURL}/user/password/reset`, data);
    return resp;
  }

  static async getDayTrialOTP() {
    const resp = await service.get(`${this.baseGroupURL}/user/day-free-trial-otp`);
    return resp;
  }

  static async getDayTrialReferral() {
    const resp = await service.get(`${this.baseGroupURL}/user/day-free-trial-referral`);
    return resp;
  }
}
