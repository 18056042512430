import Vue from "vue";
import User from "@/api/user";
import { getUserId } from "@/utils/user";
import { getLSObjectWithExpiry } from "@/utils/general";

// initial state
const state = {
  loading: false,
  timeout: false,
  logoutConfirmation: false,
  dashboardMenu: "tryout-list",
  dashboardSubmenu: "utbk",
  user_id: "",
  name: "",
  onboardingStep: [0, 0, 0],
  statusTimer: "",
  remainTimer: 0,
  durationTimer: 0,
  pomodoroTimer: false,
  routerFrom: "",
  showModalLoginFree: false,
  showModalSaveProgress: false
};

// getters
const getters = {
  getLoadingStatus: state => state.loading,
  getTimeoutStatus: state => state.timeout,
  getLogoutConfirmation: state => state.logoutConfirmation,
  getDashboardMenu: state => state.dashboardMenu,
  getDashboardSubmenu: state => state.dashboardSubmenu,
  getName: state => state.name,
  getOnboardingStep: state => state.onboardingStep,
  getStatusTimer: state => state.statusTimer,
  getRemainTimer: state => state.remainTimer,
  getDurationTimer: state => state.durationTimer,
  getPomodoroTimer: state => state.pomodoroTimer,
  getRouterFrom: state => state.routerFrom,
  isShowModalLoginFree: state => state.showModalLoginFree,
  isShowModalSaveProgress: state => state.showModalSaveProgress
};

// mutations
const mutations = {
  setLoadingStatus(state) {
    state.loading = !state.loading;
  },
  // eslint-disable-next-line
  setLoadingStatus(state, payload) {
    state.loading = payload.val;
  },
  setTimeoutStatus(state, payload) {
    state.timeout = payload.val;
  },
  setLogoutConfirmation(state, payload) {
    state.logoutConfirmation = payload;
  },
  setDashboardMenu(state, payload) {
    state.dashboardMenu = payload.val;
  },
  setDashboardSubmenu(state, payload) {
    state.dashboardSubmenu = payload.val;
  },
  setName(state, payload) {
    state.name = payload.val;
  },
  setOnboardingStep(state, payload) {
    Vue.set(state.onboardingStep, payload.idx, payload.val);
  },
  setStatusTimer(state, payload) {
    state.statusTimer = payload;
  },
  setRemainTimer(state, payload) {
    state.remainTimer = payload;
  },
  setDurationTimer(state, payload) {
    state.durationTimer = payload;
  },
  setPomodoroTimer(state, payload) {
    state.pomodoroTimer = payload;
  },
  setRouterFrom(state, payload) {
    state.routerFrom = payload;
  },
  setModalLoginFree(state, payload) {
    state.showModalLoginFree = payload;
  },
  setModalSaveProgress(state, payload) {
    if (payload) {
      const date = new Date();
      let dataLS = getLSObjectWithExpiry(`noRemindSaveProgress:${getUserId()}`, date.getTime());
      if (dataLS) {
        const { data, expired } = dataLS;
        if (expired || !data) {
          state.showModalSaveProgress = payload;
        }
      }
    } else {
      state.showModalSaveProgress = payload;
    }
  }
};

// Actions
const actions = {
  toggleLoadingStatus({ commit }) {
    commit("setLoadingStatus");
  },
  setLoadingStatus({ commit }, val) {
    commit("setLoadingStatus", {
      val
    });
  },
  setTimeoutStatus({ commit }, val) {
    commit("setTimeoutStatus", {
      val
    });
  },
  setDashboardMenu({ commit }, val) {
    commit("setDashboardMenu", {
      val
    });
  },
  setDashboardSubmenu({ commit }, val) {
    commit("setDashboardSubmenu", {
      val
    });
  },
  setName({ commit }, val) {
    commit("setName", {
      val
    });
  },
  setOnboardingStep({ commit }, payload) {
    commit("setOnboardingStep", {
      idx: payload.idx,
      val: payload.val
    });
  },
  incrementOnboardingStep({ getters, commit }, idx) {
    const val = getters.getOnboardingStep[idx] + 1;
    commit("setOnboardingStep", {
      idx,
      val
    });
  },
  async fetchName({ dispatch, commit }) {
    try {
      const userDetailsRes = await User.getUserDetails();

      let firstname = userDetailsRes.data.data.firstname;
      let lastname = userDetailsRes.data.data.lastname;

      if (firstname && lastname) {
        dispatch("setNameByFirstnameAndLastName", { firstname, lastname });
      } else {
        const usernameRes = await User.getUser();
        commit("setName", {
          val: usernameRes.data.data.username
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  setNameByFirstnameAndLastName({ commit }, val) {
    const { firstname, lastname } = val;
    let lowerFirstname = firstname.trim().toLowerCase();
    let lowerLastname = lastname.trim().toLowerCase();

    let fullname = `${lowerFirstname} ${lowerLastname}`;

    // guard for empty string after split
    fullname = fullname
      .split(" ")
      .map(el => (el.length > 0 ? `${el[0].toUpperCase()}${el.slice(1, el.length)}` : ""))
      .join(" ");
    commit("setName", {
      val: fullname
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
