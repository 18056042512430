import General from "@/api/general";
const DEFAULT_DP_STATUS = ["rank", "statistic"];

const toTitleCase = phrase => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const routes = [
  {
    path: "/rapot",
    name: "all-report",
    component: () => import(/* webpackChunkName: "report" */ "@/views/Report"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      type_page: "main",
      dp_status: [...DEFAULT_DP_STATUS],
      background_color: "grey",
      page_name: "Nilai Keseluruhan",
      is_secondary_navbar: true,
      use_breadcrumb: "show",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Nilai Keseluruhan Tryout",
          routeName: "all-report"
        }
      ]
    }
  },
  {
    path: "/rapot/rekomendasi",
    name: "recommendation",
    redirect: "/rapot/rekomendasi",
    component: () =>
      import(/* webpackChunkName: "recommendation" */ "@/views/Recommendation/index.vue"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      dp_status: [...DEFAULT_DP_STATUS],
      background_color: "light-grey"
    },
    children: [
      {
        path: "",
        name: "smart-recommendation",
        component: () =>
          import(
            /* webpackChunkName: "smart-recommendation" */ "@/views/Recommendation/SmartRecommendation.vue"
          ),
        meta: {
          whitelist_trial: true,
          background_color: "light-grey",
          page_name: "Smart Recommendation",
          use_breadcrumb: "show",
          breadcrumb: [
            {
              name: "Beranda",
              routeName: "home"
            },
            {
              name: "Smart Recommendation",
              routeName: "smart-recommendation"
            }
          ]
        }
      },
      {
        path: "statistik/:id_campus/:id_major",
        name: "statistic-tryout",
        component: () =>
          import(
            /* webpackChunkName: "statistic-tryout" */ "@/views/Recommendation/StatisticTryout.vue"
          ),
        meta: {
          background_color: "grey",
          title_params: true,
          page_name: () => "Statistik Nilai Tryout",
          use_breadcrumb: "show",
          breadcrumb: async route => {
            const major = await General.getMajor({
              ids: parseInt(route.params.id_major)
            }).then(res => res.data.data[0].major_name);
            const campus = await General.getCampus({
              ids: parseInt(route.params.id_campus)
            }).then(res => res.data.data[0].campus_name);
            const title = `${major} - ${campus}`;

            const result = [
              {
                name: "Beranda",
                routeName: "home"
              },
              {
                name: "Smart Recommendation",
                routeName: "smart-recommendation"
              },
              {
                name: "Statistik Nilai Tryout",
                routeName: "smart-recommendation"
              },
              {
                name: toTitleCase(title)
              }
            ];
            return result;
          }
        }
      },
      {
        path: "passing-grade/:id_major_category",
        name: "passing-grade",
        component: () =>
          import(/* webpackChunkName: "passing-grade" */ "@/views/Recommendation/PassingGrade.vue"),
        meta: {
          background_color: "light-grey",
          title_params: true,
          page_name: () => "Informasi Passing Grade",
          use_breadcrumb: "show",
          breadcrumb: async route => {
            const majorCategory = await General.getMajorCategory({
              ids: parseInt(route.params.id_major_category)
            }).then(res => res.data.data[0].major_category_name);
            const result = [
              {
                name: "Beranda",
                routeName: "home"
              },
              {
                name: "Smart Recommendation",
                routeName: "smart-recommendation"
              },
              {
                name: "Rekomendasi Jurusan dan Kampus",
                routeName: "smart-recommendation"
              },
              {
                name: toTitleCase(majorCategory)
              }
            ];
            return result;
          }
        }
      }
    ]
  }
];

export default routes;
