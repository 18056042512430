<script>
export default {
  name: "PageFooter",
  data() {
    return {
      nameApp: process.env.VUE_APP_NAME,
      footer_menu: [
        // {
        //   sectionName: "Tentang Eduka",
        //   children: [
        //     {
        //       type: "link",
        //       linkLabel: "Profil Perusahaan",
        //       name: "profile",
        //       link: `/`
        //     },
        //     {
        //       type: "link",
        //       linkLabel: "Eduka Premium",
        //       name: "premium",
        //       link: `/pricing`
        //     },
        //     {
        //       type: "link",
        //       linkLabel: "Sobat Eduka",
        //       name: "sobat",
        //       out: true,
        //       link: `${process.env.VUE_APP_SOBAT}`
        //     },
        //     {
        //       type: "link",
        //       linkLabel: "Karir",
        //       name: "karir",
        //       link: `/career`
        //     },
        //     {
        //       type: "link",
        //       linkLabel: "Eduka untuk Sekolah",
        //       name: "school",
        //       link: `/sekolah`
        //     }
        //   ]
        // },
        {
          sectionName: "Fitur",
          children: [
            {
              type: "link",
              linkLabel: "Rangkuman",
              link: `/lihat-caranya/rangkuman`
            },
            {
              type: "link",
              linkLabel: "Quiz",
              link: `/lihat-caranya/quiz`
            },
            {
              type: "link",
              linkLabel: "Tryout",
              link: `/lihat-caranya/tryout`
            },
            {
              type: "link",
              linkLabel: "Live Class",
              link: `/lihat-caranya/live-class`
            },
            {
              type: "link",
              linkLabel: "Rekomendasi Belajar",
              link: `/lihat-caranya/rekomendasi-belajar`
            },
            {
              type: "link",
              linkLabel: "Rekomendasi Jurusan & Kampus",
              link: `/lihat-caranya/rekomendasi-jurusan`
            }
          ]
        },
        {
          sectionName: "Hubungi Kami",
          children: [
            {
              type: "icon-group",
              children: [
                {
                  type: "icon",
                  out: true,
                  name: "instagram",
                  link: `https://www.instagram.com/edukasystem/`
                },
                {
                  type: "icon",
                  out: true,
                  name: "twitter",
                  link: `https://twitter.com/EdukaSystem`
                },
                {
                  type: "icon",
                  out: true,
                  name: "tiktok",
                  link: `https://www.tiktok.com/@edukasystem`
                },
                {
                  type: "icon",
                  out: true,
                  name: "linkedin",
                  link: `https://www.linkedin.com/company/edukasystem/`
                },
                {
                  type: "icon",
                  out: true,
                  name: "youtube",
                  link: `https://www.youtube.com/channel/UCfEfNcw_USZ65D9M7oyJTKQ`
                }
              ]
            },
            {
              type: "link",
              linkLabel: "Partnership",
              name: "partnership",
              link: `/partnership`
            },
            {
              type: "link",
              out: true,
              linkLabel: "FAQ",
              name: "faq",
              link: `https://drive.google.com/drive/folders/1cy1GGuwpf3upvrNO1t0DTvJPgfAdOqnD`
            }
          ]
        }
      ]
    };
  }
};
</script>

<template>
  <footer class="footer__container">
    <div class="footer">
      <section class="footer__section">
        <figure class="footer__logo-container">
          <img
            class="footer__logo"
            alt="BuwhanEdu-logo"
            src="https://assets.edukasystem.com/logo/buwhanedu-logo-white@3x.png"
          />
        </figure>
        <p class="footer__section-desc">
          Media belajar berbasis teknologi yang fokus untuk meningkatkan keterampilan siswa
          Indonesia dalam memecahkan masalah yang kompleks.
        </p>
      </section>
      <section class="footer__section" v-for="(el, idx) in footer_menu" :key="`footer-sec-${idx}`">
        <h3 class="footer__section-title">{{ el.sectionName }}</h3>
        <template v-for="(element, index) in el.children">
          <div v-if="element.children" class="footer__icon-group" :key="`item-footer-${index}`">
            <template v-for="(item, id) in element.children">
              <a
                v-if="item.out"
                class="footer__link"
                :class="{
                  'footer__link--icon': item.type === 'icon',
                  footer__icon: item.type === 'icon'
                }"
                :href="item.link"
                :key="`item-footer-child-${id}`"
              >
                <svg-icon
                  v-if="item.type === 'icon' && item.name !== nameApp"
                  :icon-class="item.name"
                />
                <template v-else-if="item.name !== nameApp">
                  {{ item.linkLabel }}
                </template>
              </a>
              <router-link
                v-else
                class="footer__link"
                :class="{
                  'footer__link--icon': item.type === 'icon',
                  footer__icon: item.type === 'icon'
                }"
                :to="item.link"
                :key="`item-footer-child-${id}`"
              >
                <svg-icon
                  v-if="item.type === 'icon' && item.name !== nameApp"
                  :icon-class="item.name"
                />
                <template v-else-if="item.name !== nameApp">
                  {{ item.linkLabel }}
                </template>
              </router-link>
            </template>
          </div>
          <template v-else>
            <template v-if="element.out">
              <a
                :class="{
                  footer__link: element.type !== 'icon',
                  'footer__link--icon': element.type === 'icon',
                  footer__icon: element.type === 'icon'
                }"
                :href="element.link"
                :key="`item-footer-${index}`"
                v-if="element.type === 'icon' && element.name !== nameApp"
              >
                <svg-icon :icon-class="element.name" />
              </a>
              <a
                v-else-if="element.name !== nameApp"
                class="footer__link"
                v-html="element.linkLabel"
                :key="`item-footer-${index}`"
                :href="element.link"
              >
              </a>
            </template>
            <template v-else>
              <router-link
                :class="{
                  footer__link: element.type !== 'icon',
                  'footer__link--icon': element.type === 'icon',
                  footer__icon: element.type === 'icon'
                }"
                :to="element.link"
                :key="`item-footer-${index}`"
                v-if="element.type === 'icon' && element.name !== nameApp"
              >
                <svg-icon :icon-class="element.name" />
              </router-link>
              <router-link
                v-else-if="element.name !== nameApp"
                class="footer__link"
                v-html="element.linkLabel"
                :key="`item-footer-${index}`"
                :to="element.link"
              />
            </template>
          </template>
        </template>
      </section>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  border-radius: 32px 32px 0px 0px;
  width: 95%;
  height: 100%;
  display: grid;
  margin: 0 auto;
  grid-template-columns: minmax(auto, 30%) 1fr 1fr 1fr;
  background-color: $purple;
  color: white;
  padding: 5rem 11rem;
  @include max-media(small-tablet) {
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    padding: 3rem 1.5rem 6rem;
  }
  @include max-media(mobile) {
    grid-template-columns: 1fr;
    padding: 1rem 0;
  }
  &__container {
    background-color: none;
  }
  &__link {
    margin: 0.5rem 0;
    color: white;
    &:hover {
      text-decoration: none;
    }
    &--icon {
      margin: 0;
      color: $black-grey;
    }
  }
  &__section {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    @include max-media(mobile) {
      align-items: center;
      padding: 0;
      margin-bottom: 16px;
    }
    &-title {
      font-weight: bold;
      font-size: 1.3rem;
      display: flex;
      flex-direction: row;
      &.logo {
        font-family: $lora;
        font-size: 18px;
        margin-bottom: 0.5rem;
      }
    }

    &-desc {
      margin-top: 1.5rem;

      @include max-media(mobile) {
        font-size: 0.875rem;
        text-align: center;
      }
    }
  }
  &__logo {
    width: 100%;
    max-width: 130px;

    @include max-media(mobile) {
      max-width: 100px;
    }
    &-container {
      width: 100%;
      @include max-media(mobile) {
        text-align: center;
      }
    }
  }
  &__link {
    &--icon {
      font-size: 36px;
    }
  }
  &__icon {
    &-group {
      margin: 0;
      width: 100%;
      display: flex;
      gap: 1rem;
      height: fit-content;
      place-items: center;
      color: $black-grey;
      @include max-media(mobile) {
        justify-content: center;
      }
    }
  }
}
</style>
