import Order from "@/api/order";
import Store from "@/api/store";

import { getUserId } from "@/utils/user";
import { getTypeItemSales } from "@/utils/pay";

const routes = [
  {
    path: "/eduka-pay",
    name: "pay",
    component: () => import(/* webpackChunkName: "eduka-pay" */ "@/views/Pay/index.vue"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      type_page: "main",
      page_name: "Pembelian",
      hide_back_button: true,
      mobile_navbar: "purple",
      use_whatsapp: true
    }
  },
  {
    path: "/search-sales",
    name: "search-sales",
    component: () => import(/* webpackChunkName: "search-sales" */ "@/views/Search/SearchSales"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "sticky-on-phone-size",
      page_name: "Pembelian",
      mobile_navbar: "purple",
      use_whatsapp: true
    }
  },
  {
    path: "/payment-history",
    name: "payment-history",
    meta: {
      requires_auth: true,
      type_navbar: "login",
      page_name: "Riwayat Pembelian",
      use_breadcrumb: "show",
      background_color: "grey",
      use_whatsapp: true,
      back_to: {
        name: "pay"
      },
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Pembelian",
          routeName: "pay"
        },
        {
          name: "Riwayat Pembelian",
          routeName: "payment-history"
        }
      ]
    },
    component: () => import(/* webpackChunkName: "payment-history" */ "@/views/PaymentHistory")
  },
  {
    path: "/payment-history/:id_order/detail",
    name: "payment-history-detail",
    component: () =>
      import(/* webpackChunkName: "promo-detail" */ "@/views/PaymentHistory/Detail.vue"),
    meta: {
      requires_auth: true,
      page_name: "Detail Pembelian",
      use_breadcrumb: "show",
      background_color: "grey",
      back_to: {
        name: "payment-history"
      },
      use_whatsapp: true,
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Pembelian",
          routeName: "pay"
        },
        {
          name: "Riwayat Pembelian",
          routeName: "payment-history"
        },
        {
          name: "Detail Pembelian",
          routeName: "payment-history-detail"
        }
      ]
    },
    beforeEnter: async (to, from, next) => {
      const { id_order } = to.params;
      if (id_order) {
        await Order.checkOrderStatus(id_order).then(res => res.data.data);

        // Handle edge case for enroll (backend issue)
        const res = await Order.getOrderById(id_order).then(res => res.data.data);
        if (
          !res.canceled_at &&
          !res.expired_at &&
          res.paid_at &&
          res.details.registrant?.length > 0 &&
          !res.archived_at
        ) {
          Order.enrollOrder(id_order);
        }
        next();
      } else {
        next({ name: "payment-history" });
      }
    }
  },
  {
    path: "/promo",
    name: "all-promo",
    component: () => import(/* webpackChunkName: "all-promo" */ "@/views/Promo"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      page_name: "Promo",
      mobile_navbar: "purple",
      use_breadcrumb: "show",
      use_whatsapp: true,
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Pembelian",
          routeName: "pay"
        },
        {
          name: "Daftar Promo",
          routeName: "all-promo"
        }
      ]
    }
  },
  {
    path: "/promo/:id_promo",
    name: "promo-detail",
    component: () => import(/* webpackChunkName: "promo-detail" */ "@/views/Promo/PromoDetail"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      page_name: "Detail Promo",
      mobile_navbar: "purple",
      use_breadcrumb: "show",
      background_color: "grey",
      use_whatsapp: true,
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Pembelian",
          routeName: "pay"
        },
        {
          name: "Daftar Promo",
          routeName: "all-promo"
        },
        {
          name: "Detail Promo",
          routeName: "promo-detail"
        }
      ]
    }
  },
  {
    path: "/promo-usage",
    name: "promo-usage",
    component: () => import(/* webpackChunkName: "promo-detail" */ "@/views/Promo/PromoUsage"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      page_name: "Voucher/Referral",
      mobile_navbar: "purple",
      use_whatsapp: true,
      back_to: {
        name: "payment-order"
      }
    },
    beforeEnter: async (to, from, next) => {
      const { item_sales } = to.query;
      if (item_sales) {
        const res = await Store.fetchById(item_sales).then(res => res.data.data);
        const type = await getTypeItemSales(res);

        if (type === "comparison" || type === "premium") {
          next();
        } else {
          next({ name: "pay" });
        }
      } else {
        next({ name: "pay" });
      }
    }
  },
  {
    path: "/payment-order",
    name: "payment-order",
    component: () => import(/* webpackChunkName: "payment-order" */ "@/views/PaymentOrder"),
    redirect: to => ({
      name: "payment-order-detail",
      query: {
        item_sales: to.query.item_sales
      }
    }),
    children: [
      {
        path: "detail",
        name: "payment-order-detail",
        component: () =>
          import(/* webpackChunkName: "payment-order-detail" */ "@/views/PaymentOrder/Detail"),
        meta: {
          whitelist_trial: true,
          requires_auth: true,
          type_navbar: "sticky-on-phone-size",
          page_name: "Detail Pesanan",
          mobile_navbar: "purple",
          background_color: "grey",
          use_whatsapp: true,
          hide_whatsapp_mobile: true
        },
        beforeEnter: async (to, from, next) => {
          // Handle in progress order guard (ONLY for paid order)
          let valid = true;
          const res = await Order.fetchInProgressOrder({ ids_user: getUserId() }).then(
            res => res.data.data
          );

          if (res.length > 0) {
            valid = !res.find(el => el.payment_option.channel_name !== "free");
          }

          if (!valid) {
            next({ name: "payment-order-confirmation", params: { id_order: res[0].id_order } });
          } else {
            // no in progress order
            const { item_sales } = to.query;
            if (item_sales) {
              const res = await Store.fetchById(item_sales).then(res => res.data.data);
              const type = await getTypeItemSales(res);

              if (type === "comparison" || type === "premium") {
                next();
              } else {
                next({ name: "pay" });
              }
            } else {
              next({ name: "pay" });
            }
          }
        }
      },
      {
        path: "confirmation/:id_order",
        name: "payment-order-confirmation",
        component: () =>
          import(
            /* webpackChunkName: "payment-order-confirmation" */ "@/views/PaymentOrder/Confirmation"
          ),
        meta: {
          requires_auth: true,
          type_navbar: "sticky-on-phone-size",
          page_name: "Detail Pesanan",
          mobile_navbar: "purple",
          background_color: "grey",
          use_whatsapp: true,
          hide_whatsapp_mobile: true
        },
        beforeEnter: async (to, from, next) => {
          const { id_order } = to.params;
          if (id_order) {
            // MUST GET check-status for automatically order!
            const res = await Order.checkOrderStatus(id_order).then(res => res.data.data);
            const res2 = await Order.getOrderById(id_order).then(res => res.data.data);

            // Handle edge case for enroll (backend issue)
            if (
              !res2.canceled_at &&
              !res2.expired_at &&
              res2.paid_at &&
              res2.details.registrant?.length > 0 &&
              !res2.archived_at
            ) {
              Order.enrollOrder(id_order);
            }

            if (res2.paid_at) {
              next({ name: "payment-order-finish", params: { id_order } });
            } else if (
              res.transaction_status === "pending" ||
              (!res2.canceled_at && !res2.expired_at)
            ) {
              next();
            } else {
              next({ name: "pay" });
            }
          } else {
            next({ name: "pay" });
          }
        }
      },
      {
        path: "finish/:id_order",
        name: "payment-order-finish",
        component: () =>
          import(/* webpackChunkName: "payment-order-finish" */ "@/views/PaymentOrder/Finish"),
        meta: {
          requires_auth: true,
          type_navbar: "sticky-on-phone-size",
          page_name: "Detail Pesanan",
          mobile_navbar: "purple",
          background_color: "grey",
          use_whatsapp: true,
          hide_whatsapp_mobile: true
        },
        beforeEnter: async (to, from, next) => {
          const { id_order } = to.params;
          if (id_order) {
            const res = await Order.getOrderById(id_order).then(res => res.data.data);

            if (res.paid_at) {
              next();
            } else {
              next({ name: "pay" });
            }
          } else {
            next({ name: "pay" });
          }
        }
      }
    ]
  },
  {
    path: "/payment-comparison",
    name: "payment-comparison",
    component: () =>
      import(/* webpackChunkName: "comparison" */ "@/views/PaymentComparison/index.vue"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "sticky-on-phone-size",
      page_name: "Pembelian",
      mobile_navbar: "purple",
      use_whatsapp: true
    },
    beforeEnter: async (to, from, next) => {
      const { item_sales } = to.query;
      if (item_sales) {
        next();
      } else {
        next({ name: "pay" });
      }
    }
  },
  {
    path: "/payment-order-free",
    name: "payment-order-free",
    component: () =>
      import(/* webpackChunkName: "payment-order-free" */ "@/views/PaymentOrderFree/index.vue"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "sticky-on-phone-size",
      page_name: "Pembelian",
      mobile_navbar: "purple",
      background_color: "grey",
      use_whatsapp: true,
      hide_whatsapp_mobile: true
    },
    beforeEnter: async (to, from, next) => {
      const { item_sales } = to.query;
      if (item_sales) {
        const res = await Store.fetchById(item_sales).then(res => res.data.data);
        const type = await getTypeItemSales(res);

        if (type === "comparison" || type === "free") {
          next();
        } else {
          next({ name: "pay" });
        }
      } else {
        next({ name: "pay" });
      }
    }
  },
  {
    path: "/premium-detail",
    name: "payment-premium-detail",
    component: () =>
      import(/* webpackChunkName: "comparison" */ "@/views/PaymentPremiumDetail/index.vue"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "sticky-on-phone-size",
      type_page: "main",
      page_name: "Pembelian",
      hide_navbar_phone: true,
      use_whatsapp: true
    },
    beforeEnter: async (to, from, next) => {
      const { item_sales } = to.query;
      if (item_sales) {
        const res = await Store.fetchById(item_sales).then(res => res.data.data);
        const type = await getTypeItemSales(res);

        if (type === "comparison" || type === "free") {
          next();
        } else {
          next({ name: "pay" });
        }
      } else {
        next({ name: "pay" });
      }
    }
  }
];

export default routes;
