const getInitialState = () => {
  return {
    modalShown: false
  };
};

const state = getInitialState();

const getters = {
  isModalShown(state) {
    return state.modalShown;
  }
};

const mutations = {
  toggleModalShown(state) {
    state.modalShown = !state.modalShown;
  },
  resetState(state) {
    Object.assign(state, getInitialState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
