import service from "@/utils/request";

export default class Auth {
  static baseGroupURL = "auth";

  static async validateToken(token) {
    const res = await service.post(`${this.baseGroupURL}/verification`, {
      token
    });
    return res;
  }

  static async blacklistToken(token) {
    const res = await service.post(`${this.baseGroupURL}/blacklist`, { token });
    return res;
  }

  static async loginUsername(data) {
    const res = service.post(`${this.baseGroupURL}/username`, data);
    return res;
  }

  static async loginEmail(data) {
    const res = service.post(`${this.baseGroupURL}/email`, data);
    return res;
  }

  static async checkDefaultPasswordEmail(data) {
    const res = service.post(`${this.baseGroupURL}/email/check`, data);
    return res;
  }

  static async checkDefaultPasswordUsername(data) {
    const res = service.post(`${this.baseGroupURL}/username/check`, data);
    return res;
  }

  // Trial user (login)
  static async loginTrial() {
    const res = service.post(`${this.baseGroupURL}/free`);
    return res;
  }
}
