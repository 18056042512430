export const FREE_TRIAL_CONTENTS = [
  {
    step: 8,
    text: "<p>Kamu juga dapat menguji pemahaman Rangkuman yang sudah kamu baca tadi secara cepat dengan mengerjakan <b>Quiz</b> di buwhan edu, loh!</p>",
    title: "Quiz",
    mascot: require("@/assets/gif/Burhan-Megaphone.gif"),
    image: "quiz"
  },
  {
    step: 10,
    text: "<p>Berdasarkan nilai TO kamu, Buwhan juga bakal kasih rekomendasi mata pelajaran apa aja yang masih perlu kamu tingkatkan!</p>",
    title: "Rekomendasi Belajar",
    mascot: require("@/assets/gif/Buwhan-dadah-Flipped.gif"),
    image: "recommendation",
    alterImage: true
  },
  {
    step: 11,
    text: "<p>Ngerasa jadwal kamu padat? Tenang aja, Buwhan dapat memudahkan kamu mengerjakan tryout tanpa batas, bisa kapanpun, loh!</p>",
    title: "Masa Pengerjaan TO Tanpa Batas!",
    mascot: require("@/assets/gif/Buwhan-dadah-Flipped.gif"),
    image: "masa-pengerjaan"
  },
  {
    step: 12,
    text: "<p>Pingin dapet penjelasan yang lebih dalam dari soal TO?</p><p>Kamu bisa juga diskusikan tiap soal TO dengan peserta lain, lho! Ada tutor juga yang siap membantu kamu berdiskusi :)</p>",
    title: "Diskusi Pembahasan Soal Yuk!",
    mascot: require("@/assets/gif/Buwhan-dadah-Flipped.gif"),
    image: "discussion"
  },
  {
    step: 13,
    text: "<p>Masih belum ngerasa cukup juga belajar dengan berdiskusi sama peserta lain?</p><p>Tenang aja! Di buwhan edu juga ada LIVE CLASS yang bakal membahas bersama-sama TO yang kamu kerjakan!</p>",
    title: "LIVE CLASS",
    mascot: require("@/assets/gif/Buwhan-dadah-Flipped.gif"),
    image: "live-class"
  }
];

export const FEATURES = [
  {
    id: 1,
    feature: "rangkuman",
    icon: "landing-rangkuman",
    title: "Rangkuman Ciamik",
    desc: "Dapatkan rangkuman materi yang  lengkap dengan desain yang menarik! Cocok banget untuk kamu review materi.",
    link: "BankSoalDashboard"
  },
  {
    id: 2,
    feature: "quiz",
    icon: "landing-quiz",
    title: "Quiz",
    desc: "Uji pemahaman Rangkuman yang sudah kamu baca secara cepat dengan mengerjakan Quiz!",
    link: "BankSoalDashboard"
  },
  {
    id: 3,
    feature: "tryout",
    icon: "landing-utbk",
    title: "Tryout UTBK dengan soal yang berkualitas",
    desc: "Soal tryout UTBK dibuat oleh alumni PTN terbaik dengan sistem penilaian IRT agar kamu masuk PTN impian!",
    link: "my-tryout"
  },
  {
    id: 4,
    feature: "live-class",
    icon: "landing-live-class",
    title: "Live Class",
    desc: "Kamu bisa belajar langsung bersama Tutor dan teman-teman yang lain, supaya kamu lebih memahami materi!",
    link: "my-tryout"
  },
  {
    id: 5,
    feature: "rekomendasi-belajar",
    icon: "landing-rekomendasi",
    title: "Rekomendasi Belajar",
    desc: "Evaluasi tryout makin mudah dengan Rekomendasi Belajar! Buwhan akan kasih tahu materi yang perlu kamu pelajari lebih lanjut!",
    larger: true,
    link: "my-tryout"
  },
  {
    id: 6,
    feature: "rekomendasi-jurusan",
    icon: "landing-campus",
    title: "Rekomendasi Jurusan & Kampus",
    desc: "Kamu bisa mendapatkan rekomendasi jurusan dan kampus berdasarkan tes kepribadian dan juga hasil TO kamu.",
    link: "recommendation"
  }
];

export const USER_TESTIMONIES = [
  [
    {
      author: "Indira Laksmi Maharani",
      text: `Eduka adalah platform TO favorit aku. Dari TO pertama sampai TO terakhir aku ikutin karena emang sebagus itu. Soal-soalnya HOTS susah bukan main, tetapi karena itulah aku jadi terbiasa mengerjakan soal sulit sehingga saat UTBK pun aku bisa lancar mengerjakannya. Selain itu, ada fitur rankingnya juga yang bisa memprediksi posisi kita. Jadi semakin yakin dengan pilihanku. Terima kasih Eduka ❤️`,
      uni: "Pendidikan Dokter - Universitas Indonesia",
      src: "https://assets.edukasystem.com/testimony/indira-circle.png"
    },
    {
      author: "Muhammad Rafkarilo Afi",
      text: `Eduka adalah alasan saya ketagihan melakukan tryout untuk persiapan SBMPTN. Tryout pertama dan terakhir saya adalah Eduka untuk urusan SBMPTN. Soal Eduka System sangatlah menantang dan membuat saya lebih berpikir dalam menjawab suatu soal. Nilai Eduka System saya berprogress mulai dari 400-an sampai menyentuh angka 700-an. Saya ingin berterima kasih terhadap Eduka sekali lagi, salut untuk Buwhan.`,
      uni: "Ilmu Politik - Universitas Indonesia",
      src: "https://assets.edukasystem.com/testimony/rafkarilo-circle.png"
    }
  ],
  [
    {
      author: "Putri Kirana",
      text: `Soal Try Out-nya sangat menantang dengan pembobotan yang menyesuaikan dengan IRT system seperti halnya UTBK. Pembahasan yang cukup mudah dipahami dengan tampilan website yang terus menerus diperbaiki hingga semakin menarik.`,
      uni: "Ilmu Hukum - Universitas Airlangga",
      src: "https://assets.edukasystem.com/testimony/putri-circle.png"
    },
    {
      author: "Ni Komang Widia Sentani",
      text: `Awalnya sih coba-coba tapi kelamaan malah ketagihan, setiap dikerjain rasanya dag dig dug serrrr apakah ini namanya jatuh cinta? Emang se-worth it itu tryout di Eduka. Nyaman banget parah sampe mau meninggal, ga ngerti lagi. astungkara, Aku bisa lolos karna aku juga setia ngikutin tryout di Eduka. Btw, Buwhan sehumble itu. Terima kasih buwhan sudah menemani perjuanganku & tidak lupa sudah collab dengan Ayah Fauzan.`,
      uni: "Ekonomi Pembangunan - Universitas Indonesia",
      src: "https://assets.edukasystem.com/testimony/nikomang-circle.png"
    }
  ],
  [
    {
      author: "Farrel Arif Muhammad",
      text: `Pertama kali ikut TO Burhan bulan Januari. First impression awalnya kayak, "Gila, soalnya sulit bener". Terus akhirnya jadi makin tertantang buat terus belajar dan ngejar materi-materi yang kurang. Terima kasih Buwhan, berkat ikut TO-mu, aku diterima di FKH Unair dengan skor UTBK yang menurutku lumayan memuaskan. Ayo yang belum pernah ikut TO Eduka System, buruan gih ikutan!! `,
      uni: "Kedokteran Hewan - Universitas Airlangga",
      src: "https://assets.edukasystem.com/testimony/farel-circle.png"
    },
    {
      author: "Esther Valentina",
      text: `Aku ikut TO buat persiapan SBMPTN cuma Eduka doang, soalnya rekomen temen katanya biar pola pikirku terasah, soalnya dia tahun lalu juga pake Eduka dan dapet kampus yang dia pengen. Puji Tuhan waktu UTBK nih dapet rata-rata 551. Big thanks to Eduka yang udah nyediain TO nih buat aku pejuang SBMPTN 2020, love you Buwhan Eduka!`,
      uni: "Biologi - Universitas Brawijaya",
      src: "https://assets.edukasystem.com/testimony/esther-circle.png"
    }
  ],
  [
    {
      author: "Dia Ayu Candraningrum",
      text: `Eduka dari zaman UTBK 2019 sampai sekarang tryout nya menampar banget buat aku yang males. Apalagi sekarang udah kuliah, kalo les juga bakal keluar uang banyak. Akhirnya aku join tryout Eduka lagi. Tiap habis tryout aku analisis kesalahanku di materi apa, aku pahami pembahasannya. Hasil monitoring tiap tryout Eduka, nilaiku meningkat karena belajar dari kesalahan. Terima kasih Buwhan tercintah <3`,
      uni: "Teknik Fisika - Universitas Gadjah Mada",
      src: "https://assets.edukasystem.com/testimony/dia-circle.png"
    },
    {
      author: "Putu Dyan Septianiw",
      text: `Pertama kenal Eduka System itu tahun kemaren. Bersyukur banget tanpa les aku bisa belajar banyak di Eduka. Soal-soalnya dibikin biar kita harus rajin mikir dan pembahasannya yang dibuat sejelas mungkin. Dua tahun lulus SBMPTN di PTN ternama karena Eduka. Sayang bangett sama Eduka System! Recommended deh!! 💕💕💕`,
      uni: "Ilmu Gizi - Ilmu Pertanian Bogor",
      src: "https://assets.edukasystem.com/testimony/putu-circle.png"
    }
  ],
  [
    {
      author: "Atsiilah Salmaa N.",
      text: `Eduka, tryout paling keren di dunia pertryoutan. Soal dan pembahasannya tuh kerenn bgt, dan aku belajar banyakk dari eduka. Tak lupa, perankingannya tuh seru bgt, jadi semakin tertantang buat naikin progress! The best lah pokoknya Eduka! Terima Kasih Eduka!`,
      uni: "Kedokteran Hewan - Universitas Gadjah Mada",
      src: "https://assets.edukasystem.com/testimony/astiilah-circle.png"
    },
    {
      author: "Bunga Ayu Amalia",
      text: `Eduka System sangat membantu proses belajar masuk universiatas saya. Soal-soalnya dibuat oleh ahlinya. Ngerjain soal eduka berasa ngerjain soal UTBK beneran. Sangat worth it untuk menguji kemampuanmu dalam menghadapi UTBK!`,
      uni: "Pendidikan Sejarah - Universitas Pendidikan Indonesia",
      src: "https://assets.edukasystem.com/testimony/bunga-circle.png"
    }
  ]
];

export const INTRO_CONTENTS = [
  {
    title: "Ngerasa ga ada waktu belajar karena kebanyakan tugas sekolah?",
    desc: "Pernah gak sih kamu udah niat merangkum buat persiapan ujian, tapi nggak sempat karena tugas dari sekolah menumpuk, banyak ujian praktek, dan terdistraksi sama orang lain?",
    src: "https://assets.edukasystem.com/landing-integrated/Ilustrasi-no-time.png"
  },
  {
    title: "Selesai latihan soal, bingung harus belajar apa?",
    desc: "Pernah gak sih kamu selesai latihan soal langsung niat belajar buat review materi soal yang salah, tapi nggak tahu itu materi apa? Buwhan Edu bisa tahu lho!",
    src: "https://assets.edukasystem.com/landing-integrated/Ilustrasi-confused.png"
  },
  {
    title: "Kamu udah rajin belajar sendiri tapi ngerasa masih bingung?",
    desc: "Pernah gak sih kamu baca materi sendiri, tapi belum cukup ngerti dan rasanya ingin punya teman ambis dan tutor yang bisa kamu tanya secara langsung?",
    src: "https://assets.edukasystem.com/landing-integrated/Ilustrasi-confused-2.png"
  }
];

export const MILESTONE_CONTENTS = [
  {
    text: "Pengguna",
    number: "800.000+",
    src: "users"
  },
  {
    text: "Soal Variatif",
    number: "20.000+",
    src: "questions"
  },
  {
    text: "Pembuat Soal dari PTN ternama",
    number: "100+",
    src: "ptn"
  }
];

export const TUTORIAL_DETAILS = [
  {
    feature: "rangkuman",
    title: "Rangkuman Ciamik",
    desc: "<p>Di Buwhan Edu, Buwhan bakal ngasih kamu Rangkuman Ciamik! Terutama buat kamu yang selalu sibuk sama tugas sekolah maupun yang nggak sempet buat bikin rangkuman.<br><br>Tunggu apa lagi? Yuk, pake Buwhan Edu Sekarang!</p>",
    icon: "landing-rangkuman",
    tutorial: {
      title: "Nih, cara buat nemuin Rangkuman Ciamik di Buwhan Edu!",
      steps: [
        {
          text: "Pilih Mata Pelajaran di Bank Soal",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rangkuman&Quiz-Step 1.png"
        },
        {
          text: "Pilih bab yang ingin kamu pelajari",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rangkuman&Quiz-Step 2.png"
        },
        {
          text: "Pilih Rangkuman",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rangkuman-Step 3.png"
        },
        {
          text: "Kamu siap untuk belajar!",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rangkuman-Step 4.png"
        }
      ]
    }
  },
  {
    feature: "quiz",
    title: "Quiz",
    desc: "<p>Uji kemampuanmu setelah belajar. Quiz ini cocok banget buat persiapan kamu sebelum ngerjain Tryout!<br><br>Tunggu apa lagi? Yuk, pake Buwhan Edu Sekarang!</p>",
    icon: "landing-quiz",
    tutorial: {
      title: "Nih, cara buat nemuin Quiz di Buwhan Edu",
      steps: [
        {
          text: "Pilih Mata Pelajaran di Bank Soal",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rangkuman&Quiz-Step 1.png"
        },
        {
          text: "Pilih bab yang ingin kamu pelajari",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rangkuman&Quiz-Step 2.png"
        },
        {
          text: "Pilih Quiz ",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Quiz-Step 3.png"
        },
        {
          text: "Kerjakan sampai selesai ya!",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Quiz-Step 4.png"
        }
      ]
    }
  },
  {
    feature: "tryout",
    title: "Tryout",
    desc: "<p>Soal tryout dibuat oleh alumni PTN terbaik dengan sistem penilaian IRT untuk membantu kamu masuk PTN impian!<br><br>Tunggu apa lagi? Yuk, Daftar Buwhan Edu!</p>",
    icon: "landing-utbk",
    tutorial: {
      title: "Nih, cara buat nemuin Tryout di Buwhan Edu",
      steps: [
        {
          text: "Pilih Tryout yang sudah terdaftar",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Tryout-Step 1.png"
        },
        {
          text: "Jika sudah siap pilih “Mulai Kerjakan”",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Tryout-Step 2.png"
        },
        {
          text: "Jangan lupa, dibaca ketentuannya ya",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Tryout-Step 3.png"
        },
        {
          text: "Setelah itu, kerjakan deh. Jika sudah selesai, pilih tombol “Selesai”",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Tryout-Step 4.png"
        }
      ]
    }
  },
  {
    feature: "live-class",
    title: "Live Class",
    desc: "<p>Masih bingung sama hasil pengerjaan kamu? Tenang aja, di Live Class kamu bakal dijelasin soal yang kamu masih bingungin sampai kamu bisa, bersama Tutor Buwhan Edu.<br><br>Tunggu apa lagi? Yuk, Daftar Buwhan Edu!</p>",
    icon: "landing-live-class",
    tutorial: {
      title: "Gimana sih caranya ikut Live Class? Yuk simak tutorialnya!",
      steps: [
        {
          text: "Buka halaman pembahasan Tryout dan scroll ke paling bawah",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Live Class-Step 1.png"
        },
        {
          text: "Cek email kamu untuk mendapatkan link Live Class",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Live Class-Step 2.png"
        },
        {
          text: "Tutor Buwhan Edu siap untuk membantumu dalam belajar dan diskusi mengenai soal Tryout.",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Live Class-Step 3.png",
          landscape: true
        }
      ]
    }
  },
  {
    feature: "rekomendasi-belajar",
    title: "Rekomendasi Belajar",
    desc: "<p>Evaluasi tryout makin mudah dengan Rekomendasi Belajar! Buwhan akan kasih tahu materi mana yang perlu kamu pelajari lebih lanjut!<br><br>Tunggu apa lagi? Yuk, Daftar Buwhan Edu!</p>",
    icon: "landing-rekomendasi",
    larger: true,
    tutorial: {
      title: "Nih, caranya biar kamu tau Rekomendasi Belajar Buwhan Edu!",
      steps: [
        {
          text: "Buka halaman Rapor dan pilih ‘Lihat Nilai Keseluruhan’",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rekomendasi Belajar-Step 1.png"
        },
        {
          text: "Pilih hasil Tryout yang ingin kamu lihat",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rekomendasi Belajar-Step 2.png"
        },
        {
          text: "Kamu akan masuk ke halaman Ranking. Pilih tab ‘Statistik’",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rekomendasi Belajar-Step 3.png"
        },
        {
          text: "Di bagian bawah terdapat rekomendasi belajar dari hasil TOmu!",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rekomendasi Belajar-Step 4.png"
        }
      ]
    }
  },
  {
    feature: "rekomendasi-jurusan",
    title: "Rekomendasi Jurusan & Kampus",
    desc: "<p>Kamu bisa mendapatkan rekomendasi jurusan dan kampus berdasarkan tes kepribadian dan juga hasil TO kamu, hanya di Buwhan Edu.<br><br>Tunggu apa lagi? Yuk, Daftar Buwhan Edu!</p>",
    icon: "landing-campus",
    tutorial: {
      title: "Nih, caranya biar kamu tau Rekomendasi Jurusan & Kampus!",
      steps: [
        {
          text: "Pilih Tes Kepribadian dan Jurusan, di bagian Rekomendasi Jurusan dan Kampus",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rekomendasi Jurusan-Step 1.png"
        },
        {
          text: "Pilih Mulai Mengerjakan, dan kerjakan sampai selesai",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rekomendasi Jurusan-Step 2.png"
        },
        {
          text: "Pilih Smart Recommendation, di bagian Rekomendasi Jurusan dan Kampus",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rekomendasi Jurusan-Step 3.png"
        },
        {
          text: "Setelah itu, lihat hasilnya di bagian Rekomendasi Jurusan & Kampus ya!",
          img: "https://assets.edukasystem.com/landing-integrated/tutorial/Rekomendasi Jurusan-Step 4.png"
        }
      ]
    }
  }
];

export const PAKET_BELAJAR = [
  {
    type: "free",
    title: "Gratis",
    desc: "Buat kamu Sobat Buwhan yang pengen coba-coba dulu fitur Buwhan Edu, tetap bisa coba dulu kok!",
    features: [
      {
        name: "Pengerjaan Tryout",
        check: true
      },
      {
        name: "Tes Kepribadian dan Jurusan",
        check: true
      },
      {
        name: "Ranking dan Statistik",
        check: true
      },
      {
        name: "Pembahasan Lengkap",
        check: false
      },
      {
        name: "Live Class",
        check: false
      },
      {
        name: "Video Pembahasan",
        check: false
      },
      {
        name: "Rekomendasi Belajar",
        check: false
      },
      {
        name: "Rekomendasi Jurusan dan Kampus",
        check: false
      },
      {
        name: "Diskusi Soal",
        check: false
      }
    ]
  },
  {
    type: "premium",
    title: "Premium",
    desc: "Paket Premium sangat cocok buat kamu yang ingin jadi juara!",
    features: [
      {
        name: "Pengerjaan Tryout",
        check: true
      },
      {
        name: "Tes Kepribadian dan Jurusan",
        check: true
      },
      {
        name: "Ranking dan Statistik",
        check: true
      },
      {
        name: "Pembahasan Lengkap",
        check: true
      },
      {
        name: "Live Class",
        check: true
      },
      {
        name: "Video Pembahasan",
        check: true
      },
      {
        name: "Rekomendasi Belajar",
        check: true
      },
      {
        name: "Rekomendasi Jurusan dan Kampus",
        check: true
      },
      {
        name: "Diskusi Soal",
        check: true
      }
    ]
  }
];

export const PAKET_PROMO = [
  {
    id: process.env.VUE_APP_ENVIRONMENT == "development" ? 181 : 195,
    title: "Rangkuman dan Bank Soal SAINTEK/SOSHUM",
    img: "promo-1",
    features: [
      "<p>Rangkuman & latihan soal TKA <b>(Saintek atau Soshum)</b></p>",
      "<p>Rangkuman & latihan soal TPS</p>"
    ],
    price_course: "Rp666 per hari",
    price: "Rp 60.000,-",
    prev_price: "Rp 90.000,-",
    is_mix: true,
    redirect: `${process.env.VUE_APP_EDUKASYSTEM}/search-sales?product=quiz`
  },
  {
    id: process.env.VUE_APP_ENVIRONMENT == "development" ? 181 : 197,
    title: "Rangkuman dan Bank Soal Campuran",
    img: "promo-1",
    features: [
      "<p>Rangkuman & latihan soal TKA <b>(Saintek dan Soshum)</b></p>",
      "<p>Rangkuman & latihan soal TPS</p>"
    ],
    price_course: "Rp888 per hari",
    price: "Rp 80.000,-",
    prev_price: "Rp 120.000,-",
    redirect: `${process.env.VUE_APP_EDUKASYSTEM}/payment-order-detail?item_sales=197`
  },
  {
    id: process.env.VUE_APP_ENVIRONMENT == "development" ? 181 : 231,
    title: "NGEBUT Lengkap",
    img: "promo-2",
    features: [
      "<p>Rangkuman & latihan soal TKA <b>(Saintek atau Soshum)</b></p>",
      "<p>Rangkuman & latihan soal TPS</p>",
      "<p><b>4x Tryout Premium</b> di buwhan edu</p>"
    ],
    hide_price_course: true,
    price: "Rp 259.000,-",
    prev_price: "Rp 735.000,-",
    most_recomended: true,
    redirect: `${process.env.VUE_APP_EDUKASYSTEM}/payment-order-detail?item_sales=217`
  }
];

export const TEACHER_BENEFITS = [
  {
    title: "Data & Statistik Dashboard",
    text: "Dapatkan hasil ujian dan tugas yang dikerjakan oleh siswa dalam satu sistem terpadu beserta statistik nilai ujian dan tryout untuk evaluasi belajar.",
    icon: "data-statistic"
  },
  {
    title: "Platform Tugas & Ujian",
    text: "Guru dapat memberikan tugas, ujian, maupun latihan soal harian kepada siswa melalui platform yang disediakan oleh Buwhan Edu.",
    icon: "assignment"
  },
  {
    title: "Stok Bank Soal",
    text: "Buwhan Edu menyediakan bank soal yang dapat digunakan oleh guru untuk tugas siswa. Guru tidak perlu lagi membuat soal baru untuk tugas siswa.",
    icon: "question-bank"
  }
];

export const STUDENT_BENEFITS = [
  {
    title: "Rangkuman Lengkap",
    content:
      "Siswa mendapatkan materi rangkuman yang lengkap dengan desain yang menarik. Mereka akan mudah mereview materi.",
    action: "Lihat selengkapnya",
    link: "/lihat-caranya/rangkuman"
  },
  {
    title: "Quiz",
    content:
      "Siswa dapat menguji pemahaman rangkuman yang telah dipelajari dengan mengerjakan Mini Quiz dan juga Quiz.",
    action: "Lihat selengkapnya",
    link: "/lihat-caranya/quiz"
  },
  {
    title: "Tryout UTBK",
    content:
      "Soal tryout UTBK dibuat oleh alumni PTN terbaik dengan sistem penilaian IRT untuk membantu siswa masuk PTN impian.",
    action: "Lihat selengkapnya",
    link: "/lihat-caranya/tryout"
  },
  {
    title: "Live Class",
    content:
      "Siswa dapat belajar langsung bersama Tutor dan teman-teman yang lain untuk memahami materi secara mendalam.",
    action: "Lihat selengkapnya",
    link: "/lihat-caranya/live-class"
  },
  {
    title: "Rekomendasi Belajar",
    content:
      "Rekomendasi Belajar akan membantu siswa mengevaluasi tryout yang sudah dikerjakan untuk mengetahui materi apa yang perlu ditingkatkan.",
    action: "Lihat selengkapnya",
    link: "/lihat-caranya/rekomendasi-belajar"
  },
  {
    title: "Rekomendasi Jurusan & Kampus",
    content:
      "Siswa bisa mendapatkan rekomendasi jurusan dan kampus berdasarkan tes kepribadian dan juga hasil tryout siswa.",
    action: "Lihat selengkapnya",
    link: "/lihat-caranya/rekomendasi-jurusan"
  }
];

export const TESTIMONY = [
  {
    desc: "Eduka System sangat membantu proses belajar masuk universitas saya. Soal- soalnya dibuat oleh ahlinya. Ngerjain soal eduka berasa ngerjain soal UTBK beneran. Sangat worth it untuk menguji kemampuanmu dalam menghadapi UTBK!",
    photo_url: "https://assets.edukasystem.com/testimony/Bunga.png",
    name: "Bunga Ayu Amalia",
    major: "Pendidikan Sejarah - UPI"
  },
  {
    desc: "Soal Try Out-nya sangat menantang dengan pembobotan yang menyesuaikan dengan IRT system seperti halnya UTBK. Pembahasan yang cukup mudah dipahami dengan tampilan website yang terus menerus diperbaiki hingga semakin menarik.",
    photo_url: "https://assets.edukasystem.com/testimony/Putri.png",
    name: "Putri Kirana",
    major: "Ilmu Hukum - Unair"
  },
  {
    desc: "Eduka, tryout paling keren di dunia pertryoutan. Soal dan pembahasannya tuh kerenn bgt, dan aku belajar banyakk dari eduka. Tak lupa, perankingannya tuh seru bgt, jadi semakin tertantang buat naikin progress! The best lah pokoknya Eduka! Terima Kasih Eduka!",
    photo_url: "https://assets.edukasystem.com/testimony/Atsiilah.png",
    name: "Atsiilah Salmaa N.",
    major: "Kedokteran Hewan - UGM"
  }
];

export const ABOUT = [
  {
    icon: "about_user",
    number: "890.649",
    unit: "Total Pengguna"
  },
  {
    icon: "about_question",
    number: "20.000+",
    unit: "Soal Tersedia"
  },
  {
    icon: "about_tryout_user",
    number: "27.436",
    unit: "Peserta Tryout Maret 2021"
  },
  {
    icon: "about_page_rangkuman",
    number: "1.417",
    unit: "Halaman Rangkuman Materi"
  }
];

export const PARTNERSHIP = [
  {
    logo: "https://assets.edukasystem.com/partnership/Homeschooling Kak Seto2.png",
    title: "Homeschooling_Kak_Seto"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/pilihjurusan 3 (2).png",
    title: "Pilih_Jurusan"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/MAN 1 Jepara 2.png",
    title: "MAN_1_Jepara"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/Edusmart 2.png",
    title: "Edusmart"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/SMAN 58 Jakarta 2.png",
    title: "SMAN_58_Jakarta"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/SMAN 1 Jonggol 2.png",
    title: "SMAN_1_Jonggol"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/Himala UI 2.png",
    title: "HIMALA_UI"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/Ikatan Alumni SMAN 24 Bandung 2.png",
    title: "Ikatan_Alumni_SMAN_24_Bandung"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/Ikatan Alumni SMAN 2 Payakumbuh 2.png",
    title: "Ikatan_Alumni_SMAN_2_Payakumbuh"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/MAN 1 Padang Pariaman 2.png",
    title: "Ikatan_Alumni_MAN_1_Padang_Pariaman"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/A760227F 2.png",
    title: "Makarim_Institute"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/A7602260 2.png",
    title: "KM_FK_UNAIR"
  },
  {
    logo: "https://assets.edukasystem.com/partnership/Frame 52017 2.png",
    title: "any_partner"
  }
];
