<script>
import GoogleSignInButton from "../GoogleSignInButton.vue";

import {
  validateEmail,
  validateNewUsername,
  validateNewPassword,
  validatePhoneNumber
} from "@/utils/validation-form";
import { removeUserId } from "@/utils/user";
import { removeToken, getToken } from "@/utils/auth";
import { removeUnusedCache } from "@/utils/general";
import { getLSObjectWithExpiry, removeLSItem } from "@/utils/general";

import { MessageProgrammatic as Message } from "@/components/Message";

import Auth from "@/api/auth";
import Account from "@/api/account";
import Order from "@/api/order";
import Time from "@/api/time";
import User from "@/api/user";

const OTP_TIME = 180;
export default {
  name: "ModalLogin",
  data() {
    return {
      // General
      modalType: "",
      section: "form",

      // Input Value
      name: "",
      email: "",
      phoneNumber: "",
      username: "",
      password: "",
      passwordConfirmation: "",

      // Validation
      emailValidation: {
        isValid: true,
        message: ""
      },
      passwordValidation: {
        isValid: true,
        message: ""
      },
      phoneNumberValidation: {
        isValid: true,
        message: ""
      },
      usernameValidation: {
        isValid: true,
        message: ""
      },
      passwordConfirmationValidation: {
        isValid: true,
        message: ""
      },

      // Password Visibility
      isVisiblePassword: false,
      isVisiblePasswordConfirmation: false,

      // Register
      fromGoogleSignIn: false,
      userID: "",
      userToken: "",
      OTP_TIME,

      referral: "",
      bonusDay: 0,
      referralValidation: {
        isValid: true,
        message: ""
      },

      otp: ""
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "login" // login, register, forget
    }
  },
  created() {
    this.modalType = this.type;
  },
  components: {
    GoogleSignInButton
  },
  methods: {
    // General
    close() {
      this.resetForm();
      this.$emit("close");
    },
    resetForm() {
      this.name = "";

      this.email = "";
      this.$set(this.emailValidation, "isValid", true);
      this.$set(this.emailValidation, "message", "");

      this.phoneNumber = "";
      this.$set(this.phoneNumberValidation, "isValid", true);
      this.$set(this.phoneNumberValidation, "message", "");

      this.username = "";
      this.$set(this.usernameValidation, "isValid", true);
      this.$set(this.usernameValidation, "message", "");

      this.password = "";
      this.$set(this.passwordValidation, "isValid", true);
      this.$set(this.passwordValidation, "message", "");

      this.passwordConfirmation = "";
      this.$set(this.passwordConfirmationValidation, "isValid", true);
      this.$set(this.passwordConfirmationValidation, "message", "");
    },
    switchType(type) {
      this.resetForm();
      this.modalType = type;
    },
    async logoutPrevAccount() {
      const token = getToken();

      if (token) {
        try {
          this.$honeybadger?.resetContext();
          await Auth.blacklistToken(token);
          removeToken();
          removeUserId();
          removeUnusedCache();
        } catch (e) {
          console.error(e);
        }
      }
    },

    // Login
    async login() {
      if (this.email && this.password) {
        try {
          const res = validateEmail(this.email)
            ? await Auth.loginEmail({ email: this.email, password: this.password }).then(
                res => res.data.data
              )
            : await Auth.loginUsername({
                username: this.email,
                password: this.password
              }).then(res => res.data.data);

          if (res) {
            const { token } = res;

            // Logout login free account - integrated app
            await this.logoutPrevAccount();

            // Logout and Login with real account - SSO
            window.location = `${
              process.env.VUE_APP_SSO
            }/auth/${token}?redirect=${`${window.location.origin}`}&options=${JSON.stringify({
              redirect: this.$route.path,
              options: JSON.stringify(this.$route.query)
            })}`;

            this.close();
          }
        } catch (e) {
          this.$set(this.emailValidation, "isValid", false);
          this.$set(
            this.emailValidation,
            "message",
            "Email/Username belum ketemu nih, coba cek lagi ya!"
          );

          this.$set(this.passwordValidation, "isValid", false);
          this.$set(
            this.passwordValidation,
            "message",
            "Passwordmu tidak sesuai, coba cek lagi ya!"
          );
        }
      } else {
        if (!this.email) {
          this.$set(this.emailValidation, "isValid", false);
          this.$set(this.emailValidation, "message", "Email/Username belum diisi, isi dulu yuk!");
        }

        if (!this.password) {
          this.$set(this.passwordValidation, "isValid", false);
          this.$set(this.passwordValidation, "message", "Password belum diisi, isi dulu yuk!");
        }
      }
    },

    // Register
    async register() {
      if (
        validateNewUsername(this.username) &&
        validateEmail(this.email) &&
        validatePhoneNumber(this.phoneNumber) &&
        validateNewPassword(this.password) &&
        this.password === this.passwordConfirmation
      ) {
        try {
          const res = await User.checkPhoneNumber(this.phoneNumber).then(res => res.data.data);
          if (res) {
            const { is_valid } = res;
            if (is_valid) {
              let arrayName = this.name.split(" ");
              let first_name = arrayName[0];
              let last_name = "";

              if (arrayName.length > 1) {
                for (let i = 1; i < arrayName.length; i++) {
                  last_name += arrayName[i];
                  if (i < arrayName.length) {
                    last_name += " ";
                  }
                }
              }

              const resRegister = await User.register({
                username: this.username,
                user_email: this.email,
                password: this.password,
                phone_number: this.phoneNumber,
                firstname: first_name,
                lastname: last_name
              }).then(res => res.data.data);

              if (resRegister) {
                const { token, id_user } = resRegister;

                // Handle utm creation
                const now = await Time.getTime().then(res => res.data.timestamp);
                const existedUTM = getLSObjectWithExpiry("utm", now);
                if (!existedUTM.expired) {
                  await User.createUTMHistory(id_user, existedUTM.data);
                  removeLSItem("utm");
                }

                this.userID = id_user;
                this.userToken = token;

                // Change Section
                this.fromGoogleSignIn = false;
                this.section = "referral";
              }
            } else {
              this.$set(this.phoneNumberValidation, "isValid", false);
              this.$set(
                this.phoneNumberValidation,
                "message",
                "Nomor HP sudah digunakan. Pakai nomor HP lain, ya!"
              );
            }
          } else {
            this.$set(this.phoneNumberValidation, "isValid", false);
            this.$set(
              this.phoneNumberValidation,
              "message",
              "Nomor HP sudah digunakan. Pakai nomor HP lain, ya!"
            );
          }
        } catch (e) {
          const { message, details } = e.response.data;
          if (message === "unique key violation") {
            if (details.includes("email")) {
              this.$set(this.emailValidation, "isValid", false);
              this.$set(
                this.emailValidation,
                "message",
                "Email sudah digunakan. Pakai email lain atau coba masuk!"
              );
            } else {
              this.$set(this.usernameValidation, "isValid", false);
              this.$set(
                this.usernameValidation,
                "message",
                "Username sudah digunakan. Buat username lain atau coba masuk!"
              );
            }
          }
        }
      } else {
        // Username
        if (!validateNewUsername(this.username) || !this.username) {
          this.$set(this.usernameValidation, "isValid", false);
          this.$set(
            this.usernameValidation,
            "message",
            !this.username
              ? "Username belum diisi, isi dulu yuk!"
              : "Format username belum tepat, cek lagi ya!"
          );
        }

        // Email
        if (!validateEmail(this.email) || !this.email) {
          this.$set(this.emailValidation, "isValid", false);
          this.$set(
            this.emailValidation,
            "message",
            !this.email
              ? "Email belum diisi, isi dulu yuk!"
              : !this.email.includes("@")
              ? "Email harus terdiri dari '@' yang ikuti dengan domain."
              : "Format email belum tepat, cek lagi ya!"
          );
        }

        // Phone Number
        if (!validatePhoneNumber(this.phoneNumber) || !this.phoneNumber) {
          this.$set(this.phoneNumberValidation, "isValid", false);
          this.$set(
            this.phoneNumberValidation,
            "message",
            !this.phoneNumber
              ? "Nomor HP belum diisi, isi dulu yuk!"
              : "Format nomor HP belum tepat, cek lagi ya!"
          );
        }

        // Password
        if (!validateNewPassword(this.password) || !this.password) {
          this.$set(this.passwordValidation, "isValid", false);
          this.$set(
            this.passwordValidation,
            "message",
            !this.password ? "Isi passwordnya yuk!" : "Passwordmu kurang kuat nih!"
          );
        }
        if (!(this.password === this.passwordConfirmation) || !this.passwordConfirmation) {
          this.$set(this.passwordConfirmationValidation, "isValid", false);
          this.$set(
            this.passwordConfirmationValidation,
            "message",
            !this.passwordConfirmation ? "Isi sekali lagi yuk!" : "Passwordnya disamain ya!"
          );
        }
      }
    },

    async submitReferral() {
      try {
        await Order.postReferralCode(this.userID, this.referral);

        Message.open({
          message: `Kode referral berhasil diterapkan.`,
          type: "is-success",
          position: "is-top",
          duration: 5000
        });

        if (!this.fromGoogleSignIn) {
          this.section = "otp";
        } else {
          // Logout login free account - integrated app
          await this.logoutPrevAccount();

          // Logout and Login with real account - SSO
          window.location = `${process.env.VUE_APP_SSO}/auth/${
            this.userToken
          }?redirect=${`${window.location.origin}`}&options=${JSON.stringify({
            redirect: this.$route.path,
            options: JSON.stringify(this.$route.query)
          })}`;

          this.close();
        }
      } catch (error) {
        this.$set(this.referralValidation, "isValid", false);
        this.$set(this.referralValidation, "message", "Kode referral tidak valid.");
      }
    },
    async skipReferral() {
      if (!this.fromGoogleSignIn) {
        this.section = "otp";
      } else {
        // Logout login free account - integrated app
        await this.logoutPrevAccount();

        // Logout and Login with real account - SSO
        window.location = `${process.env.VUE_APP_SSO}/auth/${
          this.userToken
        }?redirect=${`${window.location.origin}`}&options=${JSON.stringify({
          redirect: this.$route.path,
          options: JSON.stringify(this.$route.query)
        })}`;

        this.close();
      }
    },

    handleTimeOTP() {
      const timerId = setInterval(() => {
        if (this.OTP_TIME === 0) {
          clearTimeout(timerId);
        } else {
          this.OTP_TIME -= 1;
        }
      }, 1000);
    },
    async verifyOTP() {
      try {
        const res = await Account.verifyOTPCode(this.userID, this.otp).then(
          response => response.data.data
        );

        if (res) {
          const { success, total_day_free_trial_otp, total_day_free_trial_referral } = res;
          if (!success) {
            Message.open({
              message: `Kode verifikasi tidak sesuai.`,
              type: "is-danger",
              position: "is-top",
              duration: 5000
            });

            this.otp = "";
          } else {
            Message.open({
              message: `Akun berhasil terverifikasi. Nomor yang sudah diverifikasi tidak bisa digunakan di akun lain.`,
              type: "is-success",
              position: "is-top",
              duration: 5000
            });

            setTimeout(async () => {
              // Allow a pop up message display for 2 sec
              if (!total_day_free_trial_otp && !total_day_free_trial_referral) {
                await this.redirectToProduct({ notify_otp: false });
              } else {
                // Only notify if there is a free trial
                await this.redirectToProduct({
                  notify_otp: true,
                  trial_otp: total_day_free_trial_otp || 0,
                  trial_referral: total_day_free_trial_referral || 0
                });
              }
            }, 2000);
          }

          this.close();
        }
      } catch (e) {
        console.error(e);
      }
    },
    async redirectToProduct(query) {
      const { notify_otp, trial_otp, trial_referral } = query;
      // Logout login free account - integrated app
      await this.logoutPrevAccount();

      let options = { ...this.$route.query };
      if (notify_otp) {
        options["notify_otp"] = true;
        options["trial_otp"] = trial_otp;
        options["trial_referral"] = trial_referral;

        // Logout and Login with real account - SSO
        window.location = `${process.env.VUE_APP_SSO}/auth/${
          this.userToken
        }?redirect=${`${window.location.origin}`}&options=${JSON.stringify({
          redirect: "/dashboard",
          options: JSON.stringify(options)
        })}`;
      } else {
        // Logout and Login with real account - SSO
        window.location = `${process.env.VUE_APP_SSO}/auth/${
          this.userToken
        }?redirect=${`${window.location.origin}`}&options=${JSON.stringify({
          redirect: this.$route.path,
          options: JSON.stringify(options)
        })}`;
      }

      this.close();
    },
    async sendOTP() {
      try {
        await Account.reverifyUserPhone(this.userID);
      } catch (error) {
        console.error(error);
      }
    },
    async resendOTP() {
      await this.sendOTP();
      this.OTP_TIME = OTP_TIME;
      this.handleTimeOTP();
    },

    // Forget Password
    async forget() {
      if (this.email) {
        if (validateEmail(this.email)) {
          await Account.postSendChangePasswordForm({ email: this.email })
            .then(response => {
              if (response.status === 204) {
                Message.open({
                  message: `Link ganti password sudah dikirim ke email kamu, silakan cek inbox atau spam ya!`,
                  type: "is-success",
                  position: "is-top",
                  duration: 5000
                });
                this.close();
              } else {
                this.$set(this.emailValidation, "isValid", false);
                this.$set(this.emailValidation, "message", "Email salah nih, coba cek lagi ya!");
              }
            })
            .catch(() => {
              this.$set(this.emailValidation, "isValid", false);
              this.$set(this.emailValidation, "message", "Email salah nih, coba cek lagi ya!");
            });
        } else {
          this.$set(this.emailValidation, "isValid", false);
          this.$set(this.emailValidation, "message", "Email salah nih, coba cek lagi ya!");
        }
      } else {
        this.$set(this.emailValidation, "isValid", false);
        this.$set(this.emailValidation, "message", "Email belum diisi, isi dulu yuk!");
      }
    },

    // Google SignIn
    setFromGoogle(value) {
      this.fromGoogleSignIn = value;

      if (value) {
        // Register
        this.section = "referral";
      }
    },
    setUserToken(value) {
      this.userToken = value;
    },
    setUserID(value) {
      this.userID = value;
    }
  },
  watch: {
    email() {
      this.$set(this.emailValidation, "isValid", true);
      this.$set(this.emailValidation, "message", "");
    },
    phoneNumber() {
      this.$set(this.phoneNumberValidation, "isValid", true);
      this.$set(this.phoneNumberValidation, "message", "");
    },
    username() {
      this.$set(this.usernameValidation, "isValid", true);
      this.$set(this.usernameValidation, "message", "");
    },
    password() {
      this.$set(this.passwordValidation, "isValid", true);
      this.$set(this.passwordValidation, "message", "");
    },
    passwordConfirmation() {
      this.$set(this.passwordConfirmationValidation, "isValid", true);
      this.$set(this.passwordConfirmationValidation, "message", "");
    },
    referral() {
      this.referral = this.referral.toUpperCase();
      this.$set(this.referralValidation, "isValid", true);
      this.$set(this.referralValidation, "message", "");
    },
    async section(newVal) {
      if (newVal === "otp") {
        this.sendOTP();
        this.handleTimeOTP();
      } else if (newVal === "referral") {
        const res = await Account.getDayTrialReferral().then(
          res => res.data.total_day_free_trial_referral
        );
        this.bonusDay = res;
      }
    },
    async otp(newVal) {
      if (newVal.length === 6) {
        await this.verifyOTP();
      }
    }
  }
};
</script>

<template>
  <ModalGeneral :show="show" @close="close" disable-close-btn modal-type="slider">
    <div @click="close">
      <div :class="$style.rectangle"></div>
    </div>
    <div :class="$style.login" v-if="section === 'form'">
      <div :class="$style.login__header">
        <template v-if="modalType === 'login'">
          <img
            :class="$style.login__header__image"
            src="https://assets.edukasystem.com/eduka-sso/burhan-sso.png"
            alt="burhan login"
          />
          <h1 :class="$style.login__header__title">Belajar Efektif Bersama Buwhan</h1>
          <p :class="$style.login__header__subtitle">
            Coba berbagai macam latihan soal hanya di Buwhan Edu!
          </p>
        </template>
        <template v-else-if="modalType === 'forget'">
          <img
            :class="$style.login__header__image"
            src="https://assets.edukasystem.com/eduka-sso/Forget-Password-Logo.png"
            alt="gambar buwhan lupa password"
          />
          <h1 :class="$style.login__header__title">Lupa Password?</h1>
          <p :class="$style.login__header__subtitle">
            Masukan email yang terdaftar, Buwhan akan mengirimkan link untuk atur ulang passwordmu.
          </p>
        </template>
        <template v-else>
          <h1 :class="$style.login__header__register_text">Daftar Sekarang</h1>
        </template>
      </div>
      <form
        :class="$style.login__form"
        @submit.prevent="
          modalType === 'login' ? login() : modalType === 'register' ? register() : forget()
        "
      >
        <template v-if="modalType === 'register'">
          <div :class="$style.login__form__item">
            <label for="name">Nama</label>
            <div :class="$style.input">
              <input
                id="name"
                type="text"
                placeholder="Co: Buwhan Cutie"
                :autocomplete="false"
                v-model="name"
              />
            </div>
          </div>
          <div :class="$style.login__form__item">
            <label for="email-register">Email</label>
            <div :class="[$style.input, !emailValidation.isValid ? $style.invalid : '']">
              <input
                id="email-register"
                type="text"
                placeholder="Co: Buwhan@buwhanedu.com"
                :autocomplete="false"
                v-model="email"
              />
            </div>
            <p :class="$style.error" v-if="!emailValidation.isValid">
              {{ emailValidation.message }}
            </p>
          </div>
          <div :class="$style.login__form__item">
            <label for="phone-number">Nomor Handphone</label>
            <div :class="[$style.input, !phoneNumberValidation.isValid ? $style.invalid : '']">
              <input
                id="phone-number"
                type="text"
                placeholder="Co: 08123456789"
                :autocomplete="false"
                v-model="phoneNumber"
              />
            </div>
            <p :class="$style.error" v-if="!phoneNumberValidation.isValid">
              {{ phoneNumberValidation.message }}
            </p>
          </div>
          <div :class="$style.login__form__item">
            <label for="username">Username</label>
            <div :class="[$style.input, !usernameValidation.isValid ? $style.invalid : '']">
              <input
                id="username"
                type="text"
                placeholder="buwhancutie"
                :autocomplete="false"
                v-model="username"
              />
            </div>
            <p :class="$style.error" v-if="!usernameValidation.isValid">
              {{ usernameValidation.message }}
            </p>
          </div>
        </template>

        <div
          v-if="modalType === 'login' || modalType === 'forget'"
          :class="$style.login__form__item"
        >
          <label for="email-login">Email{{ modalType === "login" ? "/Username" : "" }}</label>
          <div :class="[$style.input, !emailValidation.isValid ? $style.invalid : '']">
            <input
              id="email-login"
              type="text"
              :placeholder="
                modalType === 'login' ? 'Co: Buwhan@buwhanedu.com' : 'Masukkan email disini'
              "
              :autocomplete="false"
              v-model="email"
            />
          </div>
          <p :class="$style.error" v-if="!emailValidation.isValid">
            {{ emailValidation.message }}
          </p>
        </div>

        <div :class="modalType === 'register' ? $style.password_container : ''">
          <div
            :class="$style.login__form__item"
            v-if="modalType === 'register' || modalType === 'login'"
          >
            <label for="password-login">Password</label>
            <div :class="[$style.input, !passwordValidation.isValid ? $style.invalid : '']">
              <input
                v-model="password"
                id="password-login"
                :type="isVisiblePassword ? 'text' : 'password'"
                placeholder="Password"
                autocomplete
              />
              <svg-icon
                @click="isVisiblePassword = !isVisiblePassword"
                :icon-class="!isVisiblePassword ? 'visibility_off' : 'visibility'"
                :class="$style.icon"
              />
            </div>
            <p :class="$style.error" v-if="!passwordValidation.isValid">
              {{ passwordValidation.message }}
            </p>
            <p
              v-if="modalType === 'login'"
              :class="$style.forget_password"
              @click="switchType('forget')"
            >
              Lupa password
            </p>
          </div>
          <div :class="$style.login__form__item" v-if="modalType === 'register'">
            <label for="password-confirmation">Konfirmasi Password</label>
            <div
              :class="[$style.input, !passwordConfirmationValidation.isValid ? $style.invalid : '']"
            >
              <input
                v-model="passwordConfirmation"
                id="password-confirmation"
                :type="isVisiblePasswordConfirmation ? 'text' : 'password'"
                placeholder="Password"
                autocomplete
              />
              <svg-icon
                @click="isVisiblePasswordConfirmation = !isVisiblePasswordConfirmation"
                :icon-class="!isVisiblePasswordConfirmation ? 'visibility_off' : 'visibility'"
                :class="$style.icon"
              />
            </div>
            <p :class="$style.error" v-if="!passwordConfirmationValidation.isValid">
              {{ passwordConfirmationValidation.message }}
            </p>
          </div>
        </div>

        <div :class="$style.login__form__action">
          <button
            :class="$style.primary"
            class="btn btn-secondary"
            :disabled="
              modalType === 'login'
                ? !email || !password
                : modalType === 'register'
                ? !email || !phoneNumber || !username || !password || !passwordConfirmation
                : !email
            "
            type="submit"
          >
            {{ modalType === "login" ? "Masuk" : modalType === "register" ? "Daftar" : "Kirim" }}
          </button>

          <p v-if="modalType !== 'forget'">Atau</p>

          <GoogleSignInButton
            :button-type="modalType"
            @close="close"
            @set-token="setUserToken"
            @set-id="setUserID"
            @set-from-google="setFromGoogle"
            @logout="logoutPrevAccount"
          />

          <p :class="$style.account">
            {{
              modalType === "login"
                ? "Belum Punya Akun"
                : modalType === "register"
                ? "Sudah Punya Akun"
                : "Ingat Password"
            }}?
            <a
              :class="$style.account__link"
              @click="switchType(modalType === 'login' ? 'register' : 'login')"
            >
              {{ modalType === "login" ? "Daftar" : "Masuk" }} di sini
            </a>
          </p>
        </div>
      </form>
    </div>
    <div :class="[$style.login, $style.referral]" v-else-if="section === 'referral'">
      <div :class="$style.referral__content">
        <div :class="$style.login__header">
          <h1 :class="$style.login__header__register_text">Kode Referral</h1>
        </div>
        <div :class="$style.login__form">
          <div :class="$style.login__form__item">
            <div :class="[$style.input, !referralValidation.isValid ? $style.invalid : '']">
              <input
                type="text"
                placeholder="Masukkan kode referral"
                :autocomplete="false"
                v-model="referral"
              />
            </div>
            <p :class="$style.error" v-if="!referralValidation.isValid">
              {{ referralValidation.message }}
            </p>
            <p :class="$style.desc">
              Kamu dan teman yang memberikan kode referral akan mendapatkan Bank Soal dan Rangkuman
              gratis selama {{ bonusDay }} hari.
            </p>
          </div>
        </div>
      </div>
      <div :class="$style.referral__action">
        <button class="btn btn-secondary" :disabled="!referral" @click="submitReferral">
          Lanjutkan
        </button>
        <p :class="$style.referral__action__skip" @click="skipReferral">Lewati</p>
      </div>
    </div>
    <div :class="[$style.login, $style.otp]" v-else-if="section === 'otp'">
      <div :class="$style.login__header">
        <img
          :class="$style.login__header__image"
          src="https://assets.edukasystem.com/eduka-sso/burhan-otp.png"
          alt="buwhan verifikasi OTP"
        />
        <h1 :class="$style.login__header__title">Verifikasi Akun</h1>
      </div>
      <div :class="$style.otp__content">
        <p>
          Masukkan kode verifikasi yang dikirim ke <span>{{ phoneNumber }}</span> melalui Whatsapp.
          Nomor hanya bisa diverifikasi 1 kali di 1 akun
        </p>
        <div :class="$style.login__form">
          <div :class="$style.login__form__item">
            <div :class="$style.input">
              <input
                type="text"
                maxlength="6"
                v-model="otp"
                :autocomplete="false"
                :disabled="otp.length == 6"
              />
            </div>
          </div>
        </div>

        <p v-if="OTP_TIME > 0">Mohon tunggu dalam {{ OTP_TIME }} detik untuk kirim ulang kode</p>
        <p v-else :class="$style.otp__content__resend_code">
          Masukkan kode atau <span @click="resendOTP">klik untuk kirim ulang kode</span>
        </p>
      </div>
      <div :class="$style.otp__action">
        <p @click="redirectToProduct({ notify_otp: false })">Lewati</p>
      </div>
    </div>
  </ModalGeneral>
</template>

<style lang="scss" module>
.rectangle {
  margin: auto;
  width: 2rem;
  height: 0.25rem;
  background: #c4c4c4;
  border-radius: 0.5rem;

  @include min-media(tablet) {
    display: none;
  }
}
.login {
  padding: 1.5rem;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  width: 100%;
  max-height: 550px;

  @include min-media(desktop) {
    max-height: 700px;
    width: 600px;
  }

  &__header {
    &__image {
      max-width: 175px;
      margin-bottom: 0.5rem;

      @include min-media(tablet) {
        max-width: 250px;
        margin-bottom: 1rem;
      }
    }

    &__title {
      font-size: 1rem;
      font-weight: 700;

      @include min-media(tablet) {
        font-size: 1.25rem;
      }
    }

    &__subtitle {
      font-size: 0.75rem;
      color: #676767;

      @include min-media(tablet) {
        font-size: 1rem;
      }
    }

    &__register_text {
      font-size: 1rem;
      font-weight: 700;

      @include min-media(tablet) {
        font-size: 1.25rem;
      }
    }
  }

  &__form {
    margin-top: 1.5rem;

    .password_container {
      @include min-media(tablet) {
        display: flex;
        gap: 1rem;
      }
    }

    &__item {
      width: 100%;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      text-align: left;
      position: relative;

      .error {
        margin-top: 0.25rem;
        font-size: 0.625rem;
        color: #f14668;

        @include min-media(tablet) {
          font-size: 0.75rem;
        }
      }

      .desc {
        margin-top: 0.25rem;
        font-size: 0.75rem;

        @include min-media(tablet) {
          font-size: 1rem;
        }
      }

      label {
        font-weight: 700;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;

        @include min-media(tablet) {
          font-size: 1rem;
        }
      }

      .input {
        width: 100%;
        display: block;
        position: relative;

        &.invalid {
          input {
            border: 1px solid #f14668;
          }

          .icon {
            color: #f14668;
          }
        }

        input {
          width: 100%;
          outline: none;
          background: #ffffff;
          border: 1px solid #cdd2dc;
          box-sizing: border-box;
          border-radius: 8px;
          padding: 0.625rem 1rem;
          font-size: 0.875rem;
          font-family: "Nunito", sans-serif;

          &:focus {
            border: 1px solid rgba($color: $purple, $alpha: 1);
            box-shadow: 0 0 5px rgba($color: $purple, $alpha: 1);

            & ~ .icon {
              color: $purple;
            }
          }

          @include min-media(tablet) {
            font-size: 1rem;
          }
        }

        svg {
          color: #676767;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1rem;
          font-size: 1rem;
          cursor: pointer;
          @include min-media(tablet) {
            font-size: 1.25rem;
          }
        }
      }

      .forget_password {
        font-size: 0.75rem;
        color: $purple;
        font-weight: 700;
        text-align: left;
        margin-top: 0.5rem;
        cursor: pointer;

        @include min-media(tablet) {
          font-size: 0.875rem;
        }
      }
    }

    &__action {
      width: 100%;
      margin-top: 1rem;
      button {
        width: 100%;
        font-size: 0.875rem;

        @include min-media(tablet) {
          font-size: 1rem;
        }
      }

      p {
        font-size: 0.875rem;
        margin: 0.75rem 0;

        @include min-media(tablet) {
          font-size: 1rem;
          margin: 1rem 0;
        }
      }

      .account {
        margin-bottom: 0;
        &__link {
          color: $purple;
          font-weight: bold;
          text-decoration: underline;
          transition: color 0.25s;
        }
      }
    }
  }
}

.referral {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include min-media(tablet) {
    height: 400px;
  }

  &__action {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 3rem;

    button {
      width: 100%;
      font-size: 0.875rem;

      @include min-media(tablet) {
        font-size: 1rem;
      }
    }

    p {
      color: $purple;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
      font-size: 0.875rem;

      @include min-media(tablet) {
        font-size: 1rem;
      }
    }
  }
}

.otp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input {
    width: 100%;
    text-align: center;
    letter-spacing: 1.88em;
  }

  &__content {
    margin: 2rem 0 3rem 0;

    p {
      font-size: 0.875rem;

      span {
        font-weight: 700;
      }

      @include min-media(tablet) {
        font-size: 1rem;
      }
    }

    &__resend_code {
      span {
        color: $purple;
        cursor: pointer;
      }
    }
  }

  &__action {
    width: 100%;

    p {
      color: $purple;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
      font-size: 0.875rem;

      @include min-media(tablet) {
        font-size: 1rem;
      }
    }
  }
}
</style>
