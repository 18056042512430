<script>
import NoticeMixins from "@/mixins/NoticeMixin.js";

export default {
  name: "Snackbar",
  mixins: [NoticeMixins],
  props: {
    actionText: {
      type: String,
      default: "OK"
    },
    onAction: {
      type: Function,
      default: () => {}
    },
    cancelText: {
      type: String,
      default: ""
    }
  },
  computed: {
    bodyClass() {
      return [
        this.$style.body,
        this.$style[this.position],
        this.$style[this.type],
        this.show ? this.$style.show : ""
      ];
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    triggerAction() {
      this.onAction();
      this.close();
    }
  },
  watch: {
    isActive(val) {
      if (val) {
        setTimeout(() => {
          this.show = true;
        }, 200);
        if (!this.indefinite) {
          setTimeout(() => {
            this.show = false;
          }, this.duration - 200);
        }
      } else {
        this.show = false;
      }
    }
  }
};
</script>

<template>
  <div v-show="isActive" :class="bodyClass" :role="actionText ? 'alertdialog' : 'alert'">
    <div :class="$style.message">
      <slot name="message"></slot>
      <span v-if="!$slots.message">{{ message }}</span>
    </div>
    <div :class="$style.action_group">
      <button
        v-if="cancelText"
        :class="['btn', $style.button, $style.button__cancel]"
        @click="close"
      >
        {{ cancelText }}
      </button>
      <button
        :class="[
          'btn',
          $style.button,
          type === 'default' ? $style.button__default : $style.button__white
        ]"
        @click="triggerAction"
      >
        {{ actionText }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" module>
.body {
  position: fixed;
  background: rgb(37, 37, 25);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  max-width: 30rem;
  z-index: 1000001; // more than crips
  transition: all 0.2s;
  opacity: 0;
  &.is-bottom-right {
    bottom: 0.5rem;
    right: 1rem;
  }
  &.is-bottom-left {
    bottom: 0.5rem;
    left: 1rem;
  }
  &.is-bottom {
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.is-top-right {
    top: 0.5rem;
    right: 1rem;
  }
  &.is-top-left {
    top: 0.5rem;
    left: 1rem;
  }
  &.is-top {
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.is-bottom.show,
  &.is-bottom-right.show,
  &.is-bottom-left.show {
    bottom: 1rem;
    opacity: 1;
  }

  &.is-top.show,
  &.is-top-right.show,
  &.is-top-left.show {
    top: 1rem;
    opacity: 1;
  }

  &.is-danger {
    background: $wrong;
  }

  &.is-success {
    background: $success;
  }

  @include max-media(mobile) {
    max-width: 90%;
    flex-direction: column;
  }
}

.message {
  margin-right: 1rem;
  @include max-media(mobile) {
    margin-bottom: 0.5rem;
  }
}

.action_group {
  flex-shrink: 0;
  flex-grow: 0;
}

.button {
  border-radius: 0.25rem;
  background: none;
  color: white;
  transition: all 0.2s;

  &__cancel {
    margin-right: 0.5rem;
  }
  &__default {
    color: $orange;
  }

  &__white {
    color: white;
  }

  &:hover {
    background: rgba(white, 0.15);
  }
}
</style>
