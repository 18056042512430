export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Agu",
  "Sept",
  "Okt",
  "Nov",
  "Des"
];
export const MONTHS_ID = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember"
];

export const DAYS = ["M", "S", "S", "R", "K", "J", "S"];
export const FULL_DAYS = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];

const MILLISDAY = 1000 * 60 * 60 * 24;
export const MILISEXPSPAN = MILLISDAY * 3;
export const MILISEXPSPANLONG = MILLISDAY * 7;
