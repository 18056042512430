import service from "@/utils/request";
import { getUserId } from "@/utils/user";
export default class Prize {
  static baseGroupURL = "prize/coin";

  static async getSpecificMiniQuizCoin(idMiniQuiz) {
    const resp = await service.get(
      `${this.baseGroupURL}/user/${getUserId()}/miniquiz/${idMiniQuiz}`
    );
    return resp;
  }

  static async getSpecificQuizCoin(idQuiz) {
    const resp = await service.get(`${this.baseGroupURL}/user/${getUserId()}/quiz/${idQuiz}`);
    return resp;
  }

  static async getUserCoin() {
    const resp = await service.get(`${this.baseGroupURL}/user/${getUserId()}`);
    return resp;
  }

  static async refundUserCoin(data) {
    const resp = await service.post(`${this.baseGroupURL}/user/${getUserId()}/refund`, data);
    return resp;
  }

  static async addUserCoin(data) {
    const resp = await service.post(`${this.baseGroupURL}/user/${getUserId()}/add`, data);
    return resp;
  }

  static async getCoinHistory(params) {
    const resp = await service.get(`${this.baseGroupURL}/user/${getUserId()}/history`, { params });
    return resp;
  }
}
