<template>
  <div :class="['loading', active ? '' : 'disable']">
    <div class="loading--spinner">
      <figure>
        <img
          src="@/assets/img/eduka-loading.png"
          srcset="
            @/assets/img/eduka-loading@2x.png 2x,
            @/assets/img/eduka-loading@3x.png 3x
          "
          class="Icon-Eduka-loading2-04"
          alt="Icon Eduka Loading"
        />
        <svg viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="40" />
        </svg>
        <figcaption>LOADING</figcaption>
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      active: true
    };
  },
  methods: {
    toggleActive() {
      this.active = false;
    }
  },
  created() {
    window.addEventListener("load", this.toggleActive);
  },
  beforeDestroy() {
    window.removeEventListener("load", this.toggleActive);
  }
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
