<script>
export default {
  name: "Navbar",
  props: {
    hideLogin: Boolean
  },
  data() {
    return {
      items: [
        {
          name: "Tentang",
          link: "/about"
        },
        {
          name: "Fitur",
          link: "/about",
          children: [
            {
              name: "Rangkuman",
              link: `/lihat-caranya/rangkuman`
            },
            {
              name: "Quiz",
              link: `/lihat-caranya/quiz`
            },
            {
              name: "Tryout",
              link: `/lihat-caranya/tryout`
            },
            {
              name: "Live Class",
              link: `/lihat-caranya/live-class`
            },
            {
              name: "Rekomendasi Belajar",
              link: `/lihat-caranya/rekomendasi-belajar`
            },
            {
              name: "Rekomendasi Jurusan & Kampus",
              link: `/lihat-caranya/rekomendasi-jurusan`
            }
          ]
        },
        // {
        //   name: "Testimonial",
        //   link: "/testimonial"
        // },
        // {
        //   name: "Testimoni",
        //   out: true,
        //   link: `https://promo.edukasystem.com/testimonials/`
        // },
        // {
        //   name: "Blog",
        //   out: true
        //   link: "https://blog.edukasystem.com/",
        // },
        {
          name: "FAQ",
          out: true,
          link: `https://drive.google.com/drive/folders/1cy1GGuwpf3upvrNO1t0DTvJPgfAdOqnD`
        }
      ],
      windowWidth: null,
      active: false,
      productActive: false,
      isWhiteList: -1
    };
  },
  methods: {
    toggleNavbar() {
      this.active = !this.active;
    },
    toggleProduct() {
      this.productActive = !this.productActive;
    },
    // addOnScrollEvent() {
    //   const self = this;
    //   const $navbar = document.getElementById("navbar-landing");
    //   const { pathname } = window.location;
    //   const whiteList = ["/partnership", "/career", "/career/search"];
    //   this.isWhiteList = whiteList.findIndex(e => e === pathname);

    //   window.onscroll = function() {
    //     if ($navbar && self.isWhiteList === -1) {
    //       $navbar.style.background =
    //         this.pageYOffset > 50
    //           ? "none"
    //           : "https://assets.edukasystem.com/free-trial-tryout/free-trial-tryout-bg.png";
    //     }
    //   };
    // },
    login() {
      window.location = `${process.env.VUE_APP_SSO}/auth/login?redirect=${
        window.location.origin
      }&options=${JSON.stringify(this.$route.query)}`;
    },
    register() {
      window.location = `${process.env.VUE_APP_SSO}/auth/register`;
    }
  }
  // mounted() {
  //   // on scroll effect
  //   this.addOnScrollEvent();
  // },
  // destroyed() {
  //   window.removeEventListener("scroll");
  // }
  // watch: {
  //   $route: {
  //     handler() {
  // const $navbar = document.getElementById("navbar-landing");
  // const { pathname } = window.location;
  // const whiteList = ["/partnership", "/career", "/career/search"];

  // this.isWhiteList = whiteList.findIndex(e => e === pathname);
  // this.productActive = false;
  // this.active = false;

  // if ($navbar && this.isWhiteList === -1) {
  //   $navbar.style.background =
  //     this.pageYOffset > 50 ? "rgba(256, 256, 256, 1)" : "rgba(256, 256, 256, 0.3)";
  // }
  //   },
  //   immediate: true
  // }
  // }
};
</script>

<template>
  <nav class="navbar" id="navbar-landing" role="menubar">
    <router-link class="navbar__logo" to="/">
      <img
        src="https://assets.edukasystem.com/logo/buwhanedu-logo.png"
        srcset="
          https://assets.edukasystem.com/logo/buwhanedu-logo@2x.png 2x,
          https://assets.edukasystem.com/logo/buwhanedu-logo@3x.png 3x
        "
        class="navbar__logo-img"
        alt="logo buwhan edu"
      />
    </router-link>
    <ul
      itemscope
      itemtype="http://schema.org/SiteNavigationElement"
      class="navbar__items"
      role="menu"
    >
      <li
        class="navbar__item"
        v-for="(item, idx) in items"
        :key="idx"
        itemprop="name"
        role="menuitem"
      >
        <a v-if="item.out" class="navbar__item-link" itemprop="url" :href="item.link">
          {{ item.name }}
        </a>
        <div
          v-else-if="item.children && item.children.length > 0"
          class="navbar__collaps"
          role="menu"
        >
          {{ item.name }}
          <!-- <span class="navbar__arrow">&#9660;</span> -->
          <svg-icon icon-class="chevron-down-purple" class-name="navbar__arrow" />
          <div class="navbar__collapsible" itemprop="name">
            <router-link
              class="navbar__item-link navbar__collapsible-item"
              v-for="(c, i) in item.children"
              :key="i"
              :to="c.link"
              itemprop="url"
              role="menuitem"
            >
              {{ c.name }}
            </router-link>
          </div>
        </div>
        <router-link class="navbar__item-link" :to="item.link" itemprop="url" v-else>
          {{ item.name }}
        </router-link>
      </li>
    </ul>
    <ul :class="['navbar__items--drawer', active ? 'navbar__active' : '']">
      <li class="navbar__item--drawer" v-for="(item, idx) in items" :key="idx">
        <a v-if="item.out" class="navbar__item-link" :href="item.link">{{ item.name }}</a>
        <div
          v-else-if="item.children && item.children.length > 0"
          class="navbar__collaps--alt"
          @click="toggleProduct()"
        >
          {{ item.name }}
          <span class="navbar__arrow">&#9660;</span>
          <div
            :class="[
              'navbar__collapsible--alt',
              productActive ? 'navbar__collapsible--active' : ''
            ]"
          >
            <router-link
              class="navbar__item-link navbar__collapsible-item"
              v-for="(c, i) in item.children"
              :key="i"
              :to="c.link"
            >
              {{ c.name }}
            </router-link>
          </div>
        </div>

        <router-link class="navbar__item-link" :to="item.link" v-else>
          {{ item.name }}
        </router-link>
      </li>
      <div class="navbar__sign-btn mobile" v-if="!hideLogin">
        <a @click="login">Masuk</a>
        <button class="btn regis-btn" @click="register">Daftar</button>
      </div>
    </ul>
    <div class="navbar__sign-btn" v-if="!hideLogin">
      <a @click="login">Masuk</a>
      <button class="btn regis-btn" @click="register">Daftar</button>
    </div>
    <button
      aria-label="Menu Toggle Button"
      :class="['btn', 'navbar__toggle-btn', active ? 'active' : '']"
      @click="toggleNavbar"
    >
      <div class="navbar__icon">&nbsp;</div>
    </button>
  </nav>
</template>

<style lang="scss" scoped>
$text-color: $purple;
$bg-color: white;
$accent-color: $purple;
$primary-color: $purple;

* {
  box-sizing: inherit;
}

.navbar {
  position: fixed;
  // background-color: rgba($bg-color, 0.3);
  background: white;
  top: 32px;
  left: 5%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  color: $text-color;
  z-index: 4002;
  width: 100%;
  max-width: 90%;
  margin: auto;
  transition: all 0.2s;
  box-shadow: 0px 2px 8px rgba(225, 114, 17, 0.21);
  border-radius: 36px;

  &:hover {
    background-color: $bg-color;
  }

  @include respond(tab-port) {
    padding: 0.5rem 0.25rem;
  }

  @include respond(phone) {
    max-height: 54px;
  }

  &__logo {
    margin-left: $gutter-bg;

    // @include respond(tab-port) {
    //   margin-left: 45vw;
    // }

    &-img {
      width: 4rem;
    }
  }
  &__collaps--alt {
    color: $purple;
  }
  &__items {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;

    @include respond(tab-port) {
      display: none;
    }

    &--drawer {
      background-color: white;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      font-size: 1rem;
      font-weight: bold;
      font-family: "Nunito", sans-serif;
      list-style-type: none;
      text-decoration: none;
      color: black;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      border-radius: 2rem;
      box-shadow: 0 1px 5px rgba(black, 0.2);
      padding: 0 2rem;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 0.2s;
      display: none;
      @include respond(tab-port) {
        display: flex;
      }
      &.navbar__active {
        height: auto;
        padding: 2rem;
        opacity: 1;
      }
      .navbar__item-link {
        color: $purple;
      }
      .navbar__item--drawer {
        &:not(:last-child) {
          margin-bottom: $gutter-bg;
        }
      }
      .navbar__arrow {
        color: $bg-color;
      }
      .navbar__collapsible {
        &--alt {
          height: 0;
          overflow: hidden;
          opacity: 0;
          transition: all 0.2s;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
        }

        &--active {
          height: auto;
          opacity: 1;
        }
      }
    }
  }
  &__item {
    font-size: 1rem;
    font-weight: bold;
    font-family: "Nunito", sans-serif;
    list-style-type: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s all;
    cursor: pointer;
    &:hover {
      color: $accent-color;
      border-bottom: 3px solid lighten($accent-color, 10);
    }
    &.active {
      border-bottom: 3px solid $accent-color;
    }
    &-link {
      text-decoration: none;
      color: $text-color;
    }
  }
  &__arrow {
    font-size: 0.75rem;
    // color: #f57200;
  }
  &__collaps {
    position: relative;
    cursor: pointer;
    color: $purple;

    &:hover &ible {
      visibility: visible;
      z-index: 2000;
      left: 50%;
      opacity: 1;
      transition: all 0.5s;
    }

    &ible {
      background: lighten($bg-color, 10);
      position: absolute;
      top: 100%;
      left: 30%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: -1;
      visibility: hidden;
      box-shadow: 0 2px 5px rgba(black, 0.2);
      border-radius: 1rem;
      opacity: 0;
      &-item {
        padding: 0.5rem 1rem;
        width: max-content;
      }
    }
  }

  &__toggle-btn {
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
    z-index: 2000;
    text-align: center;
    cursor: pointer;
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    background: transparent;
    text-shadow: none;

    @include respond(tab-port) {
      display: flex;
    }

    &.btn:not(.no-hover):hover {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &:hover .navbar__icon::before {
      top: -1rem;
      @include respond(tab-port) {
        top: -0.75rem;
      }
    }

    &:hover .navbar__icon::after {
      top: 1rem;
      @include respond(tab-port) {
        top: 0.75rem;
      }
    }

    &.active {
      text-shadow: none;
      .navbar__icon {
        background-color: transparent;
      }

      .navbar__icon::before {
        top: 0;
        transform: rotate(135deg);
      }

      .navbar__icon::after {
        top: 0;
        transform: rotate(-135deg);
      }
    }
  }

  //ICON
  &__icon {
    position: absolute;
    right: 0;

    &,
    &::before,
    &::after {
      width: 2rem;
      height: 4px;
      border-radius: 0.3rem;
      background-color: #fa7028;
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::before {
      top: -0.5rem;
    }
    &::after {
      top: 0.5rem;
    }
  }

  &__sign-btn {
    font-size: 1rem;
    font-weight: bold;
    font-family: $nunito;
    list-style-type: none;
    text-decoration: none;
    @include max-media(tablet) {
      display: none;
      &.mobile {
        display: block;
      }
    }
    .regis-btn {
      margin: auto 1rem;
      background-color: $primary-color;
      font-weight: bold;
      color: white;
      border-radius: 10px;
      padding: 5px 1rem;
    }

    a {
      color: $primary-color;

      &:hover {
        color: lighten($color: $primary-color, $amount: 10);
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}

#navbar-landing {
  top: 2rem;
}
</style>
