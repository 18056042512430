import axios from "axios";
import { getToken } from "@/utils/auth";
import { SnackbarProgrammatic as Snackbar } from "@/components/Snackbar";
import store from "@/store/index";

const pending = new Map();
/**
 *Add request
 * @param {Object} config
 */
export const addPending = config => {
  const url = [config.method, config.url].join("&");
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken(cancel => {
      if (!pending.has(url)) {
        // if the current request does not exist in pending, add it
        pending.set(url, cancel);
      }
    });
};
/**
 *Remove request
 * @param {Object} config
 */
export const removePending = config => {
  const url = [config.method, config.url].join("&");
  if (pending.has(url)) {
    // if the current request ID exists in pending, you need to cancel the current request and remove it
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};
/**
 *Clear requests in pending (called on route jump)
 */
export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};

// const isWhiteListConnectionTimeout = url => {
//   const url_regex = new RegExp(
//     /(^[\/]content[\/]carousel$)|(^[v/]content[\/]carousel[\/].*$)|(^[\/]content[\/]quiz-problemset[\/]length$)|(^[\/]content[\/]problemset[\/]length$)|(^[\/]notification[\/].*$)|(^[\/]notification$)/ // eslint-disable-line
//   );
//   return url_regex.test(url);
// };
const AXIOS_CONFIGURATION = {
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000 // request timeout
  // timeout: 1 // request timeout
};
const headerConfiguration = config => {
  const configuration = { ...config };
  config.headers.authorization = `BEARER ${getToken()}`;
  return configuration;
};
const errorResponseHandling = error => {
  console.error({ ...error });
  if (!error.response) {
    // Handle case when loading component keep displayed at error connection timeout
    // const errorURL = error.config.url.split("?")[0];
    // if (!isWhiteListConnectionTimeout(errorURL)) {
    if (store.getters["State/getLoadingStatus"]) {
      store.dispatch("State/setLoadingStatus", false);
    }
    store.dispatch("State/setTimeoutStatus", true);
    // }
  } else if (!error.response.status) {
    Snackbar.open({
      message: "Halamat gagal dimuat sepenuhnya",
      type: "is-warning",
      position: "is-top",
      actionText: "Muat Ulang",
      duration: 5000,
      onAction: () => {
        window.location.reload();
      }
    });
  } else if (error.response.status >= 500) {
    if (!isRouteWhiteLIsted(error.config.url)) {
      Snackbar.open({
        message: "Terjadi kesalahan server",
        type: "is-warning",
        position: "is-top",
        actionText: "Muat Ulang",
        duration: 5000,
        onAction: () => {
          window.location.reload();
        }
      });
    }
  }
};
const errorRequestHandling = error => {
  console.error(error); // for debug
};

const routeWhiteLists = ["/auth/username", "/ranker-worker/event"];

const isRouteWhiteLIsted = route => {
  return routeWhiteLists.find(item => route.includes(item));
};
// create an axios instance
const service = axios.create(AXIOS_CONFIGURATION);
const pendingService = axios.create(AXIOS_CONFIGURATION);

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (getToken()) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      return headerConfiguration(config);
    }
    return config;
  },
  error => {
    // do something with request error
    errorResponseHandling(error);
    return Promise.reject(error);
  }
);
pendingService.interceptors.request.use(
  config => {
    removePending(config);
    addPending(config);
    return headerConfiguration(config);
  },
  error => {
    // do something with request error
    errorRequestHandling(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    errorResponseHandling(error);
    return Promise.reject(error);
  }
);
pendingService.interceptors.response.use(
  response => {
    removePending(response);
    return response;
  },
  error => {
    if (!axios.isCancel(error)) {
      errorResponseHandling(error);
    }
    return Promise.reject({ ...error, isCancel: axios.isCancel(error) });
  }
);

export default service;
