import service from "@/utils/request";

export default class Store {
  static baseGroupURL = "store";

  static async fetchList(params) {
    const resp = await service.get(`${this.baseGroupURL}`, { params });
    return resp;
  }

  static async fetchById(id) {
    const resp = await service.get(`${this.baseGroupURL}/${id}`);
    return resp;
  }
}
