<script>
import { millisecondToTime } from "@/utils/datetime";

export default {
  name: "ModalPomodoroResting",
  props: {
    show: Boolean,
    isLongBreak: {
      type: Boolean,
      default: false
    },
    remain: {
      type: Number,
      default: 0
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    miliToHMS(remain) {
      const result = millisecondToTime(remain, false);

      let hours = result.hours < 10 ? "0" + result.hours : result.hours,
        minutes = result.minutes < 10 ? "0" + result.minutes : result.minutes,
        seconds = result.seconds < 10 ? "0" + result.seconds : result.seconds;

      return hours + ":" + minutes + ":" + seconds;
    }
  },
  computed: {
    renderText() {
      let text = "Waktunya istirahat ";
      if (this.isLongBreak) {
        text += "35";
      } else {
        text += "5";
      }
      text += " menit!";

      return text;
    }
  }
};
</script>

<template>
  <ModalGeneral :show="show" @close="close" :fixed="true" :disable-close-btn="true">
    <div :class="$style.modal_pomodoro_container">
      <div :class="$style.content">
        <h1>{{ renderText }}</h1>
        <img :src="`https://assets.edukasystem.com/gif/lofi_pomodoro.gif`" />
        <p>
          {{ miliToHMS(remain) }}
        </p>
      </div>
      <div :class="$style.action">
        <button @click="close" class="btn btn-secondary">Mengerti</button>
      </div>
    </div>
  </ModalGeneral>
</template>

<style lang="scss" module>
.modal_pomodoro_container {
  overflow-y: auto;
  width: 80vw;
  margin: 0 auto;
  padding: 1rem;

  @include min-media(tablet) {
    width: 550px;
    padding: 1.5rem;
    margin: auto;
  }

  .content {
    h1 {
      font-weight: 700;
      font-size: 1rem;
      @include min-media(tablet) {
        font-size: 1.5rem;
      }
    }

    img {
      margin: 1rem 0;
      max-width: 200px;
    }

    p {
      font-size: 1.25rem;
      margin: 1rem 0;
      font-weight: 700;
      color: $purple;

      @include min-media(tablet) {
        font-size: 1.5rem;
      }
    }
  }

  .action {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1.5rem;

    @include min-media(tablet) {
      gap: 1rem;
    }

    button {
      width: 100%;
      max-width: 175px;
      border-radius: 80px;
      font-size: 0.875rem;
      font-weight: bold;
      @include min-media(tablet) {
        font-size: 1rem;
      }
    }
  }
}
</style>
