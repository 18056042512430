<script>
export default {
  props: {
    separator: {
      type: String,
      default: "/"
    }
  },
  data() {
    return {
      levelList: null,
      title: ""
    };
  },
  async mounted() {
    const { title_params, breadcrumb } = this.$route.meta;
    this.levelList = title_params ? await breadcrumb(this.$route) : breadcrumb;
    this.title = this.$route.meta.title;
  },
  watch: {
    async $route(to) {
      const { title_params, breadcrumb } = to.meta;
      this.levelList = title_params ? await breadcrumb(to) : breadcrumb;
      this.title = to.meta.title;
    }
  },
  methods: {
    handleLink(item) {
      if (item.routeParams) this.$router.push({ name: item.routeName, params: item.routeParams });
      else this.$router.push({ name: item.routeName });
    }
  }
};
</script>

<template>
  <div class="app-breadcrumb">
    <transition-group name="breadcrumb" class="breadcrumb-container">
      <div class="breadcrumb-item" v-for="(item, index) in levelList" :key="index + item.routeName">
        <span v-if="index == levelList.length - 1" class="no-redirect">{{ item.name }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ item.name }}</a>
        <span v-if="index != levelList.length - 1" class="separator">{{ separator }}</span>
      </div>
    </transition-group>
    <transition name="breadcrumb">
      <h1 v-if="title" class="page-title">{{ title }}</h1>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all 0.5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-move {
  transition: all 0.5s;
}

.breadcrumb-leave-active {
  position: absolute;
}

.app-breadcrumb {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 2rem 0;
  max-width: 1064px;
  gap: 1rem;

  @include max-media(small-tablet) {
    display: none;
  }

  @include min-media(desktop) {
    padding: 2rem 1rem;
  }

  .page-title {
    color: black;
    font-size: 1.25rem;
  }

  .breadcrumb-container {
    display: flex;
    flex-direction: row;

    .breadcrumb-item {
      display: flex;
      flex-direction: row;

      a {
        color: $purple;
      }
      &:not(:last-child) {
        margin-right: 0.625rem;
      }
      .separator {
        margin-left: 0.625rem;
      }
    }
  }
}
</style>
