<script>
/**
 * TODO:
 * [] 31/03/2021 : get state from parent component (Field especially)
 * [] 31/03/2021 : remove selected from option slotted, fix issue 
 *                 data doesn't sync when option populated for the first time,
 *                 selected on option attribute is just a temporary fix
 */
export default {
  name: 'CustomSelect',
  props: {
    value: null,
    state: {
      type: String,
      validator: (value) => {
        return ['first', 'danger', 'success'].indexOf(value) !== -1
      },
      default: 'first'
    },
    placeholder: String
  },
  computed: {
    bodyClass() {
      const { danger, body, success } = this.$style
      switch(this.state) {
        case 'danger': return [body, danger]
        case 'success': return [body, success]
        case 'first': 
        default:
          return body
      }
    }
  },
  watch: {
    value(val) {
      this.selected = val;
    }
  },
  data() {
    return {
      selected: null,
      firstTime: true
    }
  },
  methods: {
    emitValue(e) {
      this.selected = e.target.value;
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<template>
  <div :class="bodyClass">
    <select
      :value="selected"
      :class="$style.input"
      @change="emitValue"
      @blur="emitValue"
      ref="select"
      :v-bind="$attrs"
    >
      <template v-if="placeholder">
        <option
            v-if="selected == null"
            :value="null"
            disabled
            hidden
>
            {{ placeholder }}
        </option>
      </template>
      <slot></slot>
    </select>
    <svg-icon icon-class="chevron-down" :class-name="$style.chevron" />
  </div>
</template>

<style module lang="scss">
.input {
  width: 100%;
  padding: .5rem;
  font-size: 1rem;
  border-radius: .25rem;
  appearance: none;
  border: 1px solid rgba(0, 0, 0, .2);
  box-shadow: inset 0 0.1em 0.25em rgba(56, 40, 40, 0.15);
  transition: all .2s ease;
  &:focus {
    outline: none;
    box-shadow: inset 0 0.1em 0.25em rgba($orange, .25),  0 0 0.25em rgba($orange, 1);
  }

  &::-ms-expand {
    display: none;
  }
}

.body {
  position: relative;

  &:hover {
    .chevron {
      filter: grayscale(10);
    }
  }
}

.chevron {
  font-size: 2rem;
  color: $orange;
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all .2s ease;
}

.success {
  .input {
    border: 1px solid $correct;
    &:focus {
      box-shadow: inset 0 0.1em 0.25em rgba(0, 0, 0, .15),  0 0 0.25em rgba($correct, 1);
    }
  }
}

.danger {
  .input {
    border: 1px solid #f14668;
    &:focus {
      box-shadow: inset 0 0.1em 0.25em rgba(0, 0, 0, .15),  0 0 0.25em rgba(#f14668, 1);
    }
  }
}
</style>
