import spacetime from "spacetime";

const spacetimeConfig = spacetime();
const _MS_PER_DAY = 1000 * 60 * 60 * 24;

spacetimeConfig.i18n({
  days: {
    long: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
    short: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"]
  },
  months: {
    long: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember"
    ],
    short: ["jan", "feb", "mar", "apr", "mei", "jun", "jul", "agu", "sep", "okt", "nov", "des"]
  }
});

export function toReadable(date, format = "EEEE, dd MMMM YYYY") {
  if (format) {
    return spacetime(date, "Asia/Jakarta").unixFmt(format);
  }
  return spacetime(date, "Asia/Jakarta").unixFmt("EEEE, dd MMMM YYYY");
}

import { MONTHS, MONTHS_ID } from "@/constants/date";

/**
 * Check date is incoming, same or past than date now
 * @param {Date} dateTested
 * @param {number} currentTimestamp
 * @returns {number} 0 = past, 1 = current, 2 = incoming
 */
export const checkDateStatus = (dateTested, currentTimestamp) => {
  //
  if (currentTimestamp === dateTested.getTime()) {
    return 1;
  }
  if (currentTimestamp > dateTested.getTime()) {
    return 0;
  }
  return 2;
};

/**
 *
 * @param {Date} date
 * @returns {string} formatted date string (dd mmmm yyyy)
 */
export const dateToStringFull = date => {
  return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
};
export const dateToStringFullID = date => {
  return `${date.getDate()} ${MONTHS_ID[date.getMonth()]} ${date.getFullYear()}`;
};

/**
 *
 * @param {Date} date
 * @returns {string} formatted date string (dd/mm/yyyy)
 */
export const dateToStringShort = date => {
  return `${pad(date.getDate(), 2)}/${pad(date.getMonth() + 1, 2)}/${date.getFullYear()}`;
};

/**
 *
 * @param {number} num current number to be padded
 * @param {number} width digit number
 * @param {string} [padchar='0'] filler character if current number digits is less than width
 * @returns {string} padded number
 * @example
 * // returns 0003
 * pad(3, 4)
 * @example
 * // returns ..23
 * pad(23, 4, '.')
 */
export const pad = (num, width, padchar = "0") => {
  var pad = new Array(1 + width).join(padchar);
  return (pad + num).slice(-pad.length);
};

/**
 *
 * @param {number} millisecond
 * @returns {Object} time object {days, hours, minutes, seconds}
 */
export const millisecondToTime = (millisecond, isConvertDays = true) => {
  let totalSeconds = Math.floor(millisecond / 1000);
  const days = Math.floor(totalSeconds / (24 * 3600));
  totalSeconds %= 24 * 3600;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return {
    days: isConvertDays ? days : 0,
    hours: isConvertDays ? hours : days * 24 + hours,
    minutes,
    seconds
  };
};

/**
 *
 * @param {number} days
 * @param {(Date|Object)} time - Date Object or Object {hours, minutes, seconds}
 * @returns {number} number of millisecond based on days and time
 *
 */
export const calculateDurationInMillisecond = (days, time) => {
  if (time instanceof Date) {
    return (
      (days * 24 * 3600 + time.getHours() * 3600 + time.getMinutes() * 60 + time.getSeconds()) *
      1000
    );
  }
  return (days * 24 * 3600 + time.hours * 3600 + time.minutes * 60 + time.seconds) * 1000;
};

/**
 *
 * @param {number} startTime
 * @param {number} finishTime
 * @param {number} duration
 * @param {number} timeNow
 * @returns {number} remaining time based on parameter
 */
export const calculateRemainingTime = (startTime, finishTime, duration, timeNow) => {
  const lastTime = startTime + duration;
  return Math.min(Math.max(lastTime - timeNow, 0), Math.max(finishTime - timeNow, 0));
};

/**
 *
 * @param {number} miliseconds
 * @returns {number} remaining time based on parameter
 */
export const milisecondsToDay = miliseconds => {
  const totalSeconds = Math.floor(miliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const day = Math.round(hours / 24);
  return day;
};

export function calculateTime(startTime, finishTime, duration, timeNow) {
  const lastTime = startTime + duration;
  return Math.min(Math.max(lastTime - timeNow, 0), Math.max(finishTime - timeNow, 0));
}

export function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}



// date1 and date2 are javascript Date objects
export const dateDiffInDays =(date1, date2) =>{
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}