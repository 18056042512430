import service from "@/utils/request";
import { getUserId } from "@/utils/user";

export default class Enrollment {
  static baseGroupURL = "enrollment";

  static async getUserEnrollments(params) {
    const res = service.get(`${this.baseGroupURL}/user/${getUserId()}/event`, { params });
    return res;
  }

  static async getEnrollmentStatus(id_event) {
    const res = service.get(`${this.baseGroupURL}/user/${getUserId()}/event/${id_event}`);
    return res;
  }

  static async getEnrollmentsCount(id_event) {
    const res = service.get(`${this.baseGroupURL}/event/${id_event}/count`);
    return res;
  }

  static async getAllTopicEnrolled(params) {
    const resp = await service.get(`${this.baseGroupURL}/user/${getUserId()}/topic`, { params });
    return resp;
  }

  static async getAllQuizEnrolled() {
    const resp = await service.get(`${this.baseGroupURL}/user/${getUserId()}/quiz`);
    return resp;
  }

  static async getTopicEnrollmentById(id_topic, params) {
    const resp = await service.get(
      `${this.baseGroupURL}/user/${getUserId()}/topic/${id_topic}`,
      { params }
    );
    return resp;
  }
}
