import service from "@/utils/request";

export default class Topic {
  static baseGroupURL = "content/code-major";

  static async getCodeMajor(id_major) {
    const res = await service.get(`${this.baseGroupURL}/${id_major}`);
    return res;
  }
}
