<script>
import Auth from "@/api/auth";
import User from "@/api/user";

import { removeUserId } from "@/utils/user";
import { removeToken, getToken } from "@/utils/auth";
import { removeUnusedCache } from "@/utils/general";
export default {
  name: "BottomBar",
  props: {
    showPromo: {
      type: Boolean,
      default: false
    },
    isTrial: Boolean
  },
  data() {
    return {
      organization: ""
    };
  },
  async created() {
    const userDetails = await User.getUserDetails().then(res => res.data.data);
    this.organization = userDetails.organization.split(',')[0];
  },
  computed: {
    tab() {
      if (this.organization !== "") {
        return [
          {
            name: "Test",
            url: "/event/tryout",
            iconName: "tryout"
          },
          {
            name: "Akun",
            url: "/profile",
            iconName: "profile"
          }
        ]
      } else {
        return [
          {
            name: "Beranda",
            url: "/dashboard",
            iconName: "home"
          },
          {
            name: "Bank Soal",
            url: "/bank-soal",
            iconName: "bank-soal-nav",
            highlight: true
          },
          {
            name: "Tryout",
            url: "/event/tryout",
            iconName: "tryout"
          },
          {
            name: "Pembelian",
            url: "/eduka-pay",
            iconName: "cart"
          },
          {
            name: "Akun",
            url: "/profile",
            iconName: "profile"
          }
        ]
      }
    }
  },
  methods: {
    changePath(path, out) {
      if (out) {
        window.location.href = path;
      } else this.$router.push(path);
    },
    async handleClick(path, out) {
      if (path === "/profile") {
        if (this.isTrial) await this.goToSSO();
        else this.changePath(path, out);
      } else this.changePath(path, out);
    },
    async goToSSO() {
      const token = getToken();
      this.$honeybadger?.resetContext();
      await Auth.blacklistToken(token);
      removeToken();
      removeUserId();
      removeUnusedCache();
      window.location = `${
        process.env.VUE_APP_SSO
      }/auth/logout?redirect=${`${process.env.VUE_APP_SSO}/auth/login`}`;
    }
  }
};
</script>

<template>
  <div :class="$style.bottom_bar">
    <div
      v-for="(el, idx) in tab"
      :class="[$style.bottom_bar__element, $route.path === el.url ? $style.active : '']"
      :key="`tab-bottom-${idx}`"
      @click="() => handleClick(el.url, el.out)"
    >
      <p v-if="el.name === 'Pembelian' && showPromo && !isTrial" :class="$style.promo_label">
        PROMO
      </p>
      <div :class="$style.icon__container">
        <svg-icon
          :icon-class="el.iconName"
          :class="[$style.icon, ['Tryout','Test'].includes(el.name) ? $style.tryout : '']"
        />
        <div :class="$style.dot"></div>
      </div>
      {{ el.name === "Akun" && isTrial ? "Masuk" : el.name }}
    </div>
  </div>
</template>

<style lang="scss" module>
.bottom_bar {
  display: none;
  @include max-media(mobile) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    height: 60px;
    box-shadow: 0 -0.5px 2px 0 rgba(0, 0, 0, 0.16);
    color: #9e9e9e;
    padding: 8px 16px;
    @include max-media(small-mobile) {
      padding: 0.25rem;
    }
  }
  &__element {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    transition: all 0.2s ease;
    position: relative;

    .icon {
      &__container {
        position: relative;
      }
      &.tryout {
        stroke: #9e9e9e;
      }
    }

    .promo_label {
      position: absolute;
      z-index: 3;
      top: -2px;

      background: #d50b00;
      border-radius: 4px;
      font-weight: bold;

      line-height: 13.64px;
      color: white;
      font-size: 10px;
      width: 46px;
      padding: 0 4px;
    }

    &.active {
      color: $purple;

      .dot {
        z-index: 1;
        position: absolute;
        width: 1rem;
        height: 1rem;
        background: #f9be63;
        border-radius: 50%;

        left: 50%;
        right: 0%;
        top: 25%;
        bottom: 5%;
      }

      .icon {
        color: $purple;
        z-index: 2;
        position: relative;

        &.tryout {
          stroke: $purple;
        }
      }
    }
    .icon {
      font-size: 24px;
      transition: all 0.5s ease;
    }
  }
}
</style>
