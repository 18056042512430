import service from "@/utils/request";
import { getUserId } from "@/utils/user";
// import serviceMock from "@/utils/mock-request";

export default class Game {
  static baseGroupURL = "game";

  static async getTrackerPlan() {
    const res = await service.get(`${this.baseGroupURL}/tracker-plan`);
    return res;
  }

  static async createTrackerPlan(data) {
    const res = await service.post(`${this.baseGroupURL}/tracker-plan`, data);
    return res;
  }

  static async updateTrackerPlan(data) {
    const res = await service.put(`${this.baseGroupURL}/tracker-plan`, data);
    return res;
  }

  static async getDailyProgress() {
    const res = await service.get(`${this.baseGroupURL}/progress/daily`);
    return res;
  }

  static async getMajorGroupProgress(id_major_group) {
    const res = await service.get(`${this.baseGroupURL}/progress/major-group/${id_major_group}`);
    return res;
  }

  static async getChapterRecommendation(id_code_major) {
    const res = await service.get(
      `${this.baseGroupURL}/recommendation/code-major/${id_code_major}/chapter`
    );
    return res;
  }

  static async getCodeMajorProgressByMajorGroup(id_major_group) {
    const res = await service.get(
      `${this.baseGroupURL}/progress/major-group/${id_major_group}/code-major`
    );
    return res;
  }

  static async getCodeMajorProgress(id_code_major) {
    const res = await service.get(`${this.baseGroupURL}/progress/code-major/${id_code_major}`);
    return res;
  }

  // Mock service implementation
  static async getUserStatus() {
    const res = await service.get(`${this.baseGroupURL}/status`);
    return res;
  }

  static async getUserStatusById(id_user) {
    const res = await service.get(`${this.baseGroupURL}/status/user/${id_user}`);
    return res;
  }

  static async getBadgesOfUser(params) {
    const res = await service.get(`${this.baseGroupURL}/badges-user`, { params });
    return res;
  }

  static async getBordersOfUser() {
    const res = await service.get(`${this.baseGroupURL}/borders-user`);
    return res;
  }

  static async getQuestsOfUser() {
    const res = await service.get(`${this.baseGroupURL}/quests-user`);
    return res;
  }

  static async getAvatarOfUser(params) {
    const res = await service.get(`${this.baseGroupURL}/avatar-user`, { params });
    return res;
  }

  static async setAvatarOfUser(data) {
    const res = await service.post(`${this.baseGroupURL}/avatar-user`, data);
    return res;
  }

  static async getBadgesOfUserPreview(params) {
    const res = await service.get(`${this.baseGroupURL}/badges-user/preview`, { params });
    return res;
  }

  static async getBadgesCountPercentage(id_badge) {
    const res = await service.get(`${this.baseGroupURL}/badges-user/count/${id_badge}`);
    return res;
  }

  static async getAllClaimableItems() {
    const res = await service.get(`${this.baseGroupURL}/reward`);
    return res;
  }

  static async postRewardClaimAll() {
    const res = await service.post(`${this.baseGroupURL}/reward/claim?all=true`);
    return res;
  }

  static async postRewardClaim(data) {
    const res = await service.post(`${this.baseGroupURL}/reward/claim`, data);
    return res;
  }

  static async addUserShare() {
    const res = await service.post(`${this.baseGroupURL}/share/add`);
    return res;
  }

  // Scheduling
  static async getDaySchedule(dateString) {
    const res = await service.get(`${this.baseGroupURL}/schedule/event/day/${dateString}`);
    return res;
  }

  static async getMonthlySchedule(year, month) {
    const res = await service.get(
      `${this.baseGroupURL}/schedule/event/year/${year}/month/${month}`
    );
    return res;
  }

  static async getScheduleById(id) {
    const res = await service.get(`${this.baseGroupURL}/schedule/event/${id}`);
    return res;
  }

  static async updateSchedule(id, data) {
    const res = await service.put(`${this.baseGroupURL}/schedule/event/${id}`, data);
    return res;
  }

  static async postSchedule(data) {
    const res = await service.post(`${this.baseGroupURL}/schedule/event`, data);
    return res;
  }

  static async getTimerUser() {
    const res = await service.get(`${this.baseGroupURL}/schedule/timer`);
    return res;
  }

  static async startEventTimer(id_event, time) {
    const res = await service.post(
      `${this.baseGroupURL}/schedule/timer/event/${id_event}/time/${time}/start`
    );
    return res;
  }

  static async deleteSchedule(id_event) {
    const res = await service.delete(`${this.baseGroupURL}/schedule/event/${id_event}`);
    return res;
  }

  static async finishSchedule(id_event, event_time) {
    const res = await service.post(
      `${this.baseGroupURL}/schedule/event/${id_event}/time/${event_time}/finished`
    );
    return res;
  }

  static async pauseEventTimer(id_event, time) {
    const res = await service.post(
      `${this.baseGroupURL}/schedule/timer/event/${id_event}/time/${time}/pause`
    );
    return res;
  }

  static async stopEventTimer(id_event, time) {
    const res = await service.post(
      `${this.baseGroupURL}/schedule/timer/event/${id_event}/time/${time}/stop`
    );
    return res;
  }

  static async unfinishSchedule(id_event, event_time) {
    const res = await service.post(
      `${this.baseGroupURL}/schedule/event/${id_event}/time/${event_time}/unfinished`
    );
    return res;
  }

  static async getUpcomingEvent() {
    const res = await service.get(`${this.baseGroupURL}/schedule/event/upcoming`);
    return res;
  }

  static async getAllUserSchedule() {
    const res = await service.get(`${this.baseGroupURL}/schedule/event/user/${getUserId()}`);
    return res;
  }

  static async getAllUserTimerStatistic() {
    const res = await service.get(`${this.baseGroupURL}/schedule/timer/event/user/${getUserId()}`);
    return res;
  }

  static async getGeneralEvents(year, month) {
    const res = await service.get(`${this.baseGroupURL}/general/event/year/${year}/month/${month}`);
    return res;
  }
}
