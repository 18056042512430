import Vue from "vue";
import Auth from "@/api/auth";
import Time from "@/api/time";
import { removeToken } from "@/utils/auth";
import { setUserId, removeUserId } from "@/utils/user";
import { removeUnusedCache } from "@/utils/general";

const reset = url => {
  removeToken();
  removeUserId();
  removeUnusedCache();
  Vue.$honeybadger?.resetContext();
  window.location = `${window.location.origin}?redirect=${url.path}&options=${JSON.stringify(
    url.query
  )}`;
};

export async function requireToken(token, url) {
  try {
    const res_verif = await Auth.validateToken(token).then(res => res);
    const { exp, id } = res_verif.data.data;

    const now = await Time.getTime().then(res => res.data.timestamp);
    const date_exp = exp * 1000;
    if (date_exp > now) {
      setUserId(id);
    } else {
      reset(url);
    }
  } catch (err) {
    const { response } = err;
    if (response && (response.status === 500 || response.status === 422)) {
      reset(url);
    }
  }
}
