import { getLSObjectWithExpiry } from "@/utils/general";
import { getUserId } from "@/utils/user";
import { isObjectEmpty } from "@/utils/helpers";

import Exam from "@/api/exam";
import ExamQuiz from "@/api/exam-quiz";
import Game from "@/api/game";
import Summary from "@/api/summary";

const id_user = getUserId();

export function saveDataTrial() {
  saveRangkumanMiniquiz();
  saveScheduling();
  saveTracker();
  saveQuiz();
  saveAptitude();
}

/* ------------------------------------------------------------------------------------- */
/*
  BANK SOAL CONCEPT
  - Handle checked rangkuman
  - Handle answered miniquiz
*/
const saveRangkumanMiniquiz = async () => {
  let date = new Date();

  const { data, expired } = getLSObjectWithExpiry("trial:rangkuman", date.getTime());

  if (!expired) {
    await Promise.all(
      data.map(async element => {
        const { summary_checked, id_concept, miniquiz, id_miniquiz, mode } = element;

        // Set Checked Rangkuman
        if (summary_checked) await Summary.createSummaryProgress({ id_user, id_concept });

        const exam = await ExamQuiz.getMiniQuizExam({
          userId: id_user,
          miniQuizId: id_miniquiz
        }).then(res => res.data.data);

        if (!exam) {
          // Set Answered Miniquiz
          if (miniquiz.length) {
            // create new exam event
            const createExamData = {
              id_mini_quiz: id_miniquiz,
              id_user
            };

            const newExam = await ExamQuiz.createMiniQuizExam(createExamData).then(
              res => res.data.data
            );
            const miniquizExamId = newExam.id_mini_quiz_exam;
            await ExamQuiz.startMiniQuizExam(miniquizExamId);

            // Submit answersheet
            await sendAnswerMiniquiz(miniquizExamId, miniquiz);

            // Finish Miniquiz
            if (mode === "solution") await ExamQuiz.finishMiniQuizExam(miniquizExamId);
          }
        }
      })
    );

    localStorage.removeItem("trial:rangkuman");
  }
};

const sendAnswerMiniquiz = async (miniquizExamId, miniquiz) => {
  await Promise.all(
    miniquiz.map(async element => {
      const { id_choice, id_question } = element;
      await ExamQuiz.answerMiniQuizQuestion(miniquizExamId, id_question, { id_choice });
    })
  );
};
/* ------------------------------------------------------------------------------------- */
/*
  SCHEDULING
  - Create schedule
  - Finish schedule if is_finished: true
*/
const saveScheduling = async () => {
  let date = new Date();
  const { data, expired } = getLSObjectWithExpiry("trial:scheduling", date.getTime());

  if (!expired) {
    // Create body for post
    const key = Object.keys(data)[0];
    let schedule = data[key][0];

    const {
      title,
      type,
      description,
      link,
      start_time,
      end_time,
      duration,
      label,
      recurrence,
      expired_time,
      reminder,
      related_id,
      is_finished,
      is_pomodoro
    } = schedule;

    let params = {
      title,
      type,
      description,
      link,
      start_time,
      end_time,
      duration,
      label,
      recurrence,
      expired_time,
      reminder,
      is_pomodoro
    };

    params["related_id"] = related_id ? related_id : [];

    const res = await Game.postSchedule(params).then(res => res.data.data);
    if (res && is_finished) {
      const { id_event, start_time } = res;
      await Game.finishSchedule(id_event, start_time);
    }

    localStorage.removeItem("trial:scheduling");
    localStorage.removeItem("trial:timer");
  }
};
/* ------------------------------------------------------------------------------------- */
/*
  AMBIS TRACKER
  - Handle create tracker
*/
const saveTracker = async () => {
  let date = new Date();

  const { data, expired } = getLSObjectWithExpiry("trial:tracker", date.getTime());

  if (!expired) {
    const tracker = await Game.getTrackerPlan().then(res => res.data.data);
    if (!tracker) {
      await Game.createTrackerPlan(data);
    }
    localStorage.removeItem("trial:tracker");
  }
};
/* ------------------------------------------------------------------------------------- */
/*
  QUIZ
  - Handle answered quiz
*/
const saveQuiz = async () => {
  let date = new Date();

  const { data, expired } = getLSObjectWithExpiry("trial:quiz", date.getTime());

  if (!expired) {
    await Promise.all(
      data.map(async el => {
        const { id_quiz, finished_at, quiz } = el;

        const exam = await ExamQuiz.getQuizExamId(id_quiz).then(res => res.data.data);
        if (!exam) {
          if (quiz && !isObjectEmpty(quiz)) {
            // Create quiz exam and start
            const createExamData = {
              id_quiz,
              id_user
            };
            const exam = await ExamQuiz.createQuizExam(createExamData).then(res => res.data.data);
            const examId = exam.id_quiz_exam;
            await ExamQuiz.startQuizExam(examId);

            // Submit answersheet
            await sendAnswerQuiz(examId, quiz);

            // finish if necessary
            if (finished_at) {
              await ExamQuiz.finishQuizExam(examId);
            }
          }
        }
      })
    );

    localStorage.removeItem("trial:quiz");
  }
};

const sendAnswerQuiz = async (quizExamId, quiz) => {
  for (let id_question in quiz) {
    if (quiz[id_question].id_choice && quiz[id_question].duration)
      await ExamQuiz.answerQuizQuestion(quizExamId, id_question, {
        id_choice: quiz[id_question].id_choice,
        duration: quiz[id_question].duration
      });
  }
};
/* ------------------------------------------------------------------------------------- */
/*
  APTITUDE
  - Handle answered aptitude
*/
const saveAptitude = async () => {
  let date = new Date();

  const { data, expired } = getLSObjectWithExpiry("trial:tkj", date.getTime());

  if (!expired) {
    const { id_aptitude, finished_at, answersheet } = data;

    const examData = await Exam.getAptitudeExamEntity(id_aptitude).then(res => res.data.data);
    if (!examData) {
      if (answersheet && !isObjectEmpty(answersheet)) {
        // Create quiz exam and start
        const createExamData = {
          id_aptitude,
          id_user
        };
        const exam = await Exam.createAptitudeExam(createExamData).then(res => res.data.data);
        const examId = exam.id_aptitude_exam;
        await Exam.startAptitudeExam(examId);

        // Submit answer
        for (let id_question in answersheet) {
          await Exam.putAptitudeAnswer(examId, id_question, {
            id_choice: answersheet[id_question]
          });
        }

        // finish if necessary
        if (finished_at) {
          await Exam.finishAptitudeExam(examId);
        }
      }
    }

    localStorage.removeItem("trial:tkj");
  }
};
/* ------------------------------------------------------------------------------------- */
