/**
 * Check item isObject or not
 * @param {object} item
 * @returns {boolean}
 */
export const isObject = item => typeof item === "object" && !Array.isArray(item);

/**
 * Merge 2 Objects
 * @param {object} target
 * @param {object} source
 * @param {object} deep ( default = false )
 * @returns {object}
 */
export const mergeObj = (target, source, deep = false) => {
  if (deep || !Object.assign) {
    const isDeep = prop =>
      isObject(source[prop]) &&
      target !== null &&
      Object.hasOwnProperty.call(target, prop) &&
      isObject(target[prop]);

    const replaced = Object.getOwnPropertyNames(source)
      .map(prop => ({
        [prop]: isDeep(prop) ? mergeObj(target[prop], source[prop], deep) : source[prop]
      }))
      .reduce((a, b) => ({ ...a, ...b }), {});

    return {
      ...target,
      ...replaced
    };
  } else {
    return Object.assign(target, source);
  }
};

export function removeElement(el) {
  if (typeof el.remove !== "undefined") {
    el.remove();
  } else if (typeof el.parentNode !== "undefined" && el.parentNode !== null) {
    el.parentNode.removeChild(el);
  }
}

export function isObjectEmpty(obj) {
  //eslint-disable-next-line
  for (let _key in obj) return false;
  return true;
}
