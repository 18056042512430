<script>
export default {
  name: "ModalGeneral",
  props: {
    show: Boolean,
    disableClose: Boolean,
    disableBgClick: Boolean,
    disableCloseBtn: Boolean,
    fixed: {
      type: Boolean,
      default: true
    },
    submitText: {
      type: String,
      default: "Ya, lanjutkan"
    },
    cancelText: String,
    modalType: {
      type: String,
      validator: value => {
        return ["popup", "slider", "fullscreen", "special-slider"].indexOf(value) !== -1;
      },
      default: "popup"
    },
    customClass: {
      type: Object,
      default: () => ({
        wrapper: "",
        body: "",
        closeBtn: ""
      })
    },
    closeIcon: String,
    title: String,
    description: String,
    image: String,
    name: String,
    disablePadding: {
      type: Boolean,
      default: false
    }
    // name required if there are chaining modal,
    // so component can trigger animation
  },
  computed: {
    wrapperClass() {
      const { wrapper, active, out, noDelay, withPadding, fixed } = this.$style;
      return [
        wrapper,
        this.fixed ? fixed : "",
        this.isFirstTime ? "" : this.show && !this.reset ? active : out,
        this.modalType === "slider" || this.modalType === "special-slider" ? noDelay : "",
        this.isSlotFilled ? "" : withPadding,
        this.customClass.wrapper
      ];
    },
    bodyClass() {
      const { body, bodySlider, disablePadding, enablePadding, specialSlider, fullscreen } =
        this.$style;
      return [
        body,
        this.modalType === "slider" ? bodySlider : "",
        this.modalType === "special-slider" ? specialSlider : "",
        this.disablePadding ? disablePadding : enablePadding,
        this.modalType === "fullscreen" ? fullscreen : "",
        this.customClass.body
      ];
    },
    isSlotFilled() {
      return !!this.$slots.default;
    }
  },
  data() {
    return {
      isMounted: false,
      reset: false,
      isFirstTime: true
    };
  },
  watch: {
    name() {
      if (this.isMounted) {
        this.reset = true;
        setTimeout(() => {
          this.reset = false;
        }, 0);
      }
    },
    show(val) {
      if (val) {
        this.isFirstTime = false;
      }
    }
  },
  mounted() {
    this.isMounted = true;
    if (this.show) {
      this.isFirstTime = false;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit");
    }
  }
};
</script>

<template>
  <div :class="wrapperClass">
    <div :class="$style.bg" @click="disableBgClick ? null : close()">&nbsp;</div>
    <div :class="bodyClass">
      <svg-icon
        v-if="!disableClose && !disableCloseBtn"
        @click="close"
        :icon-class="closeIcon || 'close'"
        :class-name="`${$style.closeIcon} ${customClass.closeBtn}`"
      />
      <template v-if="!isSlotFilled">
        <h2 :class="$style.title" v-if="title">
          {{ title }}
        </h2>
        <div :class="$style.content" v-if="image || description">
          <img v-if="image" :src="image" alt="poster buwhan edu quiz" />
          {{ description }}
        </div>
        <div :class="$style.actionGroup">
          <button v-if="cancelText" :class="$style.actionBtn" @click="close">
            {{ cancelText }}
          </button>
          <button :class="$style.actionBtn" @submit="submit">
            {{ submitText }}
          </button>
        </div>
      </template>
      <slot v-else-if="!reset"></slot>
    </div>
  </div>
</template>

<style module>
@keyframes showUp {
  0% {
    transform: scale(1);
    background: rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    background: rgba(0, 0, 0, 0.75);
  }
}

@keyframes hide {
  0% {
    transform: scale(1);
    background: rgba(0, 0, 0, 0.75);
  }
  99% {
    transform: scale(1);
    background: rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0);
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes modalShowUp {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    top: 60%;
    /* top: 100%; */
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    top: 50%;
    /* bottom: 0%; */
  }
}

@keyframes modalHide {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    top: 50%;
  }

  99% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    top: 60%;
  }
}

.wrapper {
  --bg-animation-duration: 0.3s;
  --modal-animation-duration: 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  transform: scale(0);
  background: rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

.fixed {
  z-index: 10000;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.bg {
  height: 100%;
  width: 100%;
  position: relative;
}

.active.wrapper {
  animation-fill-mode: forwards;
  animation-name: showUp;
  animation-duration: var(--bg-animation-duration);
  animation-timing-function: ease;
}

.out.wrapper {
  transform: scale(1);
  animation-fill-mode: forwards;
  animation-name: hide;
  animation-delay: var(--modal-animation-duration);
  animation-duration: var(--bg-animation-duration);
  animation-timing-function: ease;
}

.out.noDelay.wrapper {
  animation-delay: 0s;
}

.out .body {
  animation-fill-mode: forwards;
  animation-name: modalHide;
  animation-duration: var(--modal-animation-duration);
  animation-timing-function: ease;
}

.active .body {
  animation-fill-mode: forwards;
  animation-name: modalShowUp;
  animation-duration: var(--modal-animation-duration);
  animation-delay: var(--bg-animation-duration);
  animation-timing-function: ease;
}

.closeIcon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;
}

.withPadding > .body {
  padding: clamp(1rem, 3vw, 3rem);
}

.body {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 90%;
  transform: translate(-50%, -50%) scale(0);
  background-color: white;
  width: fit-content;
  border-radius: 1rem;
  overflow: hidden;
  text-align: center;
}

.title {
  font-size: clamp(1.4rem, 2.5vw, 2rem);
  font-weight: normal;
  color: #f17600;
  margin-bottom: 1rem;
  font-weight: 900;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content > img {
  height: 40vh;
  object-fit: contain;
  margin-bottom: 1rem;
}

.actionGroup {
  text-align: center;
  margin-top: 1rem;
}

.actionBtn {
  width: 12rem;
  height: 50px;
  border-radius: 25px;
  border: solid 1px #f57200;
  font-family: Nunito;
  font-size: 18.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin: 15px 15px 0 15px;
  cursor: pointer;
}

.actionBtn:first-child {
  background-color: #ffffff;
  color: #f57200;
}

.actionBtn:last-child {
  background-color: #f57200;
  color: #ffffff;
}

@media only screen and (max-width: 960px) {
  .actionGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .closeIcon {
    font-size: 1.5rem;
    color: #f57200;
  }

  .actionBtn {
    width: 100%;
  }

  .actionBtn:not(:last-child) {
    margin-top: 1rem;
  }

  .slider > .body {
  }

  .active .bodySlider {
    bottom: 0;
    animation: none;
  }

  .out .bodySlider {
    transition-delay: 0;
    animation: none;
  }

  .bodySlider {
    opacity: 1;
    width: 100%;
    max-width: initial;
    bottom: -100%;
    transition: all var(--modal-animation-duration) ease;
    border-radius: 1rem 1rem 0 0;
    position: absolute;
    background-color: white;
    left: 50%;
    transform: translateX(-50%) scale(1);
    text-align: center;
    top: initial;
  }
  .enablePadding {
    padding: clamp(1rem, 3vw, 3rem);
    padding-bottom: 4rem;
  }

  .disablePadding {
    padding: 0;
  }

  .fullscreen {
    height: 100%;
    width: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
  }
}

@media only screen and (max-width: 425px) {
  .active .specialSlider {
    bottom: 0;
    animation: none;
  }

  .out .specialSlider {
    transition-delay: 0;
    animation: none;
  }

  .specialSlider {
    opacity: 1;
    width: 100%;
    max-width: initial;
    bottom: -100%;
    transition: all var(--modal-animation-duration) ease;
    border-radius: 1rem 1rem 0 0;
    position: absolute;
    background-color: white;
    left: 50%;
    transform: translateX(-50%) scale(1);
    text-align: center;
    top: initial;
  }

  .disablePadding {
    padding: 0;
  }
}
</style>
