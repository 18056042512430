const Province = "province";
const City = "city";
const School = "school";
const Major = "major";
const Campus = "campus";

import { getUserId } from "@/utils/user";

const localStorage = window.localStorage;

export function getProvince() {
  return localStorage.getItem(Province);
}

export function setProvince(data) {
  return localStorage.setItem(Province, data);
}

export function removeProvince() {
  return localStorage.removeItem(Province);
}

export function getCity() {
  return localStorage.getItem(City);
}

export function setCity(data) {
  return localStorage.setItem(City, data);
}

export function removeCity() {
  return localStorage.removeItem(City);
}

export function getSchool() {
  return localStorage.getItem(School);
}

export function setSchool(data) {
  return localStorage.setItem(School, data);
}

export function removeSchool() {
  return localStorage.removeItem(School);
}

export function getMajor() {
  return localStorage.getItem(Major);
}

export function setMajor(data) {
  return localStorage.setItem(Major, data);
}

export function removeMajor() {
  return localStorage.removeItem(Major);
}

export function getCampus() {
  return localStorage.getItem(Campus);
}

export function setCampus(data) {
  return localStorage.setItem(Campus, data);
}

export function removeCampus() {
  return localStorage.removeItem(Campus);
}

export const removeLSItem = key => {
  localStorage.removeItem(key);
};

export const getLSItem = key => {
  return localStorage.getItem(key);
};

export const setLSItem = (key, value) => {
  localStorage.setItem(key, value);
};

/**
 * @description opening new tab with no opener because security and performance issue
 * @param {string} url
 */
export const openInNewTab = url => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

/**
 *
 * @param {string} key - Unique key for storing and identifying your data in local storage.
 * @param {*} values - Value of data you want store in local storage.
 */
export const setLsObject = (key, values) => {
  localStorage.setItem(key, JSON.stringify(values));
};

/**
 *
 * @param {string} key - Unique key for accessing your stored data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getLsObject = key => {
  return JSON.parse(localStorage.getItem(key));
};

/**
 *
 * @param {string} key - Uniue key for storing your cached data in local storage.
 * @param {*} values - Value of data from any endpoint you want to be cached.
 */
export const setCache = (key, values) => {
  setLsObject("cache", {
    ...getLsObject("cache"),
    [key]: {
      ...getLsObject("cache")[key],
      data: values
    }
  });
};

/**
 *
 * @param {string} key - Unique key for accessing your cached data in local storage.
 * @returns {*} - Return any type of data you cached.
 */
export const getCache = key => {
  return getLsObject("cache")?.[key]?.data;
};

/**
 *
 * @param {string} key - Reset cached data.
 */
export const resetThrottle = key => {
  setLsObject("cache", {
    ...getLsObject("cache"),
    [key]: {}
  });
};

/**
 *
 * @param {string} key - Unique key for identifying your data on cache.
 * @param {number} ms - Number of miliseconds that data you want to throttle.
 */
export const throttle = (key, ms) => {
  const now = new Date();
  const item = getLsObject("cache");
  const lastTime = item?.[key]?.time || 0;

  const timeDiff = now.getTime() - lastTime;

  if (timeDiff < ms) {
    return true;
  } else {
    setLsObject("cache", {
      ...getLsObject("cache"),
      [key]: {
        ...getLsObject("cache")?.[key],
        time: now.getTime()
      }
    });

    return false;
  }
};

export const setLSObjectWithExpiry = (key, value, expired_at) => {
  setLsObject(key, {
    data: value,
    expired_at
  });
};

export const getLSObjectWithExpiry = (key, now) => {
  const item = getLsObject(key);

  if (!item || item.expired_at < now) {
    localStorage.removeItem(key);
    return {
      expired: true
    };
  } else {
    return {
      expired: false,
      data: item.data
    };
  }
};

export const removeUnusedCache = () => {
  localStorage.removeItem("user-claim");
  localStorage.removeItem("user-coin");
  localStorage.removeItem("user-status");
  localStorage.removeItem("user-avatar");
  localStorage.removeItem("dataScheduling");
  localStorage.removeItem("dataRadioScheduling");
  localStorage.removeItem("is-timer-closed");
  localStorage.removeItem(`noRemindSaveProgress:${getUserId()}`);
};
