<script>
import Time from "@/api/time";
import Auth from "@/api/auth";
import User from "@/api/user";

import { removeLSItem, getLSObjectWithExpiry } from "@/utils/general";

export default {
  name: "GoogleSignIn",
  props: {
    buttonType: {
      type: String,
      default: "login"
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setFromGoogle(value) {
      this.$emit("set-from-google", value);
    },
    setUserToken(value) {
      this.$emit("set-token", value);
    },
    setUserID(value) {
      this.$emit("set-id", value);
    }
  },
  computed: {
    getButtonType() {
      return this.buttonType === "login" ? "Masuk" : "Daftar";
    }
  },
  created() {
    window.gapi.load("auth2", () => {
      window.gapi.auth2.init().then(auth2 => {
        const element = document.getElementById("google-signin-button");
        auth2.attachClickHandler(element, {}, async googleUser => {
          const id_token = googleUser.getAuthResponse().id_token;
          try {
            const res = await Auth.loginByGoogle({
              token: id_token
            }).then(res => res.data.data);

            // Handle utm creation
            const now = await Time.getTime().then(response => response.data.timestamp);
            const existedUTM = getLSObjectWithExpiry("utm", now);
            if (!existedUTM.expired) {
              await User.createUTMHistory(res.id, existedUTM.data);
              removeLSItem("utm");
            }

            const { is_new_user, token, id } = res;
            if (is_new_user) {
              // Register
              this.setUserID(id);
              this.setUserToken(token);
              this.setFromGoogle(true);
            } else {
              // Login
              this.$emit("logout");

              window.location = `${
                process.env.VUE_APP_SSO
              }/auth/${token}?redirect=${`${window.location.origin}`}&options=${JSON.stringify({
                redirect: this.$route.path,
                options: JSON.stringify(this.$route.query)
              })}`;

              this.close();
            }
          } catch (error) {
            console.error(error);
          }
        });
      });
    });
  }
};
</script>

<template>
  <div :class="$style.google">
    <button class="btn" :class="$style.google__btn" id="google-signin-button">
      <svg-icon icon-class="google-signin" :class="$style.google__logo" />
      {{ getButtonType }} dengan Google
    </button>
  </div>
</template>

<style lang="scss" module>
.google {
  display: flex;
  width: 100%;
  justify-content: center;

  &__btn {
    width: 100%;
    border: 1px solid #cdd2dc;
    box-sizing: border-box;
    border-radius: 2rem;
    font-weight: bold;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background: white;

    @include min-media(tablet) {
      font-size: 1rem;
    }
  }

  &__logo {
    height: 24px;
    width: 24px;
    margin-right: 1.5rem;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
