import service from "@/utils/request";
import { getUserId } from "@/utils/user";

export default class Referral {
  static baseGroupURL = "order/referral";

  static async createReferral(data) {
    const resp = await service.post(`${this.baseGroupURL}`, data);
    return resp;
  }

  static async getUserReferral() {
    const resp = await service.get(
      `${this.baseGroupURL}?ids_user=${getUserId()}`
    );
    return resp;
  }

  static async getReferralById(id_referral) {
    const resp = await service.get(`${this.baseGroupURL}/${id_referral}`);
    return resp;
  }

  static async getReferralByCodes(referral_code) {
    const resp = await service.get(
      `${this.baseGroupURL}?referral_codes=${referral_code}`
    );
    return resp;
  }

  static async updateReferral(id_referral, data) {
    const resp = await service.put(`${this.baseGroupURL}/${id_referral}`, data);
    return resp;
  }

  static async getAllReferralUsageById(id_referral) {
    const resp = await service.get(`${this.baseGroupURL}/usage/${id_referral}`);
    return resp;
  }
}
