<script>
// TODO: Maybe this component can be useful in the future? So Don't remove yet
import NoticeMixins from "@/mixins/NoticeMixin.js";

export default {
  name: "Notification",
  mixins: [NoticeMixins],
  computed: {
    bodyClass() {
      return [
        this.$style.body,
        this.$style[this.position],
        this.$style[this.type],
        this.show ? this.$style.show : ""
      ];
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    triggerAction() {
      this.onAction();
      this.close();
    }
  },
  watch: {
    isActive(val) {
      if (val) {
        setTimeout(() => {
          this.show = true;
        }, 200);
        if (!this.indefinite) {
          setTimeout(() => {
            this.show = false;
          }, this.duration - 200);
        }
      } else {
        this.show = false;
      }
    }
  }
};
</script>

<template>
  <div :class="bodyClass">
    <div :class="$style.message">
      <slot name="message"></slot>
      <span v-if="!$slots.message">{{ message }}</span>
    </div>
    <svg-icon icon-class="close" :class-name="$style.button" @click="close" />
  </div>
</template>

<style lang="scss" module>
.body {
  position: fixed;
  background: rgb(37, 37, 25);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  max-width: 30rem;
  z-index: 1000001; // more than crips
  transition: all 0.2s;
  opacity: 0;
  box-shadow: 0 3px 3px 3px rgba(black, 0.15);

  &.is-bottom-right {
    bottom: 0.5rem;
    right: 1rem;
  }
  &.is-bottom-left {
    bottom: 0.5rem;
    left: 1rem;
  }
  &.is-bottom {
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.is-top-right {
    top: 0.5rem;
    right: 1rem;
  }
  &.is-top-left {
    top: 0.5rem;
    left: 1rem;
  }
  &.is-top {
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.is-bottom.show,
  &.is-bottom-right.show,
  &.is-bottom-left.show {
    bottom: 1rem;
    opacity: 1;
  }

  &.is-top.show,
  &.is-top-right.show,
  &.is-top-left.show {
    top: 1rem;
    opacity: 1;
  }

  &.is-danger {
    background: $wrong;
  }

  &.is-success {
    background: $success;
  }

  @include max-media(mobile) {
    max-width: 90%;
    flex-direction: column;
  }
}

.message {
  margin-right: 1rem;
  @include max-media(mobile) {
    margin-bottom: 0.5rem;
  }
}

.button {
  border-radius: 0.25rem;
  background: rgba(white, 0.15);
  color: white;
  transition: all 0.2s;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;

  &:hover {
    background: rgba(white, 0.3);
  }
}
</style>
