import { setToken } from "@/utils/auth";
import User from "@/api/user";
const DEFAULT_DP_STATUS = ["account"];

const routes = [
  {
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "profile" */ "@/views/Profile/index.vue"),
    redirect: "/profile",
    meta: {
      requires_auth: true,
      type_navbar: "login",
      type_page: "main",
      dp_status: [...DEFAULT_DP_STATUS],
      page_name: "Akun"
    },
    children: [
      {
        path: "/",
        name: "profile-account",
        component: () =>
          import(/* webpackChunkName: "profile-account" */ "@/views/Profile/ProfileAccount"),
        meta: {
          page_name: "Akun",
          type_page: "main",
          background_color: "grey",
          hide_back_button: true,
          show_notification: true
        }
      },
      {
        path: "referral",
        name: "referral",
        component: () =>
          import(/* webpackChunkName: "choose-payment" */ "@/views/Profile/Referral"),
        meta: {
          breadcrumb: "Referral",
          page_name: "Referral"
        }
      },
      {
        path: "ubah-program-studi",
        name: "edit-major",
        component: () =>
          import(/* webpackChunkName: "choose-payment" */ "@/views/Profile/EditMajorChoice"),
        meta: {
          breadcrumb: "Ubah Pilihan Program Studi",
          page_name: "Ubah Pilihan Program Studi",
          background_color: "grey"
        }
      },
      {
        path: "ubah-profile",
        name: "edit-user",
        component: () =>
          import(/* webpackChunkName: "choose-payment" */ "@/views/Profile/EditUserDetail"),
        meta: {
          breadcrumb: "Ubah Profil",
          page_name: "Ubah Profil",
          background_color: "grey"
        }
      },
      {
        path: "pencapaian",
        name: "achievements",
        component: () =>
          import(/* webpackChunkName: "choose-payment" */ "@/views/Profile/AllAchievements"),
        meta: {
          breadcrumb: "Pencapaian",
          page_name: "Pencapaian",
          background_color: "grey"
        }
      },
      {
        path: "riwayat-koin",
        name: "coin-history",
        component: () =>
          import(/* webpackChunkName: "coin-history" */ "@/views/Profile/CoinHistory"),
        meta: {
          breadcrumb: "Riwayat Koinku",
          page_name: "Riwayat Koinku",
          background_color: "light-blue"
        }
      }
    ]
  },
  {
    path: "/lupa-password",
    redirect: "/forget-password"
  },
  {
    path: "/user/verification/:token",
    name: "user-verification",
    beforeEnter: async (to, from, next) => {
      setToken(to.params.token);
      await User.verifToken(to.params.token).then(res => res);
      next({ path: "/dashboard" });
    }
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () =>
      import(/* webpackChunkName: "forget-password" */ "@/views/Email-forget-password"),
    meta: {
      must_not_login: true,
      type_navbar: "none",
      no_loading: true,
      dp_status: [...DEFAULT_DP_STATUS]
    },
    beforeEnter: (to, from, next) => {
      if (to.query.token) {
        next({ name: "change-password", query: { token: to.query.token } });
      } else {
        next();
      }
    }
  },
  {
    path: "/setting",
    name: "setting",
    meta: {
      no_loading: true,
      background_color: "grey",
      use_breadcrumb: "show",
      page_name: "Pengaturan",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Akun",
          routeName: "profile"
        },
        {
          name: "Pengaturan",
          routeName: "setting"
        }
      ]
    },
    component: () => import(/* webpackChunkName: "setting" */ "@/views/Profile/Setting.vue")
  },
  {
    path: "/deactivate-account",
    name: "deactivate-account",
    meta: {
      no_loading: true,
      background_color: "grey",
      use_breadcrumb: "show",
      page_name: "Nonaktifkan akun",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Akun",
          routeName: "profile"
        },
        {
          name: "Pengaturan",
          routeName: "setting"
        },
        {
          name: "Nonaktifkan sementara akun",
          routeName: "deactivate-account"
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "deactivate-account" */ "@/views/DeactivateAccount")
  },
  {
    path: "/change-password",
    name: "change-password",
    meta: {
      no_loading: true,
      dp_status: [...DEFAULT_DP_STATUS],
      background_color: "grey",
      use_breadcrumb: "show",
      page_name: "Ubah Password",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Akun",
          routeName: "profile"
        },
        {
          name: "Pengaturan",
          routeName: "setting"
        },
        {
          name: "Ubah Password",
          routeName: "change-password"
        }
      ]
    },
    component: () => import(/* webpackChunkName: "change-pass" */ "@/views/ChangePassword")
  }
];

export default routes;
