import Message from "./Message";
import { mergeObj as merge } from "@/utils/helpers";
import { use, registerComponentProgrammatic, VueInstance } from "@/utils/plugins";

let localVueInstance;

const MessageProgrammatic = {
  open(params) {
    let parent;
    if (typeof params === "string") {
      params = {
        message: params
      };
    }

    const defaultParam = {
      type: "is-success",
      position: "is-top-right"
    };

    if (params.parent) {
      parent = params.parent;
      delete params.parent;
    }

    let slot;
    if (Array.isArray(params.message)) {
      slot = params.message;
      delete params.message;
    }

    const propsData = merge(defaultParam, params);
    const vm =
      typeof window !== "undefined" && window.Vue ? window.Vue : localVueInstance || VueInstance;
    const MessageComponent = vm.extend(Message);
    const component = new MessageComponent({
      parent,
      el: document.createElement("div"),
      propsData
    });

    if (slot) {
      component.$slots.default = slot;
      component.$forceUpdate();
    }

    return component;
  }
};

const Plugin = {
  install(Vue) {
    localVueInstance = Vue;
    registerComponentProgrammatic(Vue, "message", MessageProgrammatic);
  }
};

use(Plugin);

export default Plugin;

export { MessageProgrammatic, Message };
