const getInitialState = () => {
  return {
    modalShown: false,
    remainingMillis: 0,
    contentTitle: "",
    lastReminded: 0,
    reminderType: "day"
  };
};

const state = getInitialState();

const getters = {
  isModalShown: state => state.modalShown,
  getRemainingMillis: state => state.remainingMillis,
  getContentTitle: state => state.contentTitle,
  getLastReminded: state => state.lastReminded,
  getReminderType: state => state.reminderType
};

const mutations = {
  toggleModalShown(state) {
    state.modalShown = !state.modalShown;
  },
  setRemainingMillis(state, { remainingMillis }) {
    state.remainingMillis = remainingMillis;
  },
  setContentTitle(state, { contentTitle }) {
    state.contentTitle = contentTitle;
  },
  setLastReminded(state, { lastReminded }) {
    state.lastReminded = lastReminded;
  },
  setReminderType(state, { reminderType }) {
    state.reminderType = reminderType;
  },
  resetState(state) {
    Object.assign(state, getInitialState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
