<template>
  <div id="app">
    <layout>
      <router-view :key="$route.fullPath" />
    </layout>
    <loading />
  </div>
</template>

<script>
import Layout from "@/layout";
import Loading from "@/components/loading";
import { initializeFirebase } from "@/utils/push-notification";

export default {
  name: "App",
  components: {
    Layout,
    Loading
  },
  created() {
    // $crisp.push(["safe", true]); // eslint-disable-line
    initializeFirebase();
  }
};
</script>
<style lang="scss">
@import "./styles/setup/_bulma-customization.scss";
@import "./styles/setup/vendor";
@import "./styles/base/_base-element.scss";
@import "./styles/base/utils";
@import "./styles/base/animation";
@import "./styles/components/btn";
@import "./styles/components/loading";
@import "./styles/components/payment-card";
@import "./styles/components/push-notification";
@import "./styles/components/scrollbar";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $nunito;
}
// add this in V-html tagline
.clearfix {
  overflow: auto;

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .image {
    text-align: center;

    img {
      width: initial;
      margin: 0 auto;
    }

    &.image_resized {
      margin: auto;
      box-sizing: border-box;
    }

    &.image_resized img {
      width: 100%;
    }

    &.image_resized > figcaption {
      width: 100%;
      caption-side: bottom;
      word-break: break-word;
      color: #333;
      background-color: #f7f7f7;
      padding: 0.6em;
      font-size: 0.75em;
      outline-offset: -1px;
    }

    &.image-style-align-left {
      float: left;
      clear: both;
      margin-block-start: 0.875rem;
      margin-top: 0.875rem;
      margin-block-end: 1rem;
      margin-bottom: 1rem;
      margin-inline-end: 1.5rem;
      margin-right: 1.5rem;
    }

    &.image-style-align-center {
      // display: block !important;
      height: 100%;
      vertical-align: middle;
    }

    &.image-style-align-right {
      float: right;
      clear: both;
      margin-block-start: 0.875rem;
      margin-top: 0.875rem;
      margin-block-end: 1rem;
      margin-bottom: 1rem;
      margin-inline-end: 0rem;
      margin-inline-start: 1.5rem;
      margin-left: 1.5rem;
    }
  }

  figure.image {
    margin: 0;
    display: block;
  }

  figure.table {
    max-width: calc(100vw - 4rem);
    overflow: auto;
    width: fit-content;
    margin: auto;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  figure.media {
    margin: 0 auto;
    display: block;
  }
}

.ck-balloon-panel_visible {
  z-index: 10000 !important;
}

figure.image {
  &.image-inline {
    display: inline-block !important;
  }
}
</style>
