import { setLSObjectWithExpiry, getLSObjectWithExpiry } from "./general";
import store from "@/store/index";
import Game from "@/api/game";
import Prize from "@/api/prize";
import User from "@/api/user";
import Time from "@/api/time";

export const getUserClaim = async (forceUpdate = false) => {
  const now = await Time.getTime().then(response => response.data.timestamp);
  const existedClaim = getLSObjectWithExpiry("user-claim", now);

  let count = 0;
  if (existedClaim.expired || forceUpdate) {
    try {
      const res = await Game.getAllClaimableItems().then(res => res.data.data);
      count = res.total_items;
      cacheUserClaim(count);
    } catch (err) {
      console.error(err);
    }
  } else count = parseInt(existedClaim.data);

  // Add to store
  store.commit("User/setClaim", count);
  return count;
};

export const getUserStatus = async (forceUpdate = false) => {
  const now = await Time.getTime().then(response => response.data.timestamp);
  const existedUserStatus = getLSObjectWithExpiry("user-status", now);

  let userStatus = {};
  if (existedUserStatus.expired || forceUpdate) {
    try {
      userStatus = await Game.getUserStatus().then(res => res.data.data);
      cacheUserStatus(userStatus);
    } catch (err) {
      console.error(err);
    }
  } else {
    userStatus = existedUserStatus.data;
  }

  // Add to store
  store.commit("User/setUserStatus", userStatus);
  return userStatus;
};

export const getUserAvatar = async (forceUpdate = false) => {
  const now = await Time.getTime().then(response => response.data.timestamp);
  const existedUserAvatar = getLSObjectWithExpiry("user-avatar", now);

  let userAvatar = {};
  if (existedUserAvatar.expired || forceUpdate) {
    try {
      // const respAvatar = await Game.getAvatarOfUser().then(res => res.data.data);
      // Change avatar response to default avatar
      const respAvatar = {
        badge: {
          id_badge: 0,
          badge_name: "Buwhan",
          filename: "buwhan",
        },
        border: {
          id_border: 0,
          border_name: "Empty",
          filename: "empty",
        }
      };
      const respUser = await User.getUserDetails().then(res => res.data.data);

      const avatar = respAvatar.badge.filename || "buwhan";
      const border = respAvatar.border.filename || "empty";
      const title = respAvatar.badge.badge_name;
      const name = `${respUser.firstname} ${respUser.lastname}`;
      const firstname = respUser.firstname;

      userAvatar = { avatar, border, name, title, firstname };

      cacheUserAvatar(userAvatar);
    } catch (err) {
      console.error(err);
    }
  } else {
    userAvatar = existedUserAvatar.data;
  }

  // Add to store
  store.commit("User/setUserAvatar", userAvatar);
  return userAvatar;
};

export const getUserCoin = async (forceUpdate = false) => {
  const now = await Time.getTime().then(response => response.data.timestamp);
  const existedUserCoin = getLSObjectWithExpiry("user-coin", now);

  let userCoin = 0;
  if (existedUserCoin.expired || forceUpdate) {
    try {
      const respCoin = await Prize.getUserCoin().then(res => res.data.data);
      userCoin = respCoin ? respCoin.total_coin : 0;

      cacheUserCoin(userCoin);
    } catch (err) {
      console.error(err);
    }
  } else {
    userCoin = existedUserCoin.data;
  }

  // Add to store
  store.commit("User/setUserCoin", userCoin);
  return userCoin;
};

export const cacheUserStatus = async status => {
  const now = await Time.getTime().then(response => response.data.timestamp);
  const expired_at = now + 1 * 24 * 3600 * 1000;
  setLSObjectWithExpiry("user-status", status, expired_at);
};

export const cacheUserCoin = async status => {
  const now = await Time.getTime().then(response => response.data.timestamp);
  const expired_at = now + 1 * 24 * 3600 * 1000;
  setLSObjectWithExpiry("user-coin", status, expired_at);
};

export const cacheUserAvatar = async status => {
  const now = await Time.getTime().then(response => response.data.timestamp);
  const expired_at = now + 1 * 24 * 3600 * 1000;
  setLSObjectWithExpiry("user-avatar", status, expired_at);
};

export const cacheUserClaim = async claim => {
  const now = await Time.getTime().then(response => response.data.timestamp);
  const expired_at = now + 1 * 24 * 3600 * 1000;
  setLSObjectWithExpiry("user-claim", claim, expired_at);
};

export const getBadgeSource = (filename, isBW = false, annotation = "") => {
  return `https://assets.edukasystem.com/gamification/badges/${filename}${isBW ? "-bw" : ""}.png${
    annotation ? `?${annotation}` : ""
  }`;
};
export const getAvatarSource = (filename, isBW = false, annotation = "") => {
  return `https://assets.edukasystem.com/gamification/avatar/${filename}${isBW ? "-bw" : ""}.png${
    annotation ? `?${annotation}` : ""
  }`;
};
export const getBorderSource = (filename, annotation = "") => {
  return `https://assets.edukasystem.com/gamification/borders/${filename}.png${
    annotation ? `?${annotation}` : ""
  }`;
};
