import service from "@/utils/request";
import { getUserId } from "@/utils/user";

export default class Exam {
  static baseGroupMiniQuizURL = "exam/mini-quiz";
  static baseGroupQuizURL = "exam/quiz";
  static baseGroupUserURL = "exam/user";

  // USER
  static async getQuizExamByIds(ids_quiz) {
    const res = await service.get(`${this.baseGroupUserURL}/${getUserId()}/quiz`, {
      params: { ids_quiz }
    });
    return res;
  }

  // QUIZ
  static async getQuizExamId(idQuiz) {
    const res = await service.get(`${this.baseGroupQuizURL}/user/${getUserId()}/quiz/${idQuiz}`);
    return res;
  }

  static async getQuizAnswersheet(idExam) {
    const res = await service.get(`${this.baseGroupQuizURL}/${idExam}/answersheet`);
    return res;
  }

  static async startQuizExam(quizExamId) {
    const res = await service.post(`${this.baseGroupQuizURL}/${quizExamId}/start-time`);
    return res;
  }

  static async finishQuizExam(quizExamId) {
    const res = await service.post(`${this.baseGroupQuizURL}/${quizExamId}/finish-time`);
    return res;
  }

  static async createQuizExam(data) {
    const res = await service.post(`${this.baseGroupQuizURL}`, data);
    return res;
  }

  static async startQuizQuestion(quizExamId, questionId, data) {
    const res = await service.post(
      `${this.baseGroupQuizURL}/${quizExamId}/answersheet/start-time/${questionId}`,
      data
    );
    return res;
  }

  static async answerQuizQuestion(quizExamId, questionId, data) {
    const res = await service.put(
      `${this.baseGroupQuizURL}/${quizExamId}/answersheet/answer/${questionId}`,
      data
    );
    return res;
  }

  // MINIQUIZ
  static async getMiniQuizExam(options) {
    if (options["miniQuizExamId"]) {
      const res = await service.get(`${this.baseGroupMiniQuizURL}/${options["miniQuizExamId"]}`);
      return res;
    } else if (options["userId"] && options["miniQuizId"]) {
      const res = await service.get(
        `${this.baseGroupMiniQuizURL}/user/${options["userId"]}/mini-quiz/${options["miniQuizId"]}`
      );
      return res;
    }
  }

  static async startMiniQuizExam(miniQuizExamId) {
    const res = await service.post(`${this.baseGroupMiniQuizURL}/${miniQuizExamId}/start-time`);
    return res;
  }

  static async finishMiniQuizExam(miniQuizExamId) {
    const res = await service.post(`${this.baseGroupMiniQuizURL}/${miniQuizExamId}/finish-time`);
    return res;
  }

  static async createMiniQuizExam(data) {
    const res = await service.post(`${this.baseGroupMiniQuizURL}`, data);
    return res;
  }

  static async getMiniQuizAnswersheet(miniQuizExamId) {
    const res = await service.get(`${this.baseGroupMiniQuizURL}/${miniQuizExamId}/answersheet`);
    return res;
  }

  static async answerMiniQuizQuestion(miniQuizExamId, questionId, data) {
    const res = await service.put(
      `${this.baseGroupMiniQuizURL}/${miniQuizExamId}/answersheet/answer/${questionId}`,
      data
    );
    return res;
  }
}
