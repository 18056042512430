import TopicVisited from "@/api/topic-visited";
import Topic from "@/api/topic";

import store from "@/store";

const routes = [
  {
    path: "/bank-soal",
    name: "BankSoalDashboard",
    component: () => import("@/views/BankSoal/Dashboard"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      type_page: "main",
      background_color: "light-grey",
      page_name: "Bank Soal dan Rangkuman",
      hide_back_button: true,
      mobile_navbar: "purple",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Bank Soal dan Rangkuman",
          routeName: "BankSoalDashboard"
        }
      ]
    }
  },
  {
    path: "/my-bank-soal",
    name: "MyBankSoal",
    component: () => import("@/views/BankSoal/MyBankSoal"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      background_color: "light-grey",
      use_breadcrumb: "show",
      title: "Bank Soalku",
      page_name: "Bank Soalku",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Bank Soal dan Rangkuman",
          routeName: "BankSoalDashboard"
        },
        {
          name: "Bank Soalku",
          routeName: "MyBankSoal"
        }
      ]
    }
  },
  {
    path: "/bank-soal-list",
    name: "BankSoalList",
    component: () => import("@/views/BankSoal/BankSoalList"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      background_color: "light-grey",
      use_breadcrumb: "show",
      page_name: "Bank Soal",
      title: "Daftar Bank Soal dan Rangkuman",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Bank Soal dan Rangkuman",
          routeName: "BankSoalDashboard"
        },
        {
          name: "Daftar Bank Soal dan Rangkuman",
          routeName: "BankSoalList"
        }
      ]
    }
  },
  {
    path: "/bank-soal/:topicId(\\d+)/topic",
    name: "bab",
    component: () => import("@/views/ChapterConcept/index.vue"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      page_name: "Bank Soal",
      back_to: {
        name: "BankSoalDashboard"
      }
    },
    beforeEnter: async (to, from, next) => {
      //  This line we will add POST visited topik
      await TopicVisited.upsertTopicVisit(to.params.topicId);
      store.commit("State/setRouterFrom", from.name);
      next();
    }
  },
  {
    path: "/bank-soal/:topicId(\\d+)/rekap",
    name: "bank-soal-recap",
    component: () => import("@/views/ChapterConcept/RekapBankSoal.vue"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      page_name: () => "Rekap Bank Soal",
      title_params: true,
      back_to: {
        name: "bab"
      },
      breadcrumb: async route => {
        const { topic_name } = await Topic.getTopic(route.params.topicId).then(
          res => res.data.data
        );
        const result = [
          {
            name: "Beranda",
            routeName: "home"
          },
          {
            name: "Bank Soal",
            routeName: "BankSoalDashboard"
          },
          {
            name: topic_name || "Rekap Bank Soal",
            routeName: "bab",
            routeParams: {
              topicId: route.params.topicId
            }
          },
          {
            name: "Rekap Bank Soal",
            routeName: "bank-soal-recap"
          }
        ];
        return result;
      }
    }
  },
  {
    path: "/bank-soal/concept/:topicId(\\d+)/:chapterId(\\d+)/:conceptId(\\d+)",
    name: "concept",
    component: () => import("@/views/BankSoalConcept/index.vue"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      hide_page_name: true,
      hide_help: true,
      background_color: "light-grey",
      back_to: {
        name: "bab"
      }
    }
  },
  {
    path: "/quiz/:topicId(\\d+)/:chapterId(\\d+)/:quizId(\\d+)",
    name: "quiz",
    component: () => import(/* webpackChunkName: "quiz" */ "@/views/Quiz"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "hide-on-desktop-size",
      back_to: {
        name: "bab"
      },
      hide_page_name: true,
      hide_help: true
    }
  },
  {
    path: "/quiz-result/:topicId(\\d+)/:chapterId(\\d+)/:quizId(\\d+)",
    name: "quiz-result",
    props: true,
    component: () => import(/* webpackChunkName: "quiz-result" */ "@/views/Quiz/QuizResult"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      hide_navbar_phone: true
    }
  }
];

export default routes;
