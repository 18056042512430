// initial state
const getInitialState = () => {
  return {
    major: null,
    grade: null,
    semester: null
  };
};

const state = getInitialState();

// getters
const getters = {
  getMajorFilter(state) {
    return state.major;
  },
  getGradeFilter(state) {
    return state.grade;
  },
  getSemesterFilter(state) {
    return state.semester;
  },
  isMajorFilter: state => major => {
    return major.includes(state.major);
  },
  isGradeFilter: state => grade => {
    return state.grade === grade;
  },
  isSemesterFilter: state => semester => {
    return state.semester === semester;
  }
};

const mutations = {
  setMajorFilter(state, { major }) {
    state.major = major;
  },
  setGradeFilter(state, { grade }) {
    state.grade = grade;
  },
  setSemesterFilter(state, { semester }) {
    state.semester = semester;
  },
  reset(state) {
    Object.assign(state, getInitialState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
