import service from "@/utils/request";
import { getUserId } from "@/utils/user";

export default class TopicVisited {
  static baseGroupURL = "content/topic-visit";

  static async getTopicVisit() {
    const resp = await service.get(
      `${this.baseGroupURL}/user/${getUserId()}/topic`
    );
    return resp;
  }
  static async upsertTopicVisit(id_topic) {
    const resp = await service.put(
      `${this.baseGroupURL}/user/${getUserId()}/topic/${id_topic}/visited`
    );
    return resp;
  }
}
