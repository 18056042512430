<script>
import { mapGetters } from "vuex";
import { millisecondToTime } from "@/utils/datetime";

const ONE_MINUTE = 60000;

export default {
  name: "TimeScheduling",
  props: {
    statusTimer: String,
    remain: Number,
    duration: Number,
    isMusicOn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      onePart: 0,
      oneCycle: 0,
      longBreak: 0,
      oneBigCycle: 0,
      oneCycleParts: 0,
      oneBigCycleParts: 0,
      state: {
        cycleLength: 0,
        cycleNumber: 0,
        remaining: 0,
        isResting: false,
        isLongBreak: false
      },
      isFullscreen: false,
      typeTimer: ""
    };
  },
  computed: {
    ...mapGetters({
      pomodoroTimer: "State/getPomodoroTimer"
    }),
    renderText() {
      if (this.pomodoroTimer) {
        if (this.state.remaining) {
          if (this.state.isResting) {
            if (this.state.isLongBreak) {
              return "Long Break";
            } else {
              return "Break Time";
            }
          } else {
            return "Study Time";
          }
        } else {
          return "Selesai";
        }
      } else {
        return "Timer";
      }
    },
    renderTextFullscreen() {
      if (this.pomodoroTimer) {
        if (this.state.isResting) {
          if (this.state.isLongBreak) {
            return `<p>Waktunya istirahat 35 menit!</p><p>Maksimalkan istirahat kamu!</p>`;
          } else {
            return `<p>Waktunya istirahat 5 menit!</p><p>Maksimalkan istirahat kamu!</p>`;
          }
        } else {
          return `<p>Waktunya belajar 25 menit!</p>`;
        }
      } else {
        if (this.state.remaining > 60000) {
          return "<p>Waktu belajarmu sudah dimulai!</p>";
        } else {
          return "<p>Waktu belajarmu akan selesai dalam 1 menit.</p>";
        }
      }
    }
  },
  watch: {
    typeTimer(newVal, oldVal) {
      if (!this.isFullscreen && oldVal === "study") {
        if (newVal === "longbreak") {
          this.$emit("setModal", true, true);
        } else if (newVal === "resting") {
          this.$emit("setModal", true, false);
        }
      }
    },
    remain() {
      if (this.remain < 0) {
        this.isFullscreen = false;
      } else {
        if (this.pomodoroTimer) {
          this.renderStatePomodoro();
        } else {
          this.renderState();
        }
      }
    },
    isFullscreen(newVal) {
      if (this.pomodoroTimer) {
        if (newVal) {
          this.$emit("setMusic", true);
        } else {
          this.$emit("setMusic", false);
        }
      } else {
        this.$emit("setMusic", false);
      }
    },
    isMusicOn(newVal) {
      if (newVal) {
        this.$refs["audio"].play();
      } else {
        this.$refs["audio"].pause();
      }
    }
  },
  methods: {
    handleAction(action) {
      this.$emit("handle", action);
    },
    setMusic(value) {
      this.$emit("setMusic", value);
    },
    miliToHMS(remain) {
      const result = millisecondToTime(remain, false);

      let hours = result.hours < 10 ? "0" + result.hours : result.hours,
        minutes = result.minutes < 10 ? "0" + result.minutes : result.minutes,
        seconds = result.seconds < 10 ? "0" + result.seconds : result.seconds;

      return hours + ":" + minutes + ":" + seconds;
    },
    close() {
      this.$emit("close");
    },
    toScheduling() {
      if (this.$router.name !== "scheduling") {
        this.$router.push({ name: "scheduling" });
      }
      this.$emit("close");
    },
    renderClass() {
      let arrayClass = [];

      if (this.state.remaining) {
        if (this.pomodoroTimer && this.state.isResting) {
          arrayClass.push(this.$style.purple);
        } else {
          if (this.state.remaining > 300000) {
            arrayClass.push(this.$style.green);
          } else if (this.state.remaining > 60000) {
            arrayClass.push(this.$style.yellow);
          } else {
            arrayClass.push(this.$style.red);
          }
        }
      } else {
        arrayClass.push(this.$style.red);
      }

      return arrayClass;
    },
    renderStatePomodoro() {
      let difference = this.duration - this.remain;
      this.$set(this.state, "cycleLength", this.getTotalCycleNumber(this.duration));
      this.$set(this.state, "cycleNumber", this.getOnGoingCycleNumber(difference));

      let onGoingPart = this.getPartsNumber(difference);
      let part = onGoingPart % this.oneBigCycleParts;
      // Longbreak
      if (part >= 4 * this.oneCycleParts || part === 0) {
        if (!difference) {
          // Initial with Study Time
          this.$set(this.state, "isResting", false);
          this.$set(this.state, "isLongBreak", false);
          this.typeTimer = "study";

          // Calculate Remain Time
          const millis = this.oneCycle - this.onePart;
          const ongoing = (difference % this.oneBigCycle) % this.oneCycle;
          let remain = millis - ongoing;
          this.$set(this.state, "remaining", this.remain > remain ? remain : this.remain);
        } else {
          this.$set(this.state, "isResting", true);
          this.$set(this.state, "isLongBreak", true);
          this.typeTimer = "longbreak";

          // Calculate Remain time
          // 30 menit longbreak, 5 menit resting
          const millis = this.longBreak + this.onePart;
          const ongoing = (difference % this.oneBigCycle) - (4 * this.oneCycle - this.onePart);
          let remain = millis - ongoing;

          this.$set(this.state, "remaining", this.remain > remain ? remain : this.remain);
          this.$emit("setDurationModal", this.state.remaining);
        }
      } else if (part % this.oneCycleParts == 0) {
        this.$set(this.state, "isResting", true);
        this.$set(this.state, "isLongBreak", false);
        this.typeTimer = "resting";

        // Calculate Remain Time
        const millis = this.onePart;
        const ongoing =
          ((difference % this.oneBigCycle) % this.oneCycle) - (this.oneCycle - this.onePart);
        let remain = millis - ongoing;
        this.$set(this.state, "remaining", this.remain > remain ? remain : this.remain);
        this.$emit("setDurationModal", this.state.remaining);
      } else {
        this.$set(this.state, "isResting", false);
        this.$set(this.state, "isLongBreak", false);
        this.typeTimer = "study";

        // Calculate Remain Time
        const millis = this.oneCycle - this.onePart;
        const ongoing = (difference % this.oneBigCycle) % this.oneCycle;
        let remain = millis - ongoing;
        this.$set(this.state, "remaining", this.remain > remain ? remain : this.remain);
      }
    },
    renderState() {
      this.$set(this.state, "cycleLength", 0);
      this.$set(this.state, "cycleNumber", 0);
      this.$set(this.state, "remaining", this.remain);
      this.$set(this.state, "isResting", false);
      this.$set(this.state, "isLongBreak", false);
    },

    getTotalCycleNumber(duration) {
      let cycle = Math.floor(duration / this.oneBigCycle) * 4;
      let remainTime = duration % this.oneBigCycle;
      let remainCycle = Math.ceil(remainTime / this.oneCycle);
      cycle += remainCycle > 4 ? 4 : remainCycle;
      return cycle;
    },
    getOnGoingCycleNumber(duration) {
      let cycle = Math.floor(duration / this.oneBigCycle) * 4;
      let remainTime = duration % this.oneBigCycle;
      let remainCycle = Math.floor((remainTime + this.onePart) / this.oneCycle);
      cycle += remainCycle > 4 ? 4 : remainCycle;
      return cycle;
    },
    getPartsNumber(remaining) {
      return Math.ceil(remaining / this.onePart);
    }
  },
  mounted() {
    if (this.pomodoroTimer) {
      this.renderStatePomodoro();
    } else {
      this.renderState();
    }
  },
  created() {
    this.onePart = ONE_MINUTE * 5;
    this.oneCycle = this.onePart * 6;
    this.longBreak = this.oneCycle;
    this.oneBigCycle = 4 * this.oneCycle + this.longBreak;
    this.oneCycleParts = this.oneCycle / this.onePart;
    this.oneBigCycleParts = this.oneBigCycle / this.onePart;
  }
};
</script>

<template>
  <div :class="[$style.timer_container, renderClass(), isFullscreen ? $style.fullscreen : '']">
    <div>
      <audio width="100%" height="auto" loop="true" ref="audio">
        <source src="@/assets/audio/lofi.mp3" type="audio/mpeg" />
      </audio>
    </div>
    <div v-if="!isFullscreen" :class="[$style.content, renderClass()]">
      <div :class="$style.timer_container__left">
        <p :class="$style.timer_title">
          {{ renderText }} <span>{{ miliToHMS(state.remaining) }}</span>
          {{ pomodoroTimer ? `(${state.cycleNumber}/${state.cycleLength})` : "" }}
        </p>
      </div>
      <div :class="$style.timer_container__right">
        <template v-if="remain > 0">
          <div :class="$style.timer_stop" @click="handleAction('stop')">
            <svg-icon
              icon-class="stop-circle"
              class-name="icon"
              :class="[$style.icon, renderClass()]"
            />
            <p>Stop</p>
          </div>
          <div
            v-if="remain > 0 && (!pomodoroTimer || !state.isResting)"
            :class="$style.timer_action"
            @click="handleAction(statusTimer)"
          >
            <svg-icon
              :icon-class="statusTimer === 'play' ? 'pause-circle' : 'play-circle'"
              class-name="icon"
              :class="[$style.icon, renderClass()]"
            />
            <p>{{ statusTimer === "play" ? "Pause" : "Play" }}</p>
          </div>
          <div :class="$style.timer_stop" v-if="remain > 0" @click="isFullscreen = true">
            <svg-icon
              icon-class="maximize"
              class-name="icon"
              :class="[$style.icon, renderClass()]"
            />
          </div>
        </template>
        <div v-else :class="$style.timer_timeout">
          <p @click="toScheduling">Ke Study Manager</p>
          <svg-icon icon-class="close" :class="[$style.icon, $style.close]" @click="close" />
        </div>
      </div>
    </div>
    <div v-else :class="[$style.fullscreen_timer_container, renderClass()]">
      <div :class="[$style.background, renderClass()]">
        <div :class="[$style.header, pomodoroTimer ? $style.pomodoro : '']">
          <div
            :class="$style.music_container"
            @click="setMusic(isMusicOn ? false : true)"
            v-if="pomodoroTimer"
          >
            <svg-icon :icon-class="`music-${isMusicOn ? 'on' : 'off'}`" class-name="icon" />
            <p>Music: {{ isMusicOn ? "on" : "off" }}</p>
          </div>
          <svg-icon
            icon-class="minimize"
            class-name="icon"
            :class="[$style.icon]"
            @click="isFullscreen = false"
          />
        </div>
        <div :class="$style.center_container">
          <div :class="$style.text_container">
            <div :class="$style.text" v-html="renderTextFullscreen"></div>
            <p :class="$style.text_pomodoro" v-if="pomodoroTimer">
              Pomodoro ({{ state.cycleNumber }}/{{ state.cycleLength }})
            </p>
          </div>
          <div :class="$style.image_action_container">
            <div :class="$style.image_container">
              <img
                :src="`https://assets.edukasystem.com/gif/${
                  pomodoroTimer && state.isResting ? 'lofi_pomodoro' : 'gif_pomodoro_study'
                }.gif`"
              />
            </div>
            <div :class="$style.action_container">
              <p :class="[renderClass()]">{{ miliToHMS(state.remaining) }}</p>
              <div :class="$style.button_container">
                <div :class="$style.button_container__item">
                  <div
                    :class="[$style.icon_background, renderClass()]"
                    @click="handleAction('stop')"
                  >
                    <svg-icon icon-class="stop" class-name="icon" :class="[renderClass()]" />
                  </div>
                  <p>Stop</p>
                </div>
                <div :class="$style.button_container__item" v-if="!state.isResting">
                  <div
                    :class="[$style.icon_background, renderClass()]"
                    @click="handleAction(statusTimer)"
                  >
                    <svg-icon
                      :icon-class="statusTimer === 'play' ? 'pause' : 'play'"
                      class-name="icon"
                      :class="[renderClass()]"
                    />
                  </div>
                  <p>{{ statusTimer === "play" ? "Pause" : "Play" }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.timer_container {
  position: fixed;
  z-index: 4000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.green {
    background: #eeffe8;
  }
  &.yellow {
    background: #fff8d2;
  }
  &.red {
    background: #ffebe9;
  }
  &.purple {
    background: #f4f1ff;
  }

  &.fullscreen {
    z-index: 4003;
  }

  .content {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding: 0 5%;

    &.green {
      color: #185704;
    }
    &.yellow {
      color: #605004;
    }
    &.red {
      color: #7b1d12;
    }
    &.purple {
      color: #261078;
    }

    @include max-media(mobile) {
      font-size: 12px;
    }
  }

  .icon {
    cursor: pointer;
    font-size: 20px;
    margin-right: 6px;
    stroke: #676767;
    &.green {
      stroke: #185704;
    }
    &.yellow {
      stroke: #605004;
    }
    &.red {
      stroke: #7b1d12;
    }
    &.purple {
      stroke: #261078;
    }
    @include max-media(mobile) {
      font-size: 16px;
    }
  }
  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__right {
    justify-content: right;

    .timer_stop,
    .timer_action {
      display: flex;
      align-items: center;
      margin: 0.5rem;
    }

    .timer_timeout {
      display: flex;
      align-items: center;

      p {
        margin-right: 0.5rem;
        background: white;
        cursor: pointer;
        padding: 0.25rem 1rem;
        color: #7b1d12;
        border: 1px solid #7b1d12;
        border-radius: 80px;
        font-weight: 700;

        @include max-media(mobile) {
          font-size: 0.75rem;
        }
      }

      svg {
        font-size: 1.25rem;
        cursor: pointer;
        color: #7b1d12;
      }
    }
  }

  &__left {
    .timer_title {
      span {
        font-weight: 700;
      }
    }
  }
  .fullscreen_timer_container {
    position: relative;

    .background {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 1.5rem;
        margin-bottom: 0;

        &.pomodoro {
          justify-content: space-between;
        }

        .music_container {
          cursor: pointer;
          display: flex;
          gap: 0.5rem;
          align-items: center;
          color: #676767;
          border: 1px solid #cdd2dc;
          border-radius: 16px;
          padding: 0.5rem;
          p {
            font-size: 0.875rem;

            @include min-media(tablet) {
              font-size: 1rem;
            }
          }
        }
      }

      .center_container {
        margin: auto;
      }

      .text_container {
        text-align: center;
        margin: 1.5rem auto;
        .text p {
          font-size: 1rem;
          font-weight: 700;
          text-align: center;
          margin: 0;

          @include min-media(tablet) {
            font-size: 1.5rem;
          }
        }

        .text_pomodoro {
          color: #676767;
          font-size: 0.875rem;
          text-align: center;

          @include min-media(tablet) {
            font-size: 1rem;
          }
        }
      }

      .image_action_container {
        margin: 0 auto;
        width: 250px;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        @include min-media(tablet) {
          flex-direction: row;
          width: 700px;
        }
        .image_container {
          flex: 1;
          img {
            width: 250px;
            height: auto;

            @include min-media(tablet) {
              width: 350px;
            }
          }
        }

        .action_container {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            font-size: 40px;
            font-weight: 700;
            text-align: center;
            &.green {
              color: #185704;
            }
            &.yellow {
              color: #605004;
            }
            &.red {
              color: #7b1d12;
            }
            &.purple {
              color: #7250ec;
            }
          }

          .button_container {
            display: flex;
            width: 70%;
            margin: 0 auto;
            justify-content: space-around;

            @include min-media(tablet) {
              width: 50%;
            }

            &__item {
              display: flex;
              flex-direction: column;
              justify-content: center;

              p {
                font-size: 0.875rem;
                color: #9e9e9e;
                font-weight: 400;
                margin-top: 0.5rem;
              }
            }

            .icon_background {
              cursor: pointer;
              background: white;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: ease-in-out 0.25s;

              &:hover {
                &.green {
                  box-shadow: 0 0 10px rgba(#185704, 0.15);
                }
                &.yellow {
                  box-shadow: 0 0 10px rgba(#605004, 0.15);
                }
                &.red {
                  box-shadow: 0 0 10px rgba(#7b1d12, 0.15);
                }
                &.purple {
                  box-shadow: 0 0 10px rgba(#261078, 0.15);
                }
              }

              svg {
                &.green {
                  fill: #185704;
                  stroke: #185704;
                }
                &.yellow {
                  fill: #605004;
                  stroke: #605004;
                }
                &.red {
                  fill: #7b1d12;
                  stroke: #7b1d12;
                }
                &.purple {
                  fill: #261078;
                  stroke: #261078;
                }
              }
            }
          }
        }
      }

      &.green {
        background: #eeffe8;
      }
      &.yellow {
        background: #fff8d2;
      }
      &.red {
        background: #ffebe9;
      }
      &.purple {
        background: #f4f1ff;
      }
    }
  }
}
</style>
