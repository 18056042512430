import service from "@/utils/request";

export default class Calendar {
  static baseGroupURL = "game/schedule/gcal";

  static async checkUserAuth() {
    const res = service.get(`${this.baseGroupURL}`);
    return res;
  }

  static async getAuthCodeURL(google_token) {
    const res = service.get(`${this.baseGroupURL}/auth?token=${google_token}`);
    return res;
  }

  static async exchangeAuthCode(auth_code) {
    const res = service.post(`${this.baseGroupURL}/exchange?code=${auth_code}`);
    return res;
  }

  static async sync() {
    const res = service.post(`${this.baseGroupURL}/event/sync`);
    return res;
  }

  static async unsync() {
    const res = service.delete(`${this.baseGroupURL}`);
    return res;
  }
}
