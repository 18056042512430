import service from "@/utils/request";

export default class Topic {
  static baseGroupURL = "content/topic";

  static async getAllTopic(params = {}) {
    const resp = await service.get(`${this.baseGroupURL}`, { params });
    return resp;
  }

  static async getTopic(topic_id) {
    const res = await service.get(`${this.baseGroupURL}/${topic_id}`);
    return res;
  }
}
