<script>
import Auth from "@/api/auth";
import Notification from "@/api/notification";
import Referral from "@/api/referral";
import Time from "@/api/time";
import User from "@/api/user";

import { mapMutations, mapGetters } from "vuex";
import {
  // getUserClaim,
  getAvatarSource,
  getBorderSource,
  // getUserStatus,
  getUserAvatar,
  getUserCoin
} from "@/utils/game";
import { getUserId, removeUserId } from "@/utils/user";
import { removeToken, getToken } from "@/utils/auth";
import { removeUnusedCache } from "@/utils/general";

import { NOTIFICATION_FILTER } from "@/constants/notification";

import Tooltip from "@/components/Tooltip";
import ProgressBar from "@/components/ProgressBar";
import NotificationTab from "./NotificationTab";

const NOTIFICATION_LIMIT = 4;

export default {
  name: "Navbar",
  components: {
    Tooltip,
    ProgressBar,
    NotificationTab
  },
  data() {
    return {
      timestamp: 0,
      active: false,
      showNavbar: true,
      organization : "",

      // Notification Data
      categoryList: NOTIFICATION_FILTER
    };
  },
  props: {
    width: Number,
    showPromo: {
      type: Boolean,
      default: false
    },
    showNotification: {
      type: Boolean,
      default: false
    },
    hideBackButton: Boolean,
    mobileNavbar: String,
    hideNavbarPhone: {
      type: Boolean,
      default: false
    },
    hideHelp: {
      type: Boolean,
      default: false
    },
    hidePageName: {
      type: Boolean,
      default: false
    },
    hideBurgerButton: {
      type: Boolean,
      default: false
    },
    loginStatus: Boolean,
    hideCollapsibleItems: {
      type: Boolean,
      default: false
    },
    centeredItems: {
      type: Boolean,
      default: false
    },
    pageName: {
      type: String,
      default: "Buwhan Edu"
    },
    isSecondary: Boolean,
    isSticky: Boolean,
    isTrial: Boolean
  },
  computed: {
    ...mapGetters({
      userStatus: "User/getUserStatus",
      userAvatar: "User/getUserAvatar",
      userClaim: "User/getUserClaim",
      userCoin: "User/getUserCoin",
      hasUnreadNotification: "Notification/getHasUnreadNotification",
      notificationList: "Notification/getNotificationList",
      internalInfoList: "Notification/getInternalInfoList",
      userReferral: "Referral/getUserReferral"
    }),
    isHome() {
      return this.$route.name === "home";
    },
    secondaryNavbar() {
      return this.isSecondary ? "purple" : "";
    },
    generatedMenuItem() {
      if (this.loginStatus) {
        if (this.organization === "") {
          return [
            {
              name: "Beranda",
              link: "/dashboard"
            },
            {
              name: "Bank Soal",
              link: "/bank-soal"
            },
            {
              name: "Tryout",
              link: "/event/tryout"
            },
            {
              name: "Pembelian",
              link: "/eduka-pay"
            }
          ];
        } else {
          return [
            {
              name: "Test",
              link: "/event/tryout"
            }
          ];
        }
      }
      return [];
    },
    userItems() {
      if (this.organization === "") {
        return [
          // {
          //   id: "level",
          //   name: `Lv. ${this.userStatus.level}`,
          //   tooltip: true
          // },
          // {
          //   id: "streak",
          //   name: `${this.userStatus.day_streak}`,
          //   usingIcon: true,
          //   icon: "fire",
          //   tooltip: true
          // },
          // {
          //   id: "achievement",
          //   name: `${this.userStatus.total_badges}`,
          //   usingIcon: true,
          //   icon: "crown",
          //   tooltip: true
          // },
          {
            id: "coin",
            name: `${this.userCoin}`,
            usingIcon: true,
            icon: "coin",
            tooltip: true
          },
          // {
          //   id: "notification",
          //   usingIcon: true,
          //   icon: "bell",
          //   tooltip: true,
          //   useBorder: true
          // },
          // {
          //   id: "claim",
          //   name: `${this.userClaim}`,
          //   usingIcon: true,
          //   icon: "gift",
          //   tooltip: true,
          //   useBorder: true
          // },
          {
            name: "Akun",
            active: false,
            hideName: true,
            usingIcon: true,
            icon: `${this.userAvatar.avatar}`,
            children: [
              {
                name: "Akun",
                link: "/profile"
              },
              // {
              //   name: "Kode Referral:",
              //   onClick: this.navigateToReferral,
              //   subText: this.userReferral,
              //   icon: "download",
              //   onIconClick: this.downloadReferral
              // },
              {
                name: "Pengaturan",
                link: "/setting"
              },
              {
                name: "Keluar",
                onClick: this.logout
              }
            ]
          }
        ];
      } else {
        return [
          {
            name: "Akun",
            active: false,
            hideName: true,
            usingIcon: true,
            icon: `${this.userAvatar.avatar}`,
            children: [
              {
                name: "Akun",
                link: "/profile"
              },
              {
                name: "Pengaturan",
                link: "/setting"
              },
              {
                name: "Keluar",
                onClick: this.logout
              }
            ]
          }
        ];
      }
    },
    displayedNotificationList() {
      return this.notificationList.slice(0, NOTIFICATION_LIMIT);
    },
    displayedInternalInfoList() {
      return this.internalInfoList.slice(0, NOTIFICATION_LIMIT);
    },
    notificationUnreadCount() {
      let result = 0;
      if (this.notificationList.length > 0) {
        result = this.notificationList.reduce((acc, curr) => {
          if (curr.is_read) return acc;
          else return acc + 1;
        }, 0);
      }
      return result;
    },
    internalInfoUnreadCount() {
      let result = 0;
      if (this.internalInfoList.length > 0) {
        result = this.internalInfoList.reduce((acc, curr) => {
          if (curr.is_read) return acc;
          else return acc + 1;
        }, 0);
      }
      return result;
    }
  },
  async created() {
    this.timestamp = await Time.getTime().then(res => res.data.timestamp);
    const userDetails = await User.getUserDetails().then(res => res.data.data);
    this.organization = userDetails.organization.split(',')[0];

    // Setup VUEX data
    this.fetchGamificationData();
    // await this.fetchNotificationData();
    await this.getUserReferral();
  },
  mounted() {
    // Special watcher for 2 computed value
    this.$watch(
      vm => [vm.notificationUnreadCount, vm.internalInfoUnreadCount],
      val => {
        const existUnread = val.find(el => el > 0);
        if (!existUnread) this.setHasUnreadNotification(false);
        else {
          this.setHasUnreadNotification(true);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    ...mapMutations({
      setLogoutConfirmation: "State/setLogoutConfirmation",
      setHasUnreadNotification: "Notification/setHasUnreadNotification",
      setInternalInfoList: "Notification/setInternalInfoList",
      setNotificationList: "Notification/setNotificationList",
      setUserReferral: "Referral/setUserReferral",
      setReferralDownloadStatus: "Referral/setReferralDownloadStatus"
    }),
    getAvatarSource,
    getBorderSource,
    logout() {
      this.setLogoutConfirmation(true);
    },
    downloadReferral() {
      this.setReferralDownloadStatus(true);
    },
    navigateToReferral() {
      this.$router.push({ name: "referral" });
    },
    backButtonAction() {
      const { meta, params, query } = this.$route;
      if (meta.back_to) {
        this.$router.push({ ...meta.back_to, params, query });
      } else {
        this.$router.go(-1);
      }
    },
    tooltipNav(id_item) {
      if (id_item === "claim") this.$router.push({ name: "claim-prize" });
      else if (id_item === "notification") this.$router.push({ name: "notification" });
    },
    showHelp() {
      // $crisp.push(["do", "chat:open"]); // eslint-disable-line
      window.open("https://wa.me/+6281322367118");
    },
    async fetchGamificationData() {
      await getUserCoin();
      // await getUserStatus();
      // await getUserClaim();
      await getUserAvatar();
    },
    async fetchCategory() {
      const res = await Notification.getNotificationCategory().then(res => res.data.data);
      this.categoryList = this.categoryList.map(el => {
        const find = res.find(item => item.name === el.name);
        const result = el;
        if (find) result.id_category = find.id_category;
        else result.id_category = 0;
        return result;
      });
    },
    async fetchNotificationData() {
      await this.fetchCategory();

      // GET ALL NOTIFICATION to get all unread information (not just some info)
      const res = await Notification.getUserNotification({
        sortBy: "created_at",
        order: "desc"
      }).then(res => res.data.data.data);

      // Update data globally with vuex
      this.setNotificationList(res.filter(el => !el.is_info));
      this.setInternalInfoList(res.filter(el => el.is_info));
    },
    async getUserReferral() {
      const userId = getUserId();
      let res = await Referral.getUserReferral().then(res => res.data.data);

      if (res.data.length === 0) {
        //Create New Referral
        const respReferral = await Referral.createReferral({ id_user: userId });
        let id_referral = respReferral.data.data.id_referral;
        res = await Referral.getReferralById(id_referral);
        this.setUserReferral(res.data.data.referral_code);
      } else {
        this.setUserReferral(res.data[0].referral_code);
      }
    },

    async goToSSO() {
      const token = getToken();
      this.$honeybadger?.resetContext();
      await Auth.blacklistToken(token);
      removeToken();
      removeUserId();
      removeUnusedCache();
      window.location = `${
        process.env.VUE_APP_SSO
      }/auth/logout?redirect=${`${process.env.VUE_APP_SSO}/auth/login`}`;
    }
  },
  watch: {
    $route: {
      handler() {
        this.active = false;
      },
      immediate: true
    }
  }
};
</script>

<template>
  <transition name="fade">
    <nav
      v-if="showNavbar"
      class="navbar"
      :class="[
        secondaryNavbar,
        width < 600 && hideNavbarPhone ? 'hidden' : '',
        width < 600 && mobileNavbar === 'purple' ? 'purple' : ''
      ]"
    >
      <div class="navbar__phone" :class="{ purple: isSecondary }">
        <p class="navbar__phone__left" :class="{ 'navbar__left--small': pageName.length >= 22 }">
          <svg-icon
            v-if="!hideBackButton"
            class-name="back-button"
            icon-class="arrow-left"
            @click="backButtonAction"
          />
          {{
            hidePageName
              ? ""
              : organization !== "" && $route.name === "tryout-list"
                ? `Test: ${organization}`
                : pageName
          }}
        </p>
        <div class="navbar__phone__right">
          <div
            v-if="showNotification"
            @click="$router.push({ name: 'notification' })"
            class="notification-indicator"
          >
            <div v-if="hasUnreadNotification" class="notification-indicator__dot"></div>
            <svg-icon
              icon-class="bell-custom"
              :class="[
                'notification-indicator__icon',
                mobileNavbar === 'purple' ? 'white' : 'purple'
              ]"
            />
          </div>
          <p v-if="!hideHelp" class="help-text" @click="showHelp">
            <svg-icon icon-class="help" class="help-text__icon" />
            Bantuan
          </p>
        </div>
      </div>

      <figure class="navbar__logo">
        <img
          v-if="organization === ''"
          @click="$router.push({ path: loginStatus ? '/dashboard' : '/' })"
          src="https://assets.edukasystem.com/logo/buwhanedu-logo@2x.png"
          class="navbar__logo__image"
          alt="buwhanedu-logo"
        />
        <img
          v-else
          @click="$router.push({ path: loginStatus ? '/event/tryout' : '/' })"
          :src="`https://assets.edukasystem.com/logo/${organization}-logo@2x.png`"
          class="navbar__logo__image"
          :alt="`${organization}-logo`"
        />
      </figure>

      <ul class="navbar__items" :class="{ center: centeredItems }">
        <div class="navbar__items__left">
          <li v-for="(item, idx) in generatedMenuItem" :key="idx" class="menu_navigation">
            <router-link class="menu_navigation__link" :to="item.link">
              {{ item.name }}
              <a
                v-if="item.name === 'Pembelian' && showPromo && !isTrial"
                class="menu_navigation__link__promo_label"
                :href="item.link"
              >
                PROMO
              </a>
            </router-link>
          </li>
        </div>

        <div v-if="!isTrial" class="navbar__items__right">
          <div
            :class="['user_item', item.id === 'claim' ? 'claim' : '']"
            v-for="(item, index) in userItems"
            :key="'user-' + index"
          >
            <template v-if="item.children && item.children.length > 0">
              <div class="user_item__collapsible">
                <!-- Profile picture handling -->
                <template v-if="item.usingIcon">
                  <img
                    alt="avatar"
                    class="user_item__avatar"
                    v-if="item.name === 'Akun' && userAvatar.avatar"
                    :src="getAvatarSource(userAvatar.avatar)"
                  />
                  <img
                    v-if="userAvatar.border && userAvatar.border !== 'empty'"
                    class="user_item__border"
                    :src="getBorderSource(userAvatar.border)"
                    alt="border"
                  />
                </template>
                <!-- End of Profile picture handling -->

                {{ !item.hideName ? item.name : "" }}
                <span class="arrow" v-if="!item.hideArrow">&#9660;</span>
                <div class="dropdown">
                  <template v-for="(c, i) in item.children">
                    <div
                      v-if="c.onClick"
                      :key="i"
                      class="dropdown__link dropdown__item"
                      @click="c.onClick"
                    >
                      <div class="dropdown__link__left">
                        <p class="text">
                          {{ !c.hideName ? c.name : "" }}
                        </p>
                        <p v-if="c.subText" class="highlight">&nbsp;{{ c.subText }}</p>
                      </div>
                      <svg-icon
                        v-if="c.icon"
                        @click.stop="c.onIconClick"
                        :icon-class="c.icon"
                        class="dropdown__link__right"
                      />
                    </div>
                    <router-link v-else :key="i" class="dropdown__link dropdown__item" :to="c.link">
                      {{ !c.hideName ? c.name : "" }}
                    </router-link>
                  </template>
                </div>
              </div>
            </template>

            <template v-else>
              <tooltip
                v-if="item.tooltip"
                :placement="
                  item.id === 'coin' || item.id === 'claim' || item.id === 'notification'
                    ? 'bottom_end'
                    : 'bottom'
                "
                class="user_item__tooltip"
              >
                <div class="user_item__tooltip__content">
                  <template v-if="item.usingIcon">
                    <div :class="[item.useBorder ? 'border' : '']" @click="tooltipNav(item.id)">
                      <div class="relative">
                        <svg-icon
                          :icon-class="item.icon"
                          class-name="icon"
                          :class="[
                            userClaim && item.id === 'claim' ? 'animate' : '',
                            item.id === 'coin' || item.id === 'notification' ? 'small' : ''
                          ]"
                        />
                        <div v-if="userClaim && item.id === 'claim'" class="red-dot"></div>
                        <div
                          v-if="hasUnreadNotification && item.id === 'notification'"
                          class="red-dot"
                        ></div>
                      </div>
                    </div>
                  </template>
                  <template v-if="item.name && item.id !== 'claim'">
                    <p class="text">{{ item.name }}</p>
                  </template>
                </div>
                <template #hover>
                  <div class="user_item__tooltip__hover" v-if="item.id === 'level'">
                    <div class="right">
                      <p class="title">Level {{ userStatus.level }}</p>
                      <div class="progress_bar" v-if="userStatus.total_exp >= 0">
                        <progress-bar
                          :percent="
                            (userStatus.total_exp / userStatus.exp_level[userStatus.level + 1]) *
                            100
                          "
                        />
                        <div class="progress_bar_desc">
                          <p class="progress_bar_desc__poin">
                            {{ userStatus.exp_level[userStatus.level + 1] - userStatus.total_exp }}
                            poin menuju level {{ userStatus.level + 1 }}
                          </p>
                          <p class="progress_bar_desc__xp">
                            {{ userStatus.total_exp }}/{{
                              userStatus.exp_level[userStatus.level + 1]
                            }}
                            XP
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="user_item__tooltip__hover" v-else-if="item.id === 'streak'">
                    <div class="left">
                      <svg-icon v-if="item.icon" :icon-class="item.icon" />
                      <p>{{ userStatus.day_streak }} hari</p>
                    </div>
                    <div class="right">
                      <p class="title">Konsistensi</p>
                      <p class="description">
                        Kerjakan tryout, quiz, atau mini quiz untuk mempertahankan konsistensi kamu!
                      </p>
                    </div>
                  </div>
                  <div class="user_item__tooltip__hover" v-if="item.id === 'achievement'">
                    <div class="left">
                      <svg-icon v-if="item.icon" :icon-class="item.icon" />
                      <p>{{ userStatus.total_badges }}</p>
                    </div>
                    <div class="right">
                      <p class="title">Pencapaian</p>
                      <p class="description">
                        Kerjakan tantangan dari Buwhan untuk mendapatkan avatar pencapaian!
                      </p>
                      <router-link :to="{ name: 'achievements' }">
                        Lihat daftar pencapaian
                      </router-link>
                    </div>
                  </div>
                  <div class="user_item__tooltip__hover" v-if="item.id === 'coin'">
                    <div class="left">
                      <svg-icon v-if="item.icon" :icon-class="item.icon" />
                      <p>{{ userCoin }}</p>
                    </div>
                    <div class="right">
                      <p class="title">Koinku</p>
                      <p class="description">
                        Dapatkan koin dengan mengerjakan quiz dan mini quiz. Gunakan koinmu di
                        pembelian atau tukar dengan merchandise!
                      </p>
                    </div>
                  </div>
                  <div class="user_item__tooltip__hover notif" v-if="item.id === 'notification'">
                    <div class="right">
                      <NotificationTab
                        :category-list="categoryList"
                        :notification-unread-count="notificationUnreadCount"
                        :internal-info-unread-count="internalInfoUnreadCount"
                        :timestamp="timestamp"
                        :notification-list="displayedNotificationList"
                        :internal-info-list="displayedInternalInfoList"
                      />
                    </div>
                  </div>
                  <div class="user_item__tooltip__hover" v-if="item.id === 'claim'">
                    <div class="left">
                      <div :style="{ position: 'relative' }">
                        <svg-icon v-if="item.icon" :icon-class="item.icon" />
                        <div v-if="userClaim && item.id === 'claim'" class="red-dot"></div>
                      </div>
                      <p>{{ userClaim }}</p>
                    </div>
                    <div class="right">
                      <p class="title">Klaim Hadiah</p>
                      <p class="description">
                        Ambil koin, border, ataupun XP di sini untuk meningkatkan levelmu!
                      </p>
                      <router-link :to="{ name: 'claim-prize' }">Klaim hadiah sekarang</router-link>
                    </div>
                  </div>
                </template>
              </tooltip>
            </template>
          </div>
        </div>

        <div v-else class="navbar__items__auth">
          <a class="login" @click="goToSSO">Masuk</a>
          <button class="btn btn-secondary register" @click="goToSSO">Daftar</button>
        </div>
      </ul>
    </nav>
  </transition>
</template>

<style lang="scss" scoped>
$text-color: black;
$bg-color: white;
$accent-color: white;
$primary-color: $purple;

.navbar {
  position: fixed;
  background-color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 2.5%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  color: $text-color;
  z-index: 4003;
  width: 100vw;
  transition: all 0.2s;
  min-height: 3.25rem;

  &.hidden {
    visibility: hidden;
  }

  @include respond(phone) {
    padding: 4px 2px;

    background-size: cover;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    &.purple {
      background: #7250ec;
      box-shadow: none;

      .navbar__phone {
        color: white;
      }

      .help-text {
        color: white;
      }
    }
  }

  &__phone {
    position: relative;
    font-weight: bold;
    color: $purple;
    padding: 1rem;
    display: none;
    width: 100%;

    @include respond(phone) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.purple {
      color: $purple;
    }

    &__left {
      display: flex;
      align-items: center;

      &--small {
        font-size: 0.75rem;
      }

      .back-button {
        margin-right: 1rem !important;
        font-size: 1.3rem;
        cursor: pointer;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .notification-indicator {
        position: relative;
        cursor: pointer;

        &__dot {
          position: absolute;
          background: #fa3e3e;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;

          top: 0;
          right: 0;
        }

        &__icon {
          font-size: 1.25rem;
          display: block;

          &.purple {
            stroke: #7250ec;
          }
          &.white {
            stroke: white;
          }
        }
      }

      .help-text {
        font-size: 0.875rem;
        font-weight: bold;
        color: $purple;
        cursor: pointer;

        display: flex;
        align-items: center;
        gap: 0.25rem;
        &__icon {
          font-size: 1.5rem;
          color: $purple;
        }
      }
    }
  }

  &__logo {
    margin: 0;
    cursor: pointer;
    @include respond(tab-port) {
      margin-left: 0;
      text-align: center;
    }
    &__image {
      width: 5rem;

      @include max-media(tablet) {
        width: 4rem;
      }

      @include respond(tab-port) {
        margin-top: 8px;
      }
    }

    @include respond(phone) {
      display: none;
    }
  }

  &__items {
    display: flex;
    flex: 1 0;
    align-items: center;
    height: 100%;
    margin: 0;
    margin-left: 2rem;
    justify-content: space-between;

    @include max-media(desktop) {
      margin-left: 1rem;
    }

    @include respond(phone) {
      display: none;
    }

    &.center {
      justify-content: center;
    }

    &__left {
      display: flex;

      .menu_navigation {
        cursor: pointer;
        margin-right: 2rem;
        font-weight: bold;

        display: flex;
        align-items: center;
        justify-content: center;

        @include max-media(desktop) {
          margin-right: 1rem;
        }

        @include max-media(tablet) {
          margin-right: 0.75rem;
          font-size: 0.75rem;
        }

        &__link {
          color: black;
          transition: 0.2s color;
          text-decoration: none;
          color: inherit;

          &:hover {
            color: $primary-color;
          }

          &.router-link-active {
            color: $primary-color;
          }

          &__promo_label {
            background: #d50b00;
            border-radius: 4px;
            font-weight: bold;

            line-height: 13.64px;
            color: white;
            font-size: 12px;
            margin-left: 0.5rem;
            padding: 4px;
            @include max-media(small-tablet) {
              font-size: 0.5rem;
              margin-left: 0.25rem;
              padding: 2px;
            }
          }
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      @include min-media(desktop) {
        gap: 1rem;
      }

      @media (min-width: 1440px) {
        gap: 1.5rem;
        padding-right: 1rem;
      }

      .user_item {
        display: flex;
        align-items: center;
        justify-content: center;

        &__avatar {
          width: 32px;

          @include min-media(desktop) {
            width: 40px;
          }
        }

        &__border {
          position: absolute;
          left: 0;
          width: 32px;
          bottom: 0;

          @include min-media(desktop) {
            width: 40px;
          }
        }

        &__collapsible {
          cursor: pointer;
          position: relative;

          display: flex;
          align-items: center;
          gap: 0.5rem;

          &:hover .dropdown {
            visibility: visible;
            z-index: 2000;
            left: -50%;
            opacity: 1;
            transition: all 0.2s;
          }

          .arrow {
            font-size: 0.75rem;
            color: #676767;
          }

          .dropdown {
            background: lighten($bg-color, 10);
            position: absolute;
            top: 100%;
            left: 30%;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            flex-direction: column;
            z-index: -1;
            visibility: hidden;
            box-shadow: 0 2px 5px rgba(black, 0.2);
            border-radius: 1rem;
            opacity: 0;
            &__item {
              padding: 0.5rem 1rem;
              width: 100%;
              white-space: nowrap;
              &:hover {
                color: $primary-color;
                background: $lightest-purple;
                font-weight: bold;
                &:first-child {
                  border-top-left-radius: 1rem;
                  border-top-right-radius: 1rem;
                }
                &:last-child {
                  border-bottom-left-radius: 1rem;
                  border-bottom-right-radius: 1rem;
                }
              }
            }

            &__link {
              color: black;
              transition: 0.2s color;
              text-decoration: none;
              color: inherit;

              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.625rem;

              &:hover {
                color: $primary-color;
              }
              &.router-link-active {
                color: $primary-color;
              }

              &__left {
                display: flex;
                align-items: center;

                .highlight {
                  font-weight: bold;
                  max-width: 100px;
                  width: 100%;

                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              &__right {
                font-size: 1.25rem;
                stroke: $primary-color;
              }
            }
          }
        }

        &__tooltip {
          &__content {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            gap: 0.25rem;

            @include min-media(desktop) {
              gap: 0.5rem;
            }

            .border {
              padding: 0.25rem 0.5rem;

              @include min-media(desktop) {
                padding: 0.375rem 0.75rem;
              }
              border: 1px solid #cdd2dc;
              border-radius: 8px;
            }
            .relative {
              position: relative;
            }

            .icon {
              font-size: 20px;

              @include min-media(desktop) {
                font-size: 26px;
              }

              &.small {
                font-size: 16px;

                @include min-media(desktop) {
                  font-size: 22px;
                }
              }

              &.animate {
                animation: shake 1s ease-out infinite;
              }
            }

            .red-dot {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #fa3e3e;

              position: absolute;
              top: -1px;
              right: -1px;
            }

            .yellow-dot {
              position: absolute;
              background: #f1ad21;
              width: 6px;
              height: 6px;
              border-radius: 50%;

              top: 0;
              right: 0;
            }

            .text {
              font-size: 0.75rem;
              font-weight: bold;
              color: #676767;

              @include min-media(desktop) {
                font-size: 1rem;
              }
            }
          }

          &__hover {
            width: 450px;
            display: flex;
            padding: 20px;

            @include max-media(desktop) {
              width: 300px;
            }

            @include max-media(tablet) {
              width: 250px;
            }

            &.notif {
              width: 460px;
              padding: 1rem;
            }

            .left {
              flex: 1;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-right: 20px;

              svg {
                font-size: 2rem;
                margin-bottom: 10px;

                @include min-media(desktop) {
                  font-size: 2.5rem;
                }
              }

              p {
                font-weight: 700;
                color: #676767;
                font-size: 0.75rem;

                @include min-media(desktop) {
                  font-size: 1rem;
                }
              }
            }

            .right {
              flex: 3;

              .title {
                margin: 0;
                font-size: 1rem;
                @include min-media(desktop) {
                  font-size: 1.25rem;
                }
              }

              p {
                font-size: 0.75rem;
                margin: 8px 0;

                @include min-media(desktop) {
                  font-size: 1rem;
                }
              }

              a {
                font-weight: 700;
                font-size: 0.75rem;
                @include min-media(desktop) {
                  font-size: 1rem;
                }
              }

              .progress_bar {
                margin-top: 16px;
                &_desc {
                  display: flex;

                  &__poin {
                    flex: 1;
                    font-size: 0.75rem;
                    @include min-media(desktop) {
                      font-size: 0.875rem;
                    }
                  }

                  &__xp {
                    flex: 1;
                    font-size: 0.75rem;
                    color: #676767;
                    text-align: right;
                    @include min-media(desktop) {
                      font-size: 0.875rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &__auth {
      display: flex;
      align-items: center;
      gap: 1rem;

      .login {
        color: $purple;
        font-size: 0.75rem;
        font-weight: bold;

        @include min-media(tablet) {
          font-size: 1rem;
        }
      }

      .register {
        border-radius: 0.75rem;
        font-size: 0.75rem;
        padding: 0.25rem 0.75rem;
        font-weight: bold;

        @include min-media(tablet) {
          font-size: 1rem;
        }
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: rotateZ(-10deg);
  }

  15% {
    transform: rotateZ(10deg);
  }

  30% {
    transform: rotateZ(-10deg);
  }
  40% {
    transform: translateY(-8px);
  }
  80% {
    transform: translateY(0);
  }
}
</style>
