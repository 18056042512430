<script>
/**
 * TODO:
 * [] 31/03/2021 : get state from parent component (Field especially)
 */
export default {
  name: 'CustomAutoComplete',
  props: {
    value: null,
    type: {
      type: String,
      default: 'text'
    },
    state: {
      type: String,
      validator: (value) => {
        return ['first', 'danger', 'success'].indexOf(value) !== -1
      },
      default: 'first'
    },
    data: {
      type: Array,
      default: ()=>[]
    },
    selectedField: String,
    selectedKey: String,
    placeholder: String,
    loading: Boolean,
    disableInfiniteScroll: Boolean
  },
  computed: {
    bodyClass() {
      const { danger, body, success } = this.$style
      switch(this.state) {
        case 'danger': return [body, danger]
        case 'success': return [body, success]
        case 'first': 
        default:
          return body
      }
    },
    computedIndex() {
      return this.data.length - this.focusedItem;
    }
  },
  data() {
    return {
      active: false,
      focusedItem: -1,
      observer: null,
      curObserved: null
    }
  },
  mounted() {
    this.initObserver()
  },
  updated() {
    this.setObserved()
  },
  methods: {
    initObserver() {
      const options = {
        root: this.$refs.options,
        // rootMargin: '0px',
        // threshold: 1
      }
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(e => {
          if (e.intersectionRatio > 0 && !this.disableInfiniteScroll) {
            this.observer.unobserve(e.target)
            this.emitOnScroll()
          }
        }, options)
      })

    },
    setObserved() {
      const len = this.data.length
      if (len > 0) {
        this.curObserved = this.$refs['option-item'][len-1]
        this.observer.observe(this.curObserved)
      }
    },
    emitOnScroll() {
      this.$emit('infinite-scroll')
    },
    toggleActive(value) {
      this.active = value;
    },
    selectValue(value) {
      this.$emit('select', value)
      this.$emit('input', value[this.selectedField])
      this.toggleActive(false)
    },
    typeSearch(e) {
      this.toggleActive(true)
      this.$emit('input', e.target.value)
      this.$emit('type', e.target.value)
    },
    doKeyAction(e) {
      if(this.focusedItem === -1) {
        this.focusedItem = 0;
        return;
      }
      switch(e.key) {
        case 'ArrowDown':
          if(!this.active) this.toggleActive(true)
          if(this.focusedItem < this.data.length) this.focusedItem++
          this.$refs.options.children[this.focusedItem].focus();
          break;
        case 'ArrowUp':
          if(!this.active) this.toggleActive(true)
          if(this.focusedItem > 0) this.focusedItem--
          this.$refs.options.children[this.focusedItem].focus();
          break;
        case 'Enter':
          this.selectValue(this.data[this.focusedItem])
          break;
      }
    }
  }
}
</script>

<template>
  <div 
    :class="bodyClass" 
    @keydown="doKeyAction"
  >
    <svg-icon v-show="loading" icon-class="circle-notch-2" :class-name="$style.loading" />
    <input 
      :value="value"
      :type="type"
      :placeholder="placeholder"
      :class="$style.input"
      @input="typeSearch"
    />
    <ul :class="[$style.options, active ? $style.optionActive: '']" ref="options">
      <li 
        v-for="(option, index) in data" 
        :key="selectedKey ? ('item-'+ index +'-'+option[selectedKey]) : index"
        :tabindex="-1"
        :class="index === focusedItem ? $style.liActive: ''"
        @click="selectValue(option)"
        ref="option-item"
      >
        {{ selectedField ? option[selectedField] : option }}
      </li>
      <li v-if="loading">
        <slot name="loading"></slot>
      </li>
      <li v-else-if="!data || data.length === 0">
        <slot name="empty"></slot>
      </li>
      <li v-else ref="lastItem">&nbsp;</li>
    </ul>
  </div>
</template>

<style module lang="scss">
@keyframes showUp {
  0% {
    display: block;
    max-height: 0;
  }

  100% {
    display: block;
    max-height: 10rem;
  }
}

@keyframes rotate {
  0% {
    transform: translateY(-50%) rotateZ(0);
  }
  100% {
    transform: translateY(-50%) rotateZ(360deg);
  }
}

.body {
  position: relative;
}

.input {
  width: 100%;
  padding: .5rem;
  font-size: 1rem;
  border-radius: .25rem;
  border: 1px solid rgba(0, 0, 0, .2);
  box-shadow: inset 0 0.1em 0.25em rgba(56, 40, 40, 0.15);
  transition: all .2s ease;
  &:focus {
    outline: none;
    box-shadow: inset 0 0.1em 0.25em rgba($orange, .25),  0 0 0.25em rgba($orange, 1);
  }
}

.options {
  display: none;
  max-height: 10rem;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 10;
  background: white;
  box-shadow: 0 3px 3px rgba(black, .15);
  border: 1px solid rgba(black, .15);
  padding: .25rem 0;
  border-radius: 0 0 .25rem .25rem;

  > li {
    padding: .25rem .5rem;
    font-size: .9rem;
    transition: all .2s;

    &:hover {
      background: rgba(grey, .15);
      cursor: pointer;
    }

    &.liActive {
      background: rgba(grey, .15);
      cursor: pointer;
    }
  }

  &.optionActive {
    display: block;
    animation-name: showUp;
    animation-fill-mode: forwards;
    animation-duration: .2s;
    animation-timing-function: ease;
  }
}

.loading {
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  animation: rotate 1s linear infinite;
  color: $orange;
}

.success {
  .input {
    border: 1px solid $correct;
    &:focus {
      box-shadow: inset 0 0.1em 0.25em rgba(0, 0, 0, .15),  0 0 0.25em rgba($correct, 1);
    }
  }
}

.danger {
  .input {
    border: 1px solid #f14668;
    &:focus {
      box-shadow: inset 0 0.1em 0.25em rgba(0, 0, 0, .15),  0 0 0.25em rgba(#f14668, 1);
    }
  }
}
</style>
