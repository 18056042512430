// Notification ONLY fetched globally in Navbar
const getDefaultState = () => {
  return {
    notificationList: [],
    internalInfoList: [],
    hasUnreadNotification: false
  };
};

const state = getDefaultState();

const getters = {
  getNotificationList: state => state.notificationList,
  getInternalInfoList: state => state.internalInfoList,
  getHasUnreadNotification: state => state.hasUnreadNotification
};

const mutations = {
  setNotificationList(state, payload) {
    state.notificationList = [...payload];
  },
  setInternalInfoList(state, payload) {
    state.internalInfoList = [...payload];
  },
  setHasUnreadNotification(state, payload) {
    state.hasUnreadNotification = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
