export const NOTIFICATION_MENU = [
  { label: "Notifikasiku", value: "notification" },
  { label: "Info dari Buwhan", value: "internal-info" }
];

export const NOTIFICATION_FILTER = [
  { label: "Semua", name: "all" },
  { label: "Proses belajar", name: "Progres belajar" },
  { label: "Jadwal", name: "Jadwal" },
  { label: "Pembelian", name: "Pembelian" },
  { label: "Promo", name: "Promo" },
  { label: "Lainnya", name: "Lainnya" }
];

export const NOTIFICATION_ICON = new Map([
  ["Progres belajar", "study-progress-circle"],
  ["Jadwal", "schedule-circle"],
  ["Pembelian", "pay-circle"],
  ["Promo", "promo-circle"],
  ["Lainnya", "other-circle"]
]);
