var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer__container"},[_c('div',{staticClass:"footer"},[_vm._m(0),_vm._l((_vm.footer_menu),function(el,idx){return _c('section',{key:("footer-sec-" + idx),staticClass:"footer__section"},[_c('h3',{staticClass:"footer__section-title"},[_vm._v(_vm._s(el.sectionName))]),_vm._l((el.children),function(element,index){return [(element.children)?_c('div',{key:("item-footer-" + index),staticClass:"footer__icon-group"},[_vm._l((element.children),function(item,id){return [(item.out)?_c('a',{key:("item-footer-child-" + id),staticClass:"footer__link",class:{
                'footer__link--icon': item.type === 'icon',
                footer__icon: item.type === 'icon'
              },attrs:{"href":item.link}},[(item.type === 'icon' && item.name !== _vm.nameApp)?_c('svg-icon',{attrs:{"icon-class":item.name}}):(item.name !== _vm.nameApp)?[_vm._v(" "+_vm._s(item.linkLabel)+" ")]:_vm._e()],2):_c('router-link',{key:("item-footer-child-" + id),staticClass:"footer__link",class:{
                'footer__link--icon': item.type === 'icon',
                footer__icon: item.type === 'icon'
              },attrs:{"to":item.link}},[(item.type === 'icon' && item.name !== _vm.nameApp)?_c('svg-icon',{attrs:{"icon-class":item.name}}):(item.name !== _vm.nameApp)?[_vm._v(" "+_vm._s(item.linkLabel)+" ")]:_vm._e()],2)]})],2):[(element.out)?[(element.type === 'icon' && element.name !== _vm.nameApp)?_c('a',{key:("item-footer-" + index),class:{
                footer__link: element.type !== 'icon',
                'footer__link--icon': element.type === 'icon',
                footer__icon: element.type === 'icon'
              },attrs:{"href":element.link}},[_c('svg-icon',{attrs:{"icon-class":element.name}})],1):(element.name !== _vm.nameApp)?_c('a',{key:("item-footer-" + index),staticClass:"footer__link",attrs:{"href":element.link},domProps:{"innerHTML":_vm._s(element.linkLabel)}}):_vm._e()]:[(element.type === 'icon' && element.name !== _vm.nameApp)?_c('router-link',{key:("item-footer-" + index),class:{
                footer__link: element.type !== 'icon',
                'footer__link--icon': element.type === 'icon',
                footer__icon: element.type === 'icon'
              },attrs:{"to":element.link}},[_c('svg-icon',{attrs:{"icon-class":element.name}})],1):(element.name !== _vm.nameApp)?_c('router-link',{key:("item-footer-" + index),staticClass:"footer__link",attrs:{"to":element.link},domProps:{"innerHTML":_vm._s(element.linkLabel)}}):_vm._e()]]]})],2)})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"footer__section"},[_c('figure',{staticClass:"footer__logo-container"},[_c('img',{staticClass:"footer__logo",attrs:{"alt":"BuwhanEdu-logo","src":"https://assets.edukasystem.com/logo/buwhanedu-logo-white@3x.png"}})]),_c('p',{staticClass:"footer__section-desc"},[_vm._v(" Media belajar berbasis teknologi yang fokus untuk meningkatkan keterampilan siswa Indonesia dalam memecahkan masalah yang kompleks. ")])])}]

export { render, staticRenderFns }