import Event from "@/api/events";
import Time from "@/api/time";
import Enrollment from "@/api/enrollment";
import Exam from "@/api/exam";
import store from "../../store";
import { getUserStatus } from "@/utils/game";

const DEFAULT_DP_STATUS = ["event", "exam"];
const REPORT_SEMESTER_END_DATE = 1643648399000; // 31 Jan 2022 23:59:59
const routes = [
  {
    path: "/dashboard",
    name: "home",
    component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/index.vue"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "sticky-on-phone-size",
      type_page: "main",
      dp_status: [...DEFAULT_DP_STATUS, "rank"],
      page_name: "Beranda",
      hide_navbar_phone: true,
      hide_back_button: true,
      show_notification: true
    }
  },
  {
    path: "/search",
    name: "search",
    component: () => import(/* webpackChunkName: "search" */ "@/views/Search"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "sticky-on-phone-size",
      type_page: "main",
      page_name: "Pencarian",
      hide_navbar_phone: true,
      hide_back_button: true
    }
  },
  {
    path: "/notifikasi",
    name: "notification",
    component: () => import(/* webpackChunkName: "notification" */ "@/views/Notification"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      type_page: "main",
      page_name: "Notifikasi",
      mobile_navbar: "purple",
      use_breadcrumb: "show",
      background_color: "grey",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Notifikasi",
          routeName: "notification"
        }
      ]
    }
  },
  {
    path: "/informasi-jurusan",
    name: "major-information",
    component: () => import(/* webpackChunkName: "major-information" */ "@/views/MajorInformation"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "sticky-on-phone-size",
      type_page: "main",
      page_name: "Informasi Jurusan",
      mobile_navbar: "purple",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Informasi Jurusan",
          routeName: "major-information"
        }
      ]
    }
  },
  {
    path: "/rapot-semester",
    name: "report-semester",
    component: () => import(/* webpackChunkName: "report-semester" */ "@/views/ReportSemester"),
    meta: {
      requires_auth: true,
      type_navbar: "sticky-on-phone-size",
      type_page: "main",
      hide_navbar_phone: true,
      hide_bottom_bar: true
    },
    beforeEnter: async (to, from, next) => {
      const status = await getUserStatus().then(res => res);
      const time = await Time.getTime().then(res => res.data.timestamp);

      if (status.total_exp >= 1000 && time < REPORT_SEMESTER_END_DATE) {
        next();
      } else {
        next({ name: "home" });
      }
    }
  },
  {
    path: "/event/minat-bakat",
    name: "minat-bakat",
    component: () =>
      import(/* webpackChunkName: "aptitude-event-detail" */ "@/views/MinatBakatEvent"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      type_page: "main",
      page_name: "Tes Kepribadian dan Jurusan",
      dp_status: [...DEFAULT_DP_STATUS, "rank"]
    }
  },
  {
    path: "/event/tryout",
    name: "tryout-list",
    component: () => import(/* webpackChunkName: "event-detail" */ "@/views/Tryout"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      type_page: "main",
      dp_status: [...DEFAULT_DP_STATUS, "rank"],
      background_color: "grey",
      page_name: "Buwhan Edu Tryout",
      hide_back_button: true,
      mobile_navbar: "purple",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Daftar Tryout",
          routeName: "tryout-list"
        }
      ]
    }
  },
  {
    path: "/event/minat-bakat/exam",
    name: "minat-bakat-exam",
    component: () => import(/* webpackChunkName: "exam" */ "@/views/MinatBakatExam"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "hide-on-desktop-size",
      page_name: "Tes Kepribadian dan Jurusan",
      dp_status: [...DEFAULT_DP_STATUS, "content"]
    }
  },
  {
    path: "/event/:id",
    name: "event",
    component: () => import(/* webpackChunkName: "event-detail" */ "@/views/Event"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      dp_status: [...DEFAULT_DP_STATUS, "rank", "content"],
      title_params: true,
      page_name: async route => {
        let { event_name } = await Event.getEventsById(route.params.id).then(res => res.data.data);
        return event_name || "Tryout";
      },
      back_to: {
        name: "tryout-list"
      }
    },
    beforeEnter: (to, from, next) => {
      store.commit("State/setRouterFrom", from.name);
      next();
    }
  },
  {
    path: "/event/:id/exam/:session",
    name: "exam",
    component: () => import(/* webpackChunkName: "exam" */ "@/views/Exam/index.vue"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      dp_status: [...DEFAULT_DP_STATUS, "content"]
    },
    beforeEnter: async (to, from, next) => {
      const res_enrolled = await Enrollment.getEnrollmentStatus(to.params.id).then(
        res => res.data.data
      );
      let enrolled = !!res_enrolled;
      if (enrolled) {
        next();
      } else {
        next({ path: from.path });
      }
    }
  },
  {
    path: "/event/:id/pembahasan/:session",
    name: "pembahasan",
    component: () => import(/* webpackChunkName: "pembahasan" */ "@/views/Exam/index.vue"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      page_name: "Pembahasan",
      dp_status: [...DEFAULT_DP_STATUS, "content"]
    },
    beforeEnter: async (to, from, next) => {
      const timestamp = await Time.getTime().then(res => res.data.timestamp);

      const enrollment = await Enrollment.getEnrollmentStatus(to.params.id).then(
        res => res.data.data
      );
      const enrollmentStatus = enrollment?.status || "free";
      const enrolled = !!enrollment;

      const event = await Event.getEventsById(to.params.id).then(res => res.data.data);

      const exam_details = await Exam.getExamDetails(to.params.id).then(res => res.data.data);
      const exam = exam_details || {};

      const examEndDate =
        enrollmentStatus === "free" || enrollmentStatus === "free-compensation"
          ? event?.free_end_date
          : event?.end_date;

      let isCanOpenSolution = false;
      if (event.open_solution_date > examEndDate) {
        isCanOpenSolution =
          timestamp > event.open_solution_date &&
          enrolled &&
          (enrollmentStatus === "free" ||
            enrollmentStatus === "free-compensation" ||
            exam?.finished_at);
      } else {
        const enrolledAndOpenSolution =
          timestamp > event.open_solution_date && enrolled && exam?.finished_at;
        const isEventEnded =
          timestamp > examEndDate &&
          enrolled &&
          (enrollmentStatus === "free" ||
            enrollmentStatus === "free-compensation" ||
            exam?.finished_at);
        isCanOpenSolution = enrolledAndOpenSolution || isEventEnded;
      }

      if (isCanOpenSolution) {
        next();
      } else {
        next({ name: "event", params: { id: to.params.id } });
      }
    }
  },
  {
    path: "/event/:id/ranking",
    name: "ranking",
    redirect: "/event/:id/ranking/my-rank",
    // redirect: "/maintanance",
    component: () => import(/* webpackChunkName: "ranking" */ "@/views/Ranking"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      dp_status: [...DEFAULT_DP_STATUS, "rank", "statistic"]
    },
    children: [
      {
        path: "my-rank",
        component: () => import("@/views/Ranking/MyRank"),
        name: "my-rank",
        meta: {
          is_secondary_navbar: true,
          title_params: true,
          page_name: async route => {
            let { event_name } = await Event.getEventsById(route.params.id).then(
              res => res.data.data
            );
            return event_name || "Tryout";
          }
        }
      },
      {
        path: "my-stat",
        component: () => import("@/views/Ranking/MyStat"),
        name: "my-stat",
        meta: {
          is_secondary_navbar: true,
          title_params: true,
          page_name: async route => {
            let { event_name } = await Event.getEventsById(route.params.id).then(
              res => res.data.data
            );
            return event_name || "Tryout";
          }
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      Enrollment.getEnrollmentStatus(to.params.id).then(async res => {
        // let res_event_by_id = await Event.getEventsById(to.params.id).then(res => res.data.data);
        if (res.data.data) {
          next();
        } else {
          next({ path: "404" });
        }
      });
    }
  }
];

export default routes;
