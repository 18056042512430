<script>
import Auth from "@/api/auth";

import { setLSObjectWithExpiry } from "@/utils/general";
import { getUserId, removeUserId } from "@/utils/user";
import { removeToken, getToken } from "@/utils/auth";
import { removeUnusedCache } from "@/utils/general";

export default {
  name: "ModalSaveProgress",
  data() {
    return {
      check: false
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async goToSSO() {
      const token = getToken();
      this.$honeybadger?.resetContext();
      await Auth.blacklistToken(token);
      removeToken();
      removeUserId();
      removeUnusedCache();
      window.location = `${
        process.env.VUE_APP_SSO
      }/auth/logout?redirect=${`${process.env.VUE_APP_SSO}/auth/login`}`;
    },
    close() {
      if (this.check) {
        const date = new Date();
        var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
        setLSObjectWithExpiry(`noRemindSaveProgress:${getUserId()}`, true, newDate.getTime());
      }
      this.$emit("close");
    }
  }
};
</script>

<template>
  <ModalGeneral :show="show" disable-close-btn modal-type="slider">
    <div @click="close">
      <div :class="$style.rectangle"></div>
    </div>
    <div :class="$style.save_progress">
      <div :class="$style.save_progress__content">
        <h1 :class="$style.save_progress__content__title">Simpan progres belajar kamu yuk!</h1>
        <img
          :class="$style.save_progress__content__image"
          src="https://assets.edukasystem.com/mascot/buwhan-login-free.png"
          alt="buwhan daftar sekarang"
        />
        <p :class="$style.save_progress__content__desc">
          Kamu perlu masuk ke akun Buwhan Edu kamu dulu supaya progres belajar kamu tersimpan.
        </p>
      </div>
      <div :class="$style.save_progress__checkbox">
        <b-checkbox class="custom-checkbox" :class="$style.checkbox" v-model="check">
          Jangan tampilkan lagi
        </b-checkbox>
      </div>
      <div :class="$style.save_progress__action">
        <button class="btn btn-secondary--alt" @click="close">Saya mengerti</button>
        <button class="btn btn-secondary" @click="goToSSO">Masuk</button>
      </div>
    </div>
  </ModalGeneral>
</template>

<style lang="scss" module>
.rectangle {
  margin: auto;
  width: 2rem;
  height: 0.25rem;
  background: #c4c4c4;
  border-radius: 0.5rem;

  @include min-media(tablet) {
    display: none;
  }
}

.save_progress {
  padding: 1.5rem;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  width: 100%;
  max-width: 500px;
  max-height: 500px;

  @include min-media(desktop) {
    max-height: 700px;
    width: 500px;
  }

  &__content {
    &__title {
      font-size: 1rem;
      text-align: left;

      @include min-media(tablet) {
        font-size: 1.25rem;
      }
    }

    &__image {
      margin: 1.5rem 0;
      width: 100%;
      max-width: 160px;
    }

    &__desc {
      font-size: 0.75rem;
      text-align: left;

      @include min-media(tablet) {
        font-size: 1rem;
      }
    }
  }

  &__checkbox {
    text-align: left;
    margin: 1.5rem 0;
    font-size: 0.75rem;

    @include min-media(tablet) {
      font-size: 1rem;
    }
  }

  &__action {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    button {
      width: 100%;
      max-width: 150px;
      font-size: 0.75rem;

      @include min-media(tablet) {
        font-size: 1rem;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.custom-checkbox::v-deep {
  .check {
    border-color: $purple !important;

    &:hover {
      border-color: $purple !important;
    }
  }
  input[type="checkbox"]:checked + .check {
    box-shadow: 0 1px 2px $purple !important;
    background-color: $purple !important;
  }
}
</style>
