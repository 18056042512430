<script>
export default {
  name: "CustomField",
  props: {
    label: String,
    message: String,
    // generalInfo: String,
    // dangerInfo: String,
    // successInfo: String,
    state: {
      type: String,
      validator: value => {
        return ["first", "default", "danger", "success"].indexOf(value) !== -1;
      },
      default: "default"
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    bodyClass() {
      const { body, danger, success } = this.$style;
      switch (this.state) {
        case "danger":
          return [body, danger];
        case "success":
          return [body, success];
        case "first":
        case "default":
        default:
          return body;
      }
    }
    // info() {
    //   const {generalInfo, dangerInfo, successInfo} = this;
    //   switch(this.state) {
    //     case 'danger': return dangerInfo
    //     case 'success': return successInfo
    //     case 'first':
    //     default:
    //       return generalInfo
    //   }
    // }
  }
};
</script>

<template>
  <label :class="bodyClass">
    <div :class="$style.labelGroup">
      <span :class="$style.title">
        {{ label }}
      </span>
      <slot name="right-label"></slot>
    </div>
    <div :class="$style.wrapper">
      <slot></slot>
      <template v-if="showIcon">
        <svg-icon icon-class="check" :class-name="`${$style.icon} ${$style.iconSuccess}`" />
        <svg-icon icon-class="error" :class-name="`${$style.icon} ${$style.iconDanger}`" />
      </template>
    </div>
    <div v-show="message" :class="$style.info">
      {{ message }}
    </div>
  </label>
</template>

<style module lang="scss">
.body {
  width: 100%;
  margin-bottom: 1.5rem;
  display: block;
  position: relative;
  font-family: $nunito;
}

.wrapper {
  position: relative;
}

.labelGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.75rem;
  opacity: 0;
  transition: all 0.2s ease;
}

.info {
  position: absolute;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  top: 95%;
  left: 0;
  transition: all 0.2s ease;
}

.success {
  .iconSuccess {
    opacity: 1;
  }

  .info {
    top: 100%;
    color: $correct;
  }
}

.danger {
  .iconDanger {
    opacity: 1;
    color: #f14668;
  }

  .info {
    top: 100%;
    color: #f14668;
  }
}
</style>
