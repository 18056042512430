import Cookies from "js-cookie";

const UserId = "user-id";

export function getUserId() {
  return Cookies.get(UserId);
}

export function setUserId(id) {
  return Cookies.set(UserId, id);
}

export function removeUserId() {
  return Cookies.remove(UserId);
}

export const checkUserTrial = () => {
  const uid = getUserId();
  if (uid == process.env.VUE_APP_TRIAL_UID) return true;
  return false;
};
