const routes = [
  {
    path: "/maintenance",
    name: "Maintenance",
    meta: {
      no_loading: true,
      type_navbar: "none"
    },
    component: () => import(/* webpackChunkName: "maintesnance" */ "@/views/Maintenance")
  },
  // {
  //   path: "/user/verification",
  //   redirect: "/"
  // },
  {
    path: "*",
    redirect: "/maintenance"
  }
];

export default routes;
