import CodeMajor from "@/api/code-major";
import Game from "@/api/game";
import store from "@/store";

import { getLSObjectWithExpiry } from "@/utils/general";
import { checkUserTrial } from "@/utils/user";

const routes = [
  {
    path: "/target-belajar",
    name: "daily-plan",
    component: () =>
      import(/* webpackChunkName: "daily-plan" */ "@/views/DailyPlan/StudyTracker.vue"),
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      type_page: "main",
      background_color: "grey",
      page_name: "Ambis Tracker",
      back_to: {
        name: "home"
      },
      use_breadcrumb: "show",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Ambis Tracker",
          routeName: "daily-plan"
        }
      ]
    },
    beforeEnter: async (to, from, next) => {
      const isUserTrial = checkUserTrial();
      let tracker;
      if (!isUserTrial) {
        tracker = await Game.getTrackerPlan().then(res => res.data.data);
        store.commit("State/setRouterFrom", from.name);
      } else {
        const { data, expired } = getLSObjectWithExpiry("trial:tracker", new Date().getTime());
        if (expired) {
          tracker = null;
        } else {
          tracker = data;
        }
      }

      if (tracker) {
        next();
      } else {
        next({ name: "daily-plan-create" });
      }
    }
  },
  {
    path: "/target-belajar/create",
    name: "daily-plan-create",
    component: () =>
      import(/* webpackChunkName: "daily-plan-create" */ "@/views/DailyPlan/NewStudyPlan.vue"),
    beforeEnter: async (to, from, next) => {
      const isUserTrial = checkUserTrial();
      let tracker;
      if (!isUserTrial) {
        tracker = await Game.getTrackerPlan().then(res => res.data.data);
      } else {
        const { data, expired } = getLSObjectWithExpiry("trial:tracker", new Date().getTime());
        if (expired) {
          tracker = null;
        } else {
          tracker = data;
        }
      }

      if (tracker) {
        next({ name: "daily-plan" });
      } else {
        next();
      }
    },
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      type_page: "main",
      background_color: "grey",
      page_name: "Ambis Tracker",
      back_to: {
        name: "home"
      }
    }
  },
  {
    path: "/target-belajar/progress-mata-pelajaran",
    name: "progress-home",
    redirect: "/target-belajar/progress-mata-pelajaran",
    component: () => import(/* webpackChunkName: "daily-plan-progress" */ "@/views/StudyProgress"),
    meta: {
      requires_auth: true,
      type_navbar: "login"
    },
    children: [
      {
        path: "",
        component: () => import("@/views/StudyProgress/ProgressAllMajor"),
        name: "progress-all",
        meta: {
          whitelist_trial: true,
          back_to: {
            name: "daily-plan"
          },
          background_color: "grey",
          page_name: "Progres Mata Pelajaran",
          use_breadcrumb: "show",
          breadcrumb: [
            {
              name: "Beranda",
              routeName: "home"
            },
            {
              name: "Ambis Tracker",
              routeName: "daily-plan"
            },
            {
              name: "Progress Mata Pelajaran",
              routeName: "progress-all"
            }
          ]
        }
      },
      {
        path: "/target-belajar/progress-mata-pelajaran/:id_code_major",
        component: () => import("@/views/StudyProgress/ProgressMajor"),
        name: "progress-major",
        back_to: {
          name: "progress-all"
        },
        meta: {
          whitelist_trial: true,
          background_color: "grey",
          use_breadcrumb: "show",
          title_params: true,
          breadcrumb: async route => {
            const { major_name } = await CodeMajor.getCodeMajor(route.params.id_code_major).then(
              res => res.data.data
            );
            const result = [
              {
                name: "Beranda",
                routeName: "home"
              },
              {
                name: "Ambis Tracker",
                routeName: "daily-plan"
              },
              {
                name: "Progress Mata Pelajaran",
                routeName: "progress-all"
              },
              {
                name: major_name || "Major",
                routeName: "progress-major"
              }
            ];
            return result;
          },
          page_name: async route => {
            const { major_name } = await CodeMajor.getCodeMajor(route.params.id_code_major).then(
              res => res.data.data
            );
            return major_name || "Progress Keseluruhan";
          }
        }
      }
    ]
  },
  {
    path: "/klaim-hadiah",
    name: "claim-prize",
    component: () => import(/* webpackChunkName: "klaim-hadiah" */ "@/views/Claim"),
    meta: {
      requires_auth: true,
      type_navbar: "login",
      type_page: "main",
      background_color: "grey",
      use_breadcrumb: "show",
      page_name: "Klaim Hadiah",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Klaim Hadiah",
          routeName: "claim-prize"
        }
      ]
    }
  },
  {
    path: "/privacy-policy/gcal",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy-gcal" */ "@/views/PrivacyPolicy/GoogleCalendar.vue"
      ),
    name: "privacy-policy-gcal",
    meta: {
      requires_auth: true,
      type_navbar: "login",
      hide_navbar_phone: true
    }
  },
  {
    path: "/scheduling",
    component: () => import(/* webpackChunkName: "scheduling" */ "@/views/Scheduling"),
    name: "scheduling",
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      back_to: {
        name: "home"
      },
      background_color: "grey",
      page_name: "Study Manager",
      use_breadcrumb: "show",
      breadcrumb: [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Study Manager",
          routeName: "scheduling"
        }
      ]
    }
  },
  {
    path: "/scheduling/add/:schedule_type",
    //schedule_type is  belajar, kegiatan, tryout
    component: () => import("@/views/Scheduling/AddSchedule"),
    name: "add-schedule",
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      back_to: {
        name: "scheduling"
      },
      background_color: "grey",
      title_params: true,
      page_name: route =>
        `Tambah Jadwal ${
          route.params.schedule_type.toLowerCase() == "belajar"
            ? "Belajar"
            : route.params.schedule_type.toLowerCase() == "kegiatan"
            ? "Kegiatan"
            : "Tryout"
        }`,
      use_breadcrumb: "show",
      breadcrumb: route => [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Study Manager",
          routeName: "scheduling"
        },
        {
          name: `Tambah Jadwal ${
            route.params.schedule_type.toLowerCase() == "belajar"
              ? "Belajar"
              : route.params.schedule_type.toLowerCase() == "kegiatan"
              ? "Kegiatan"
              : "Tryout"
          }`,
          routeName: "add-schedule"
        }
      ]
    }
  },
  {
    path: "/scheduling/edit/:schedule_type/:id_schedule",
    //schedule_type is  belajar, kegiatan, tryout
    component: () => import("@/views/Scheduling/AddSchedule"),
    name: "edit-scheduling",
    meta: {
      whitelist_trial: true,
      requires_auth: true,
      type_navbar: "login",
      back_to: {
        name: "scheduling"
      },
      background_color: "grey",
      title_params: true,
      page_name: route =>
        `Edit Jadwal ${
          route.params.schedule_type.toLowerCase() == "belajar"
            ? "Belajar"
            : route.params.schedule_type.toLowerCase() == "kegiatan"
            ? "Kegiatan"
            : "Tryout"
        }`,
      use_breadcrumb: "show",
      breadcrumb: route => [
        {
          name: "Beranda",
          routeName: "home"
        },
        {
          name: "Study Manager",
          routeName: "scheduling"
        },
        {
          name: `Edit Jadwal ${
            route.params.schedule_type.toLowerCase() == "belajar"
              ? "Belajar"
              : route.params.schedule_type.toLowerCase() == "kegiatan"
              ? "Kegiatan"
              : "Tryout"
          }`,
          routeName: "edit-schedule"
        }
      ]
    }
  }
];

export default routes;
