import service from "@/utils/request";
import { getUserId } from "@/utils/user";
import { getToken } from "@/utils/auth";
export default class User {
  static baseGroupURL = "account/user";

  static async verifToken(token) {
    const res = service.post(`${this.baseGroupURL}/verification/${token}`);
    return res;
  }

  static async getUser() {
    const resp = await service.get(`${this.baseGroupURL}/${getUserId()}`);
    return resp;
  }

  static async getUserByIdentifier(identifier) {
    const resp = await service.get(`${this.baseGroupURL}/${identifier}`);
    return resp;
  }

  static async getUserDetails() {
    const resp = await service.get(`${this.baseGroupURL}/${getUserId()}/details`);
    return resp;
  }

  static async getUserChoice() {
    const resp = await service.get(`${this.baseGroupURL}/${getUserId()}/choice`);
    return resp;
  }

  static async getUserMajorChoice() {
    const resp = await service.get(`${this.baseGroupURL}/${getUserId()}/major-choice`);
    return resp;
  }

  static async getUserByEmail(email) {
    const resp = await service.get(`${this.baseGroupURL}/${email}`);
    return resp;
  }

  static async getUserRecommendation() {
    const resp = await service.get(`${this.baseGroupURL}/${getUserId()}/recommendation`);
    return resp;
  }

  static async deleteUserRecommendation() {
    const resp = await service.delete(`${this.baseGroupURL}/${getUserId()}/recommendation`);
    return resp;
  }

  static async getUserRecommendationV2() {
    const resp = await service.get(`${this.baseGroupURL}/${getUserId()}/recommendation/v2`);
    return resp;
  }

  static async getMajorCategoryRecommendation(id_major) {
    const resp = await service.get(
      `${this.baseGroupURL}/${getUserId()}/major/${id_major}/recommendation/v2`
    );
    return resp;
  }

  static async updateUserName(data) {
    const res = service.put(`${this.baseGroupURL}/${getUserId()}/username`, data);
    return res;
  }

  static async signup(data) {
    const res = service.post(`${this.baseGroupURL}`, data);
    return res;
  }

  static async updateUserDetails(data) {
    const res = service.put(`${this.baseGroupURL}/${getUserId()}/details`, data);
    return res;
  }

  static async updateUserEmail(data) {
    const res = service.put(`${this.baseGroupURL}/${getUserId()}/email`, data);
    return res;
  }

  static async createUserChoice(data) {
    const res = service.post(`${this.baseGroupURL}/${getUserId()}/choice`, data);
    return res;
  }

  static async updateUserChoice(data) {
    const res = service.put(`${this.baseGroupURL}/${getUserId()}/choice`, data);
    return res;
  }

  static async upsertUserMajorChoice(data) {
    const res = service.post(`${this.baseGroupURL}/${getUserId()}/major-choice`, data);
    return res;
  }

  static async postSendChangePasswordForm(data) {
    const res = service.post(`${this.baseGroupURL}/password/reset`, data);
    return res;
  }

  static async putNewPassword(identifier, data, token) {
    const res = service.put(`${this.baseGroupURL}/${identifier}/password`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `BEARER ${token}`
      }
    });
    return res;
  }

  static async postVerifyEmail() {
    const res = service.post(`${this.baseGroupURL}/${getUserId()}/verification`, null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `BEARER ${getToken()}`
      }
    });
    return res;
  }

  static async getUserOnboardingStatus() {
    const res = service.get(`${this.baseGroupURL}/${getUserId()}/onboarding`);
    return res;
  }

  static async upsertUserOnboardingStatus(data) {
    const res = service.post(`${this.baseGroupURL}/${getUserId()}/onboarding`, data);
    return res;
  }

  static async createUTMHistory(data) {
    const res = service.post(`${this.baseGroupURL}/${getUserId()}/utm_history`, data);
    return res;
  }

  static async freeTrialHistory() {
    const res = service.post(`${this.baseGroupURL}/${getUserId()}/free-trial-history/referral`);
    return res;
  }

  static async checkPhoneNumber(phone_number) {
    const resp = await service.post(`${this.baseGroupURL}/phone-number/check`, {
      phone_number
    });
    return resp;
  }

  static async getDeactivateReasons() {
    const resp = await service.get(`${this.baseGroupURL}/deactive/reason`);
    return resp;
  }

  static async deactivateUser(data) {
    const resp = await service.post(`${this.baseGroupURL}/deactive/reason/user`, {
      ...data,
      id_user: getUserId()
    });
    return resp;
  }

  static async register(data) {
    const resp = await service.post(`${this.baseGroupURL}`, data);
    return resp;
  }
}
