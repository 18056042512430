import Vue from "vue";
import Vuex from "vuex";

import BankSoal from "./modules/BabKonsep/bank_soal";
import Filter from "./modules/filter";
import LockedModal from "./modules/BabKonsep/locked_modal";
import Notification from "./modules/notification";
import Pay from "./modules/pay";
import Referral from "./modules/referral";
import State from "./modules/state";
import SubscriptionModal from "./modules/BabKonsep/subscription_modal";
import UnavailableModal from "./modules/BabKonsep/unavailable_modal";
import User from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    BankSoal,
    Filter,
    LockedModal,
    Notification,
    Pay,
    Referral,
    State,
    SubscriptionModal,
    UnavailableModal,
    User
  }
});
