<script>
export default {
  name: "ProgressBar",
  props: {
    percent: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: "progress"
    },
    useLine: {
      type: Boolean,
      default: true
    },
    showValue: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 16
    },
    backgroundColor: {
      type: String,
      default: "#cdd2dc"
    },
    progressColor: {
      type: String,
      default: "#7250ec"
    }
  },
  computed: {
    displayPercentage() {
      if (this.percent > 100) return 100;
      return this.percent;
    }
  }
};
</script>
<template>
  <div
    :style="{ height: `${height}px`, background: backgroundColor }"
    :class="[$style.container, type === 'compare' ? $style.containerCompare : '']"
  >
    <div
      :class="[
        $style.fill,
        type === 'compare' ? $style.fillCompare : '',
        percent < 10 ? $style.smallCase : ''
      ]"
      :style="{ width: `${displayPercentage}%`, background: progressColor }"
    >
      <p v-if="showValue && type !== 'compare'" :class="$style.fillPercent">{{ percent }}%</p>
      <!-- <img :class="$style.gif" src="@/assets/gif/Burhan-Jalan.gif" alt="Burhan-Jalan" /> -->
      <div v-if="useLine" :class="$style.whiteIndicator"></div>
    </div>
    <div v-if="showValue && type !== 'compare'" :style="{ width: `${100 - displayPercentage}%` }">
      <p>
        <b>{{ 100 - percent }}%</b>
      </p>
    </div>
    <div v-if="showValue && type === 'compare'" :class="$style.compareValue">
      <p :style="{ color: `${percent < 10 ? '#212121' : 'white'}` }">{{ percent }}%</p>
      <p :style="{ color: `${100 - percent > 10 ? '#212121' : 'white'}` }">{{ 100 - percent }}%</p>
    </div>
  </div>
</template>

<style lang="scss" module>
.container {
  width: 100%;
  border-radius: 1rem;

  display: flex;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.containerCompare {
    background: #c7b9f7;
    height: initial;
    position: relative;

    .compareValue {
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      p {
        font-size: 0.75rem;
        margin: 0 0.5rem;
        font-weight: 700;

        @include min-media(tablet) {
          font-size: 0.875rem;
        }
      }
    }
  }

  .fill {
    position: relative;
    border-radius: 1rem;
    height: 100%;

    &.smallCase {
      border-radius: 1rem 0 0 1rem;
    }

    &Percent {
      font-weight: bold;
      color: white;
    }
  }

  .gif {
    max-width: 50px;
    position: absolute;
    z-index: 100;
    // left: 70%;
    right: -20px;
    bottom: 0;
  }

  .whiteIndicator {
    top: 5px;
    left: 10%;
    width: 80%;
    position: absolute;
    height: 2px;
    background: rgba(255, 255, 255, 0.75);
  }
}
</style>
