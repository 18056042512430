// PLEASE LIST ALL META USAGE HERE FOR DOCUMENTATION
/** --------------------------------
 * ROUTER META ATTRIBUTE:
 * 1. type_page
 *    - value: main or anything
 *    - if value is main, display bottom bar in responsive mode
 * 2. title_params
 *    - value: true or false
 *    - if true, breadcrumb and page_name changes to function
 * 3. page_name
 *    - usage: set page name when responsive navbar
 * 4. breadcrumb
 *    - usage: display set of breadcrumb in desktop page
 * 5. background_color
 *    - current value: light-blue, grey, light-grey, light-orange
 * 6. mobile_navbar
 *    - if unset background responsive navbar is white
 *    - if set to purple, vice versa will be purple
 * 7. hide_back_button
 *    - value: true or false
 *    - if true, hide back button in mobile responsive
 * 8. requires_auth
 *    - if true, user must have token (login) to access the route
 * 9. back_to
 *    - value: Object router (name, params, etc)
 *    - Dedicated back router for back button in mobile responsive navbar
 * 10. hide_bottom_bar
 *    - value: true or false
 *    - hide bottom bar in responsive
 * 11. hide_help
 *    - value: true or false
 *    - hide help button in responsive
 * 12. use_breadcrumb
 *    - value: show or anything
 *    - whether a page use breadcrumb or not
 * 13. must_not_login
 *    - values: true or false
 *    - if true, user must not have token to access the page
 * 14. no_loading
 *    - values: true or false
 *    - if true, page wont have loading indicator when load the page
 * 15. type_navbar
 *    - values: none (hide navbar), login (normal), etc?
 * 16. type_footer
 *    - values: none (hide footer), show, etc?
 * 17. title
 *    - Set title of tab to specific value
 * 18. show_notification
 *    - show notification icon in responsive navbar
 * 19. hide_navbar_phone
 *    - values: true or false
 * 20. dp_status?
 * 21. is_secondary_navbar ?
 * 22. use_whatsapp
 *    - whether use whatsapp chat or crisp chat
 * 23. hide_whatsapp_mobile
 *    - if use_whatsapp = true, hide on mobile or not
 * 24. whitelist_trial
 *    - whether user is trial or not
 *
 * TODO: <Add more documentation if there are new metas>
 */

import Vue from "vue";
import VueRouter from "vue-router";
import ACCOUNT_ROUTES from "./modules/account";
import EVENTS_ROUTES from "./modules/event";
import RANK_ROUTES from "./modules/rank";
import BASE_ROUTES from "./modules/base";
import MAINTENANCE_ROUTES from "./modules/maintenance-route";
import BANK_SOAL_ROUTES from "./modules/bank-soal";
import GAMIFICATION_ROUTES from "./modules/gamification";
import PAY_ROUTES from "./modules/pay";
import { MAINTENANCE_STATUS_ROUTES } from "@/constants/routes";
Vue.use(VueRouter);
const routes = !MAINTENANCE_STATUS_ROUTES["all"]
  ? [
      ...ACCOUNT_ROUTES,
      ...EVENTS_ROUTES,
      ...RANK_ROUTES,
      ...BASE_ROUTES,
      ...BANK_SOAL_ROUTES,
      ...GAMIFICATION_ROUTES,
      ...PAY_ROUTES
    ]
  : MAINTENANCE_ROUTES;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 })
});

export default router;
