/* Referral ONLY fetched globally in Navbar
  Used in Dashboard, Navbar, and Referral page
*/
const getDefaultState = () => {
  return {
    userReferral: "",
    referralDownloadStatus: false
  };
};

const state = getDefaultState();

const getters = {
  getUserReferral: state => state.userReferral,
  getReferralDownloadStatus: state => state.referralDownloadStatus
};

const mutations = {
  setUserReferral(state, payload) {
    state.userReferral = payload;
  },
  setReferralDownloadStatus(state, payload) {
    state.referralDownloadStatus = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
