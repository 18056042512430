import service from "@/utils/request";
import { getUserId } from "@/utils/user";
export default class Order {
  static baseGroupURL = "order";

  static async getReferralById() {
    const resp = await service.get(`${this.baseGroupURL}/referral/trial-usage/user/${getUserId()}`);
    return resp;
  }

  static async getUserPromo(params) {
    const resp = await service.get(`${this.baseGroupURL}/promo`, { params });
    return resp;
  }

  static async getUserPromoById(id_promo) {
    const resp = await service.get(`${this.baseGroupURL}/promo/${id_promo}`);
    return resp;
  }

  static async getPaymentChannel(params) {
    const resp = await service.get(`${this.baseGroupURL}/channel`, { params });
    return resp;
  }

  static async checkCode(code, params) {
    const resp = await service.get(`${this.baseGroupURL}/check-code/${code}`, { params });
    return resp;
  }

  static async fetchInProgressOrder(params) {
    const resp = await service.get(`${this.baseGroupURL}/in-progress`, {
      params: {
        ...params,
        sort: "desc"
      }
    });
    return resp;
  }

  static async fetchSucceedOrder(params) {
    const resp = await service.get(`${this.baseGroupURL}/succeed`, {
      params: {
        ...params,
        sort: "desc"
      }
    });
    return resp;
  }

  static async fetchFailedOrder(params) {
    const resp = await service.get(`${this.baseGroupURL}/fail`, {
      params: {
        ...params,
        sort: "desc"
      }
    });
    return resp;
  }

  static async cancelOrderById(id) {
    const resp = await service.post(`${this.baseGroupURL}/${id}/cancel`);
    return resp;
  }

  static async fetchFillDataOrder(params) {
    const resp = await service.get(`${this.baseGroupURL}/fill-data`, {
      params: {
        ...params,
        sort: "desc"
      }
    });
    return resp;
  }

  static async getOrderById(id) {
    const resp = await service.get(`${this.baseGroupURL}/${id}`);
    return resp;
  }

  static async getListOfOrders(params) {
    const resp = await service.get(`${this.baseGroupURL}`, { params });
    return resp;
  }

  static async getPaymentChannelById(id_payment_channel) {
    const resp = await service.get(`${this.baseGroupURL}/channel/${id_payment_channel}`);
    return resp;
  }

  static async checkOrderStatus(id_order) {
    const resp = await service.get(`${this.baseGroupURL}/${id_order}/check-status`);
    return resp;
  }

  static async getFinalizedOrder(id_order) {
    const resp = await service.get(`${this.baseGroupURL}/${id_order}/finalize`);
    return resp;
  }

  static async createOrder(data) {
    const resp = await service.post(`${this.baseGroupURL}/v2`, data);
    return resp;
  }

  static async expireOrder(id_order) {
    const resp = await service.post(`${this.baseGroupURL}/${id_order}/expire`);
    return resp;
  }

  static async enrollOrder(id_order) {
    const resp = await service.post(`${this.baseGroupURL}/${id_order}/enroll`);
    return resp;
  }

  static async updateOrder(id_order, data) {
    const resp = await service.put(`${this.baseGroupURL}/${id_order}`, data);
    return resp;
  }

  static async postReferralCode(id_user, referral_code) {
    const resp = await service.post(
      `${this.baseGroupURL}/referral/trial-usage/user/${id_user}/code/${referral_code}`
    );
    return resp;
  }
}
