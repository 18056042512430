import service from "@/utils/request";
import { getUserId } from "@/utils/user";

export default class Notification {
  static baseGroupURL = "notification";

  static async getNotificationCategory() {
    const resp = await service.get(`${this.baseGroupURL}/category`);
    return resp;
  }

  static async getUserNotification(params) {
    const resp = await service.get(`${this.baseGroupURL}`, {
      params: {
        ...params,
        ids_user: getUserId()
      }
    });
    return resp;
  }

  static async readNotification(id_notification) {
    const resp = await service.put(`${this.baseGroupURL}/${id_notification}`, {
      is_read: true
    });
    return resp;
  }

  static async deleteNotification(id_notification) {
    const resp = await service.delete(`${this.baseGroupURL}/${id_notification}`);
    return resp;
  }

  static async readAllNotification(is_info) {
    const resp = await service.put(`${this.baseGroupURL}`, {
      is_info,
      ids_user: [getUserId()],
      is_read: true
    });
    return resp;
  }

  static async deleteAllNotification(is_info) {
    const resp = await service.delete(`${this.baseGroupURL}`, {
      data: {
        is_info,
        ids_user: [getUserId()]
      }
    });
    return resp;
  }

  static async registerToken(token) {
    const resp = await service.post(`${this.baseGroupURL}/register`, {
      token,
      id_device: window.navigator.userAgent,
      id_user: getUserId()
    });
    return resp;
  }
}
