import Time from "@/api/time";

export const getTypeItemSales = async itemSales => {
  const currentTime = await Time.getTime().then(res => res.data.timestamp);

  const { price, free_until } = itemSales;
  if (free_until > currentTime && price?.discounted_value !== 0) {
    return "comparison";
  } else if (!price?.discounted_value) {
    return "free";
  } else {
    return "premium";
  }
};
