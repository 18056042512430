import service from "@/utils/request";
import { getUserId } from "@/utils/user";

export default class Exam {
  static baseGroupURL = "exam";

  static async getExamDetails(id_event) {
    const res = service.get(`${this.baseGroupURL}/event/${id_event}/user/${getUserId()}`);
    return res;
  }

  static async getSessionByIds(ids_session) {
    const res = service.get(`${this.baseGroupURL}/user/${getUserId()}/session`, {
      params: { ids_session }
    });
    return res;
  }

  static async getExamByIds(ids_event) {
    const res = service.get(`${this.baseGroupURL}/user/${getUserId()}/event`, {
      params: {
        ids_event
      }
    });
    return res;
  }

  static async getExamSession(id_exam) {
    const res = service.get(`${this.baseGroupURL}/${id_exam}/session`);
    return res;
  }

  static async createExam(id_event) {
    const res = service.post(`${this.baseGroupURL}`, {
      id_event: id_event,
      id_user: getUserId()
    });
    return res;
  }

  static async getUserExamEntity(id_event) {
    const res = service.get(`${this.baseGroupURL}/event/${id_event}/user/${getUserId()}`);
    return res;
  }

  static async startExam(id_exam) {
    const res = service.post(`${this.baseGroupURL}/${id_exam}/start-time`);
    return res;
  }

  static async finishExam(id_exam) {
    const res = service.post(`${this.baseGroupURL}/${id_exam}/finish-time`);
    return res;
  }

  static async createSession(id_exam, id_session) {
    const res = service.post(`${this.baseGroupURL}/${id_exam}/session/${id_session}`);
    return res;
  }

  static async getSessionState(id_exam, id_session) {
    const res = service(`${this.baseGroupURL}/${id_exam}/session/${id_session}`);
    return res;
  }

  static async finishSession(id_exam, id_session, data) {
    const res = service.post(
      `${this.baseGroupURL}/${id_exam}/session/${id_session}/finish-time`,
      data
    );
    return res;
  }

  static async getAnswersheet(id_exam, id_session) {
    const res = service.get(`${this.baseGroupURL}/${id_exam}/session/${id_session}/answersheet`);
    return res;
  }

  static async putAnswer(id_exam, id_session, id_question, data) {
    const res = service.put(
      `${this.baseGroupURL}/${id_exam}/session/${id_session}/answersheet/answer/${id_question}`,
      data
    );
    return res;
  }

  static async deleteAnswer(id_exam, id_session, id_question) {
    const res = service.delete(
      `${this.baseGroupURL}/${id_exam}/session/${id_session}/answersheet/answer/${id_question}`
    );
    return res;
  }

  static async update(id, data) {
    const resp = await service.put(`${this.baseGroupURL}/${id}`, data);
    return resp;
  }

  static async getAptitudeExamDetails(id) {
    const res = service.get(`${this.baseGroupURL}/aptitude/user/${getUserId()}/aptitude/${id}`);
    return res;
  }

  static async getAptitudeExamEntity(id_aptitude) {
    const res = service.get(
      `${this.baseGroupURL}/aptitude/user/${getUserId()}/aptitude/${id_aptitude}`
    );
    return res;
  }

  static async createAptitudeExam(data) {
    const res = service.post(`${this.baseGroupURL}/aptitude`, data);
    return res;
  }

  static async getAptitudeAnswersheet(id_exam) {
    const res = service.get(`${this.baseGroupURL}/aptitude/${id_exam}/answersheet`);
    return res;
  }

  static async startAptitudeExam(id_exam) {
    const res = service.post(`${this.baseGroupURL}/aptitude/${id_exam}/start-time`);
    return res;
  }

  static async hardRestartAptitudeExam(id_exam) {
    const res = service.post(`${this.baseGroupURL}/aptitude/${id_exam}/hardrestart`);
    return res;
  }

  static async finishAptitudeExam(id_exam, data) {
    const res = service.post(`${this.baseGroupURL}/aptitude/${id_exam}/finish-time`, data);
    return res;
  }

  static async putAptitudeAnswer(id_exam, id_question, data) {
    const res = service.put(
      `${this.baseGroupURL}/aptitude/${id_exam}/answersheet/answer/${id_question}`,
      data
    );
    return res;
  }

  static async deleteAptitudeAnswer(id_exam, id_question) {
    const res = service.delete(
      `${this.baseGroupURL}/aptitude/${id_exam}/answersheet/answer/${id_question}`
    );
    return res;
  }

  static async getUserDashboardTracker() {
    const res = service.get(`${this.baseGroupURL}/user/${getUserId()}/tracker`);
    return res;
  }

  static async getSummaryProgressTotal() {
    const res = service.get(`${this.baseGroupURL}/summary`);
    return res;
  }

  static async getSummaryProgressDaily() {
    const res = service.get(`${this.baseGroupURL}/summary/today`);
    return res;
  }

  static async getSummaryProgressTopic(id_topic) {
    const res = service.get(`${this.baseGroupURL}/summary/topic/${id_topic}`);
    return res;
  }
}
