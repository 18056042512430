const getInitialState = () => {
  return {
    modalShown: false,
    contentTitle: ""
  };
};

const state = getInitialState();

const getters = {
  isModalShown(state) {
    return state.modalShown;
  },
  getContentTitle(state) {
    return state.contentTitle;
  }
};

const mutations = {
  toggleModalShown(state) {
    state.modalShown = !state.modalShown;
  },
  setContentTitle(state, { contentTitle }) {
    state.contentTitle = contentTitle;
  },
  resetState(state) {
    Object.assign(state, getInitialState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
