import { setToken, removeToken } from "@/utils/auth";
import { setUserId, removeUserId } from "@/utils/user";
import { removeUnusedCache } from "@/utils/general";
import { setLsObject, setLSObjectWithExpiry, getLSObjectWithExpiry } from "@/utils/general";

import { SHOW_ADS } from "@/constants/key";
import { TUTORIAL_DETAILS } from "@/constants/landing";

import Auth from "@/api/auth";
import Time from "@/api/time";
import Calendar from "@/api/calendar";

const routes = [
  {
    path: "/",
    name: "LandingMenu",
    redirect: "/home",
    component: () => import(/* webpackChunkName: "main-menu" */ "@/views/Landing"),
    meta: {
      must_not_login: true
    },
    children: [
      {
        path: "/",
        name: "Landing",
        component: () => import(/* webpackChunkName: "main" */ "@/views/Landing/components/Home"),
        meta: {
          must_not_login: true,
          background_color: "grey",
          use_whatsapp: true
        }
      },
      {
        path: "about",
        name: "About",
        component: () => import(/* webpackChunkName: "about" */ "@/views/Landing/components/About"),
        meta: {
          must_not_login: true,
          use_whatsapp: true
        }
      },
      {
        path: "career",
        name: "Career",
        redirect: "/career",
        component: () =>
          import(/* webpackChunkName: "career" */ "@/views/Landing/components/Career"),
        meta: {
          must_not_login: true
        },
        children: [
          {
            path: "search",
            name: "SearchCareer",
            redirect: "/maintenance",
            meta: {
              use_whatsapp: true
            },
            component: () =>
              import(
                /* webpackChunkName: "career-search" */ "@/views/Landing/components/Career/Search.vue"
              )
          },
          {
            path: "/",
            name: "CareerIntro",
            component: () =>
              import(
                /* webpackChunkName: "career-intro" */ "@/views/Landing/components/Career/Intro.vue"
              ),
            meta: {
              use_whatsapp: true
            }
          }
        ]
      },
      {
        path: "testimonial",
        name: "Testimonial",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Landing/components/Testimonial"),
        meta: {
          must_not_login: true
        }
      },
      {
        path: "partnership",
        name: "Partnership",
        meta: {
          use_whatsapp: true
        },
        component: () =>
          import(/* webpackChunkName: "error-404" */ "@/views/Landing/components/Partnership")
      },
      {
        path: "/lihat-caranya/:feature",
        name: "Tutorial",
        component: () =>
          import(/* webpackChunkName: "main" */ "@/views/Landing/components/Tutorial"),
        meta: {
          must_not_login: true,
          use_whatsapp: true
        },
        beforeEnter: async (to, from, next) => {
          if (TUTORIAL_DETAILS.find(el => el.feature === to.params.feature)) {
            return next();
          }
          return next("/404");
        }
      },
      {
        path: "pricing",
        name: "Pricing",
        component: () =>
          import(/* webpackChunkName: "main" */ "@/views/Landing/components/Pricing"),
        meta: {
          must_not_login: true,
          background_color: "white",
          use_whatsapp: true
        }
      }
    ]
  },
  {
    path: "/sekolah",
    name: "school-landing",
    component: () => import(/* webpackChunkName: "main" */ "@/views/Landing/components/School"),
    meta: {
      type_footer: "show",
      background_color: "white",
      hide_login: true,
      use_whatsapp: true
    }
  },
  {
    path: "/404",
    name: "404",
    meta: {
      type_navbar: "none",
      no_loading: true
    },
    component: () => import(/* webpackChunkName: "404" */ "@/views/404")
  },
  {
    path: "/500",
    name: "500",
    meta: {
      type_navbar: "none",
      no_loading: true
    },
    component: () => import(/* webpackChunkName: "500" */ "@/views/404")
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    meta: {
      type_navbar: "none",
      no_loading: true
    },
    component: () => import(/* webpackChunkName: "maintenance" */ "@/views/Maintenance")
  },
  {
    path: "/direct",
    name: "direct",
    meta: {
      no_loading: true
    },
    component: () => import(/* webpackChunkName: "main" */ "@/views/Landing"),
    beforeEnter: async (to, from, next) => {
      const { redirect, utm_campaign, utm_medium, utm_source, utm_content, utm_term, out } =
        to.query;

      if (utm_campaign || utm_medium || utm_source || utm_content || utm_term) {
        const now = await Time.getTime().then(response => response.data.timestamp);
        const existedUTM = getLSObjectWithExpiry("utm", now);
        const expired_at = now + 3 * 24 * 3600 * 1000;
        if (existedUTM.expired) {
          setLSObjectWithExpiry(
            "utm",
            {
              utm_campaign: utm_campaign || "",
              utm_medium: utm_medium || "",
              utm_source: utm_source || "",
              utm_content: utm_content || "",
              utm_term: utm_term || ""
            },
            expired_at
          );
        }
      }

      if (parseInt(out) && redirect) {
        window.location.href = redirect;
        next();
      } else {
        next({ path: redirect || "/" });
      }
    }
  },
  {
    path: "/auth/google-calendar",
    name: "auth-google-calendar",
    meta: {
      no_loading: true,
      type_navbar: "none"
    },
    beforeEnter: async (to, from, next) => {
      try {
        await Calendar.exchangeAuthCode(to.query.code);
        await Calendar.sync();
        next({ name: "scheduling", query: { sync: true } });
      } catch (err) {
        console.error(err);
        next({ path: "/" });
      }
    }
  },
  {
    path: "/auth/:token",
    name: "auth",
    meta: {
      no_loading: true,
      type_navbar: "none"
    },
    beforeEnter: async (to, from, next) => {
      try {
        const res_verif = await Auth.validateToken(to.params.token).then(res => res);
        setToken(to.params.token);
        setLsObject(SHOW_ADS, 1);
        const { id } = res_verif.data.data;
        setUserId(id);

        if (to.query.redirect) {
          next({ path: to.query.redirect, query: JSON.parse(to.query.options) });
        } else {
          next({ path: "/dashboard" });
        }
      } catch (error) {
        console.error(error);
        removeToken();
        removeUserId();
        removeUnusedCache();
        next({ path: "/" });
      }
    }
  },
  {
    path: "*",
    redirect: "/404",
    meta: {
      no_loading: true
    }
  }
];

export default routes;
