import service from "@/utils/request";
import { getUserId } from "@/utils/user";
export default class Event {
  static baseGroupURL = "event";

  static async getListEvents(params) {
    const res = service.get(`${this.baseGroupURL}`, {
      params: {
        ...params,
        is_published: true
      }
    });
    return res;
  }

  static async getEventsById(id) {
    const res = service.get(`${this.baseGroupURL}/${id}`);
    return res;
  }

  static async getEventLogo(id) {
    const res = service.get(`${this.baseGroupURL}/${id}/logo`);
    return res;
  }

  static async getSessionsById(id) {
    const res = service.get(`${this.baseGroupURL}/${id}/session?v2=true`);
    return res;
  }

  static async getEvents(params) {
    const res = service.get(`${this.baseGroupURL}`, { params });
    return res;
  }

  // static async getCurrentEvents() {
  //   const res = service.get(`events?category=current`);
  //   return res;
  // }

  static async fetchListByUser(params, newAPI) {
    if (newAPI) {
      return await service.get(`${this.baseGroupURL}/user/${getUserId()}/v2`, { params });
    }
    return await service.get(`${this.baseGroupURL}/user/${getUserId()}`, { params });
  }

  static async getSessionDetails(id_session) {
    const res = service.get(`${this.baseGroupURL}/session/${id_session}`);
    return res;
  }

  static async getAptitudeTest() {
    const res = service.get(`${this.baseGroupURL}/aptitude-test`);
    return res;
  }

  static async getAptitudeTestById(id_aptitude) {
    const res = service.get(`${this.baseGroupURL}/aptitude-test/${id_aptitude}`);
    return res;
  }

  static async enrollEventByEventCode(event_code) {
    const res = service.post(`${this.baseGroupURL}/code/${event_code}`);
    return res;
  }
}
