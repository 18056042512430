import router from "./router/index";

import { requireToken } from "@/utils/middleware";
import { getToken } from "@/utils/auth";
import { getUserId, checkUserTrial } from "@/utils/user";
import { saveDataTrial } from "@/utils/trial";

import { MAINTENANCE_STATUS_ROUTES } from "@/constants/routes";
import Time from "@/api/time";
import User from "@/api/user";
import {
  setLSItem,
  removeLSItem,
  setLSObjectWithExpiry,
  getLSObjectWithExpiry
} from "@/utils/general";

import store from "@/store/index";

const defaultMetaData = [];
const defaultTitle = `Buwhan Edu - Platform Latihan Seru & Menantang`;
const handleDocumentMeta = meta => {
  document.title = meta.title || defaultTitle;
  resetMetas();
  // meta.metaTags is array of object with keys name, content
  if (meta.metaTags) {
    for (let index in meta.metaTags) {
      // get by its name
      const currentMetaTags = meta.metaTags[index];
      const currentMeta = document.querySelector(`meta[name=${currentMetaTags.name}]`);
      if (currentMeta) {
        // change the content
        for (let atr in currentMetaTags) {
          currentMeta.setAttribute(atr, currentMetaTags[atr]);
        }
      } else {
        // create new meta tags, and append it into document head
        const newMeta = document.createElement("meta");
        for (let atr in currentMetaTags) {
          newMeta.setAttribute(atr, currentMetaTags[atr]);
        }
        document.getElementsByTagName("head")[0].appendChild(newMeta);
      }
    }
  } else {
    resetMetas();
  }
};

const resetMetas = () => {
  if (defaultMetaData.length !== 0) {
    document.querySelectorAll("meta").forEach(el => {
      if (el.name) {
        if (defaultMetaData.map(el => el.name).includes(el.name)) {
          el.setAttribute("content", el.content);
        } else {
          el.remove();
        }
      }
    });
  }
};

router.beforeEach(async (to, from, next) => {
  if (from.name === "add-schedule") {
    localStorage.removeItem("dataScheduling");
    localStorage.removeItem("dataRadioScheduling");
  }
  if (defaultMetaData.length === 0) {
    document.querySelectorAll("meta").forEach(el => {
      if (el.name) {
        defaultMetaData.push({
          name: el.name,
          content: el.content
        });
      }
    });
  }
  const token = getToken();
  // LOADING PER PAGE BY VUEX
  if (!store.getters["State/getLoadingStatus"] && !to.meta.no_loading) {
    store.dispatch("State/setLoadingStatus", true);
  }
  if (store.getters["State/getTimeoutStatus"]) {
    store.dispatch("State/setTimeoutStatus", false);
  }
  // handle document title and meta
  handleDocumentMeta(to.meta);
  if (to.meta.dp_status) {
    const { dp_status } = to.meta;
    let is_maintenance = false;
    for (let i = 0; i < dp_status.length; i++) {
      if (MAINTENANCE_STATUS_ROUTES[dp_status[i]]) {
        is_maintenance = true;
        break;
      }
    }
    if (is_maintenance) next({ path: "/maintenance" });
  }

  // Handle UTM
  const { utm_campaign, utm_medium, utm_source, utm_content, utm_term } = to.query;

  const now = await Time.getTime().then(response => response.data.timestamp);
  if (utm_campaign || utm_medium || utm_source || utm_content || utm_term) {
    const existedUTM = getLSObjectWithExpiry("utm", now);
    const expired_at = now + 3 * 24 * 3600 * 1000;
    // const expired_at = now + 0;
    if (existedUTM.expired) {
      setLSObjectWithExpiry(
        "utm",
        {
          utm_campaign: utm_campaign || "",
          utm_medium: utm_medium || "",
          utm_source: utm_source || "",
          utm_content: utm_content || "",
          utm_term: utm_term || ""
        },
        expired_at
      );
    }
  }

  // Handle verification from SSO
  const { notify_otp, trial_otp, trial_referral } = to.query;
  const data = {};
  if (notify_otp && getUserId()) {
    // save query data only happen if there are flag 'notify_otp' this is handle notify activation from SSO page
    if (trial_otp) data.trial_otp = trial_otp;
    if (trial_referral) data.trial_referral = trial_referral;
    if (trial_otp && trial_referral) setLSItem(`modal-notify:${getUserId()}`, JSON.stringify(data));
  }

  if (token) {
    const existedUTM = getLSObjectWithExpiry("utm", now);
    if (!existedUTM.expired) {
      await User.createUTMHistory(existedUTM.data);
      removeLSItem("utm");
    }

    if (!checkUserTrial()) {
      saveDataTrial();
    }

    // if has token
    if (to.matched.some(record => record.meta.requires_auth)) {
      // if require auth, check auth
      await requireToken(token, to);

      // Check trial user and destination router can be accessed by trial user
      if (checkUserTrial()) {
        if (!to.meta.whitelist_trial) {
          next({ name: "home" });
        } else {
          next();
        }
      } else {
        // Normal user
        next();
      }
    } else {
      if (to.matched.some(record => record.meta.must_not_login)) {
        // url for havent login
        next({ path: "/dashboard" });
      } else {
        // otherwise, next
        next();
      }
    }
  } else {
    /* has no token*/
    if (to.matched.some(record => record.meta.requires_auth)) {
      window.location = `${process.env.VUE_APP_SSO}/auth/login?redirect=${
        window.location.origin
      }&options=${JSON.stringify({
        redirect: to.path,
        options: JSON.stringify(to.query)
      })}`;
    } else {
      next();
    }
  }
});

// LOADING PER PAGE BY VUEX
// router.afterEach(() => {
//   // finish progress bar
//   // setTimeout(() =>  store.dispatch('State/setLoadingStatus'), 1000);
// })
