// import { NotificationProgrammatic as Notification } from 'buefy'

const firebase = window.firebase;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  const messaging = firebase.messaging?.isSupported() ? firebase.messaging() : null;

  if (messaging) {
    messaging.onMessage(payload => {
      const { Notification } = window;
      const { body, image, link, title } = payload.data;
      var notify = new Notification(title, {
        body: body,
        icon: image,
        data: {
          url: link
        }
      });
      notify.addEventListener("notificationclick", function(event) {
        const url = event.notification.data.url;
        event.notification.close();
        event.waitUntil(
          window.clients.matchAll({ type: "window" }).then(windowClients => {
            // Check if there is already a window/tab open with the target URL
            for (let i = 0; i < windowClients.length; i++) {
              const client = windowClients[i];
              // If so, just focus it.
              if (client.url === url && "focus" in client) {
                return client.focus();
              }
            }
            // If not, then open the target URL in a new window/tab.
            if (window.clients.openWindow) {
              return window.clients.openWindow(url);
            }
          })
        );
      });
    });
  }
};

export const getToken = () => {
  const messaging = firebase.messaging?.isSupported() ? firebase.messaging() : null;
  if (messaging) {
    return messaging.getToken({
      vapidKey: process.env.VUE_APP_FIREBASE_PUBLIC_KEY
    });
  }
  return false;
};

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken(); // eslint-disable-line
  } catch (error) {
    console.error(error);
  }
};
