import service from "@/utils/request";

export default class General {
  static baseGroupURL = "general";

  static async getCity() {
    const res = service.get(`${this.baseGroupURL}/city`);
    return res;
  }

  static async getMajor(params) {
    const res = service.get(`${this.baseGroupURL}/major`, { params });
    return res;
  }

  static async getMajorCategory(params) {
    const res = service.get(`${this.baseGroupURL}/major/category/name`, { params });
    return res;
  }

  static async getProvince() {
    const res = service.get(`${this.baseGroupURL}/province`);
    return res;
  }

  static async getCampus(params) {
    const res = service.get(`${this.baseGroupURL}/campus`, { params });

    return res;
  }

  static async getSchool(params) {
    const res = service(`${this.baseGroupURL}/school`, {
      params
    });
    return res;
  }

  static async getStatMajor(id) {
    const res = service.get(`${this.baseGroupURL}/major/statistic/recent?ids=${id}`);
    return res;
  }

  static async getPersonalityByIdentifier(id) {
    const res = service.get(`${this.baseGroupURL}/personality/${id}`);
    return res;
  }

  static async getPersonalityMajorByIdentifier(id) {
    const res = service.get(`${this.baseGroupURL}/personality/${id}/major`);
    return res;
  }

  static async calculateMajorChance(id_major, normalized_score) {
    const res = service.post(`${this.baseGroupURL}/major/chance`, { id_major, normalized_score });
    return res;
  }

  static async AddPartnershipSchool(data) {
    const res = service.post(`${this.baseGroupURL}/school/partnership`, data);
    return res;
  }

  static async getFunFact() {
    const res = service.get(`${this.baseGroupURL}/fun-fact`);
    return res;
  }

  static async getProblemSubmission(params) {
    const res = service.get(`${this.baseGroupURL}/problem-submission/description`, { params });
    return res;
  }

  static async postProblemSubmission(data) {
    const res = service.post(`${this.baseGroupURL}/problem-submission`, data);
    return res;
  }

  static async getMajorStatistic(params) {
    const res = service.get(`${this.baseGroupURL}/major/statistic`, { params });
    return res;
  }

  static async getListOfMajor(params) {
    const res = service.get(`${this.baseGroupURL}/major`, { params });
    return res;
  }

  static async getMajorCategoryName(params) {
    const res = service.get(`${this.baseGroupURL}/major/category/name`, { params });
    return res;
  }
}
