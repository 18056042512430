<script>
/**
 * TODO:
 * [] 31/03/2021 : get state from parent component (Field especially)
 */
export default {
  name: "CustomInput",
  props: {
    value: null,
    type: {
      type: String,
      default: "text"
    },
    disabled: Boolean,
    state: {
      type: String,
      validator: value => {
        return ["first", "danger", "success"].indexOf(value) !== -1;
      },
      default: "first"
    },
    placeholder: String
  },
  computed: {
    inputClass() {
      const { danger, input, success } = this.$style;
      switch (this.state) {
        case "danger":
          return [input, danger];
        case "success":
          return [input, success];
        case "first":
        default:
          return input;
      }
    }
  },
  methods: {
    emitValue(e) {
      this.$emit("input", e.target.value);
    }
  }
};
</script>

<template>
  <input
    :value="value"
    :type="type"
    :placeholder="placeholder"
    :class="inputClass"
    @input="emitValue"
  />
</template>

<style module lang="scss">
.input {
  font-family: $nunito;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0.1em 0.25em rgba(56, 40, 40, 0.15);
  transition: all 0.2s ease;
  &:focus {
    outline: none;
    box-shadow: inset 0 0.1em 0.25em rgba($orange, 0.25),
      0 0 0.25em rgba($orange, 1);
  }
}

.success {
  &.input {
    border: 1px solid $correct;
    &:focus {
      box-shadow: inset 0 0.1em 0.25em rgba(0, 0, 0, 0.15),
        0 0 0.25em rgba($correct, 1);
    }
  }
}

.danger {
  &.input {
    border: 1px solid #f14668;
    &:focus {
      box-shadow: inset 0 0.1em 0.25em rgba(0, 0, 0, 0.15),
        0 0 0.25em rgba(#f14668, 1);
    }
  }
}
</style>
