const state = {
  is_signed: false,
  user_id: "",
  fullname: localStorage.getItem("fullname") || "",
  userClaim: 0,
  userCoin: 0,
  userStatus: {},
  userAvatar: {}
};

// getters
const getters = {
  getSignedStatus: state => state.is_signed,
  getUserID: state => state.user_id,
  getFullname: state => state.fullname,
  getUserClaim: state => state.userClaim,
  getUserCoin: state => state.userCoin,
  getUserStatus: state => state.userStatus,
  getUserAvatar: state => state.userAvatar
};

// mutations
const mutations = {
  toggleSignedStatus(state) {
    state.is_signed = !state.is_signed;
  },
  changeUserID(state, id) {
    state.user_id = id;
  },
  changeFullname(state, fullname) {
    state.fullname = fullname;
  },
  setClaim(state, claim) {
    state.userClaim = claim;
  },
  setUserCoin(state, payload) {
    state.userCoin = payload;
  },
  setUserStatus(state, payload) {
    state.userStatus = payload;
  },
  setUserAvatar(state, payload) {
    state.userAvatar = payload;
  }
};

// const actions = {
//   setUserCoin({ commit }, val) {
//     commit("setUserCoin", {
//       val
//     });
//   },
//   async fetchCoin({ commit }) {
//     if (getLastCoin()) {
//       let coin = parseInt(getLastCoin());
//       commit("setUserCoin", {
//         val: coin
//       });
//     } else {
//       let coin_res = await Prize.getUserCoin().then(res => res.data.data);
//       commit("setUserCoin", {
//         val: coin_res ? coin_res.total_coin : 0
//       });
//     }
//   }
// };

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
