<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import * as htmlToImage from "html-to-image";

import BottomBar from "./components/BottomBar";
import Breadcrumb from "./components/Breadcrumbs";
import FooterWeb from "./components/Footer";
import FloatingWhatsapp from "./components/FloatingWhatsapp.vue";
import ModalPomodoroResting from "./components/Modal/ModalPomodoroResting";
import Navbar from "./components/Navbar";
import NavbarLanding from "./components/NavbarLanding";
import ReferralDownload from "@/components/modal/ModalDownload/Referral";
import TimerScheduling from "./components/TimerScheduling";
import ModalLogin from "./components/Modal/ModalLogin";
import ModalSaveProgress from "./components/Modal/ModalSaveProgress";

import { getToken } from "@/utils/auth";
import { getUserId, checkUserTrial } from "@/utils/user";
import { getLsObject, setLSObjectWithExpiry, getLSObjectWithExpiry } from "@/utils/general";

import Game from "@/api/game";
import Order from "@/api/order.js";

import { TRIAL_ACTIVE_PERIOD } from "@/constants/trial";

export default {
  name: "Layout",
  components: {
    FloatingWhatsapp,
    BottomBar,
    Breadcrumb,
    ConnectionTimeout: () => import("./components/ConnectionTimeout"),
    FooterWeb,
    LogoutConfirmation: () => import("./components/LogoutConfirmation"),
    NavbarLanding,
    Navbar,
    ModalPomodoroResting,
    ReferralDownload,
    TimerScheduling,
    ModalLogin,
    ModalSaveProgress
  },
  computed: {
    ...mapGetters({
      userAvatar: "User/getUserAvatar",
      statusTimer: "State/getStatusTimer",
      remainTimer: "State/getRemainTimer",
      durationTimer: "State/getDurationTimer",
      pomodoroTimer: "State/getPomodoroTimer",
      userReferral: "Referral/getUserReferral",
      referralDownloadStatus: "Referral/getReferralDownloadStatus",
      showModalLoginFree: "State/isShowModalLoginFree",
      showModalSaveProgress: "State/isShowModalSaveProgress"
    }),
    containerClass() {
      // Default Page = page with navbar
      let arrayClass = ["page"];

      if (this.isLogin) {
        if (this.isSchool) {
          arrayClass.push("page--landing");
        } else {
          if (this.hideNavbarPhone) {
            if (this.isTimerOn) {
              arrayClass.push("page--hide-navbar-phone--with-timer");
            } else {
              arrayClass.push("page--hide-navbar-phone");
            }
          } else {
            if (this.isTimerOn) {
              arrayClass.push("page--with-timer");
            }
          }
        }
      } else {
        arrayClass.push("page--landing");
      }

      if (this.mainPage) arrayClass.push("page--use-bottom-bar");
      if (this.backgroundColor) arrayClass.push(`page-background--${this.backgroundColor}`);
      return arrayClass;
    }
  },
  data() {
    return {
      isLogin: true,
      useFooter: false,
      useBreadcrumb: true,
      mainPage: true,
      navbarType: true,
      hideLogin: false,
      hideNavbarPhone: false,
      hideBackButton: false,
      hideBottomBar: false,
      hidePageName: false,
      mobileNavbar: "default",
      backgroundColor: "default",
      loading: true,
      pageName: "Buwhan Edu",
      isSecondaryNavbar: false,
      innerWidth: 1280, // if initial value for innerWidth is below phone size (426px) navbar in desktop won't show unless there are width's changes
      isSchool: false,
      isTimerOn: false,
      isTrial: false,
      timer: null,
      idEvent: 0,
      eventTime: 0,
      hideHelp: false,
      showPromo: false,
      isMusicOn: false,
      showModalResting: false,
      isLongBreak: false,
      durationModal: 0,
      showNotification: false,

      useWhatsapp: false,
      hideWhatsappMobile: false
    };
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.isLogin = !!getToken() && !!getUserId();
    if (this.isLogin) {
      this.fetchName();

      this.isTrial = checkUserTrial();
      if (this.isTrial) {
        this.getDataTimerTrial();
      } else {
        await this.getDataTimer();
      }

      this.getPromo();
    }
    this.loading = false;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapMutations({
      setStatusTimer: "State/setStatusTimer",
      setRemainTimer: "State/setRemainTimer",
      setDurationTimer: "State/setDurationTimer",
      setPomodoroTimer: "State/setPomodoroTimer",
      setReferralDownloadStatus: "Referral/setReferralDownloadStatus",
      setModalLoginFree: "State/setModalLoginFree",
      setModalSaveProgress: "State/setModalSaveProgress"
    }),
    ...mapActions({
      fetchName: "State/fetchName"
    }),
    setMusic(value) {
      this.isMusicOn = value;
    },
    setModalResting(value, isLongBreak) {
      this.showModalResting = value;
      this.isLongBreak = isLongBreak;
    },
    setDurationModal(duration) {
      this.durationModal = duration;
    },
    async getPromo() {
      try {
        const params = {
          pageSize: 10,
          sortBy: "updated_at",
          order: "desc"
        };
        const res = await Order.getUserPromo(params).then(res => res.data.data);
        if (res.length > 0) {
          const recentPromo = res[0];
          const promoCache = getLsObject(`show-promo:${getUserId()}`);
          if (
            !promoCache ||
            (recentPromo.updated_at > promoCache.updated_at &&
              promoCache.id_promo !== recentPromo.id_promo)
          ) {
            this.showPromo = true;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    async getDataTimer() {
      try {
        // const dataTimer = await Game.getTimerUser().then(res => res.data.data);
        // Change timer data response to default null value
        const dataTimer = null;
        if (dataTimer) {
          const { remain_time, is_paused, id_event, event_time, is_pomodoro, duration } = dataTimer;
          this.setDurationTimer(duration);
          this.setRemainTimer(remain_time);
          this.idEvent = id_event;
          this.eventTime = event_time;
          if (is_pomodoro) {
            this.setPomodoroTimer(true);
          }

          if (this.remainTimer > 0) {
            if (is_paused) {
              this.setStatusTimer("pause");
            } else {
              this.setStatusTimer("play");
            }
          } else {
            this.setStatusTimer("stop");
          }
        } else {
          const date = new Date();
          if (getLSObjectWithExpiry("is-timer-closed", date.getTime()).expired) {
            this.setStatusTimer("");
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    getDataTimerTrial() {
      try {
        let dataTimer;
        const date = new Date();
        const { data, expired } = getLSObjectWithExpiry("trial:timer", date.getTime());
        if (!expired) {
          if (data["duration"] && data["status"]) {
            data["remain_time"] = data["last_start"]
              ? data["remain_time"] - (date.getTime() - data["last_start"])
              : data["remain_time"];
            dataTimer = { ...data };
          } else {
            dataTimer = null;
          }
        } else {
          dataTimer = null;
        }

        if (dataTimer) {
          const { remain_time, is_paused, id_event, event_time, is_pomodoro, duration } = dataTimer;
          this.setDurationTimer(duration);
          this.setRemainTimer(remain_time);
          this.idEvent = id_event;
          this.eventTime = event_time;
          if (is_pomodoro) {
            this.setPomodoroTimer(true);
          }

          if (this.remainTimer > 0) {
            if (is_paused) {
              this.setStatusTimer("pause");
            } else {
              this.setStatusTimer("play");
            }
          } else {
            this.setStatusTimer("stop");
          }
        } else {
          const date = new Date();
          if (getLSObjectWithExpiry("is-timer-closed", date.getTime()).expired) {
            this.setStatusTimer("");
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    closeTimer() {
      this.setStatusTimer("");

      if (this.isTrial) {
        const date = new Date();
        let { data } = getLSObjectWithExpiry("trial:timer", date.getTime());
        data["status"] = "";

        setLSObjectWithExpiry("trial:timer", data, date.getTime() + TRIAL_ACTIVE_PERIOD);
      }
    },
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    async handleActionTimerHandler(action) {
      if (this.isTrial) {
        this.handleActionTimerTrial(action);
      } else {
        await this.handleActionTimer(action);
      }
    },
    async handleActionTimer(action) {
      if (action === "play") {
        // Call API
        try {
          await this.getDataTimer();

          const res = await Game.pauseEventTimer(this.idEvent, this.eventTime).then(
            res => res.data.data
          );

          this.setRemainTimer(res.remain_time);
          this.setStatusTimer("pause");
        } catch (e) {
          console.error(e);
        }
      } else if (action === "pause") {
        // Call API
        try {
          await this.getDataTimer();

          const remain_time = await Game.startEventTimer(this.idEvent, this.eventTime).then(
            res => res.data.data.remain_time
          );
          this.setRemainTimer(remain_time);
          this.setStatusTimer("play");
        } catch (e) {
          console.error(e);
        }
      } else if (action === "stop") {
        // Call API
        try {
          await this.getDataTimer();

          const res = await Game.stopEventTimer(this.idEvent, this.eventTime).then(
            res => res.data.data
          );

          this.setRemainTimer(res.remain_time);
          this.setPomodoroTimer(false);
          this.setStatusTimer("stop");
        } catch (e) {
          console.error(e);
        }
      }
    },
    async handleActionTimerTrial(action) {
      if (action === "play") {
        // Pause Timer
        let res;
        // Set Remain Time
        const date = new Date();
        let { data } = getLSObjectWithExpiry("trial:timer", date.getTime());

        // calculate remain
        data["remain_time"] = data["remain_time"] - (date.getTime() - data["last_start"]);
        data["status"] = "pause";
        data["is_paused"] = 1;
        data["last_start"] = 0;

        setLSObjectWithExpiry("trial:timer", data, date.getTime() + TRIAL_ACTIVE_PERIOD);
        res = { remain_time: data["remain_time"] };

        this.setRemainTimer(res.remain_time);
        this.setStatusTimer("pause");
      } else if (action === "pause") {
        // Play Timer
        let remain_time;
        // Get Remain Time
        const date = new Date();
        let { data } = getLSObjectWithExpiry("trial:timer", date.getTime());

        remain_time = data["remain_time"];
        data["status"] = "play";
        data["is_paused"] = 0;
        data["last_start"] = date.getTime();

        setLSObjectWithExpiry("trial:timer", data, date.getTime() + TRIAL_ACTIVE_PERIOD);

        this.setRemainTimer(remain_time);
        this.setStatusTimer("play");
      } else if (action === "stop") {
        let res;
        const date = new Date();
        let { data } = getLSObjectWithExpiry("trial:timer", date.getTime());

        data["remain_time"] = 0;
        data["status"] = "stop";
        data["last_start"] = 0;
        data["is_pomodoro"] = false;
        setLSObjectWithExpiry("trial:timer", data, date.getTime() + TRIAL_ACTIVE_PERIOD);

        res = { remain_time: 0 };

        // Change localstorage data to finished
        let dataSchedule = getLSObjectWithExpiry("trial:scheduling", date.getTime());
        let { data: events } = dataSchedule;

        const key = Object.keys(events)[0];
        let event = events[key][0];
        events[key] = [{ ...event, is_finished: true, duration: 0 }];
        setLSObjectWithExpiry("trial:scheduling", events, date.getTime() + TRIAL_ACTIVE_PERIOD);

        this.setRemainTimer(res.remain_time);
        this.setPomodoroTimer(false);
        this.setStatusTimer("stop");
      }
    },
    async downloadReferral() {
      const el = document.getElementById("referral-download");
      const name = this.userAvatar.name.split(" ").join("");
      await htmlToImage.toPng(el).then(dataUrl => {
        var link = document.createElement("a");
        link.download = `${name}_referral_share.png`;
        link.href = dataUrl;
        link.click();
        link.remove();
      });
      // Game.addUserShare();
      this.setReferralDownloadStatus(false);
    }
  },
  watch: {
    statusTimer(newVal) {
      if (newVal) {
        this.isTimerOn = true;
        if (newVal === "play") {
          this.timer = setInterval(() => {
            if (this.remainTimer <= 0) {
              const date = new Date();
              setLSObjectWithExpiry("is-timer-closed", false, date.getTime() + 3600000);
              clearTimeout(this.timer);
              this.setStatusTimer("stop");
            } else {
              this.setRemainTimer(this.remainTimer - 1000);
            }
          }, 1000);
        } else if (newVal === "pause") {
          clearInterval(this.timer);
        } else if (newVal === "stop") {
          const date = new Date();
          setLSObjectWithExpiry("is-timer-closed", false, date.getTime() + 3600000);
        }
      } else {
        localStorage.removeItem("is-timer-closed");
        this.isTimerOn = false;
      }
    },
    async referralDownloadStatus(newVal) {
      if (newVal) {
        await this.downloadReferral();
      }
    },
    async isLogin(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.fetchName();
      }
      if (newVal) {
        // Only trigger once when user login (first time user has token)
        this.isTrial = checkUserTrial();

        if (this.isTrial) {
          this.getDataTimerTrial();
        } else {
          await this.getDataTimer();
        }

        this.getPromo();
      }
    },
    async $route(to) {
      this.isLogin = !!getToken();
      this.isSchool = to.name === "school-landing";
      this.useFooter =
        to.meta.type_footer !== "none" && (!this.isLogin || to.meta.type_footer === "show");
      this.useBreadcrumb = to.meta.use_breadcrumb == "show";
      this.backgroundColor = to.meta.background_color ? to.meta.background_color : "default";
      this.mainPage = to.meta.type_page === "main";
      this.hideLogin = to.meta.hide_login;
      const {
        title_params,
        page_name,
        is_secondary_navbar,
        type_navbar,
        hide_navbar_phone,
        mobile_navbar,
        hide_back_button,
        hide_bottom_bar,
        hide_page_name,
        hide_help,
        show_notification,
        use_whatsapp,
        hide_whatsapp_mobile
      } = to.meta;

      this.hideHelp = hide_help;
      this.pageName = (title_params ? await page_name(to) : page_name) || "Buwhan Edu";
      this.isSecondaryNavbar = is_secondary_navbar;
      this.navbarType = type_navbar;
      this.hideNavbarPhone = hide_navbar_phone;
      this.hidePageName = hide_page_name;
      this.hideBackButton = hide_back_button;
      this.hideBottomBar = hide_bottom_bar;
      this.mobileNavbar = mobile_navbar || "default";
      this.showNotification = show_notification;
      this.useWhatsapp = use_whatsapp;
      this.hideWhatsappMobile = hide_whatsapp_mobile;
    },
    // innerWidth(val) {
    //   if (val <= 600) {
    //     $crisp.push(["do", "chat:hide"]); // eslint-disable-line

    //     // If opened, callback to show purple a few milisecond button (MANDATORY, so chat can be opened)
    //     // eslint-disable-next-line
    //     $crisp.push([
    //       "on",
    //       "chat:opened",
    //       () => {
    //         $crisp.push(["do", "chat:show"]); // eslint-disable-line
    //       }
    //     ]);

    //     // After closed, callback to hide purple button
    //     // eslint-disable-next-line
    //     $crisp.push([
    //       "on",
    //       "chat:closed",
    //       () => {
    //         $crisp.push(["do", "chat:hide"]); // eslint-disable-line
    //       }
    //     ]);
    //   } else {
    //     $crisp.push(["do", "chat:show"]); // eslint-disable-line
    //   }
    // }
  }
};
</script>

<template>
  <div :class="containerClass">
    <template
      v-if="
        !loading &&
        navbarType !== 'none' &&
        (navbarType !== 'hide-on-phone-size' ||
          (navbarType === 'hide-on-phone-size' && innerWidth > 426))
      "
    >
      <NavbarLanding v-if="!isLogin || isSchool" :hide-login="hideLogin" />
      <template v-else>
        <TimerScheduling
          v-if="isTimerOn && isLogin"
          class="timer-mobile"
          :status-timer="statusTimer"
          :remain="remainTimer"
          :duration="durationTimer"
          :is-music-on="isMusicOn"
          @handle="handleActionTimerHandler"
          @setMusic="setMusic"
          @setModal="setModalResting"
          @setDurationModal="setDurationModal"
          @close="closeTimer"
        />
        <Navbar
          :width="innerWidth"
          :login-status="isLogin"
          :hide-back-button="hideBackButton"
          :centered-items="!isLogin"
          :page-name="pageName"
          :hide-page-name="hidePageName"
          :is-secondary="isSecondaryNavbar"
          :is-trial="isTrial"
          :is-sticky="navbarType === 'sticky-on-phone-size'"
          :hide-burger-button="isLogin"
          :hide-navbar-phone="hideNavbarPhone"
          :mobile-navbar="mobileNavbar"
          :class="isTimerOn ? 'navbar-with-timer' : 'navbar'"
          :hide-help="hideHelp"
          :show-promo="showPromo"
          :show-notification="showNotification"
        />
        <TimerScheduling
          class="timer"
          v-if="isTimerOn && isLogin"
          :remain="remainTimer"
          :duration="durationTimer"
          :status-timer="statusTimer"
          :is-music-on="isMusicOn"
          @handle="handleActionTimerHandler"
          @setMusic="setMusic"
          @setModal="setModalResting"
          @setDurationModal="setDurationModal"
          @close="closeTimer"
        />
      </template>
    </template>
    <Breadcrumb v-if="useBreadcrumb" />
    <slot></slot>

    <ModalPomodoroResting
      :show="showModalResting"
      :remain="durationModal"
      :is-long-break="isLongBreak"
      @close="showModalResting = false"
    />
    <BottomBar
      v-if="mainPage && isLogin && !hideBottomBar"
      :show-promo="showPromo"
      :is-trial="isTrial"
    />
    <template>
      <FooterWeb v-if="useFooter" />
    </template>
    <ConnectionTimeout />
    <LogoutConfirmation />
    <div class="download_content">
      <ReferralDownload :referral="userReferral" id="referral-download" />
    </div>
    <FloatingWhatsapp v-if="useWhatsapp" :hide-on-mobile="hideWhatsappMobile" />

    <ModalLogin :show="showModalLoginFree" type="login" @close="setModalLoginFree(false)" />

    <ModalSaveProgress :show="showModalSaveProgress" @close="setModalSaveProgress(false)" />
  </div>
</template>

<style lang="scss" scoped>
$timer-height: 3.5rem;
$timer-mobile-height: 2.5rem;

.page {
  padding-top: 3.8rem;
  min-height: 100vh;

  &.page--hide-navbar-phone {
    @include max-media(mobile) {
      padding-top: 0;
    }

    &--with-timer {
      padding-top: 7.5rem;

      @include max-media(mobile) {
        padding-top: 2.5rem;
      }
    }
  }

  &--with-timer {
    padding-top: 7.5rem;

    @include max-media(mobile) {
      padding-top: 6.3rem;
    }
  }

  &-with-timer {
    padding-top: 7.5rem;

    @include max-media(mobile) {
      padding-top: 6.3rem;
    }
  }

  &--landing {
    padding-top: 0;
  }

  &--non-navbar {
    @include max-media(mobile) {
      padding-top: 0;
    }
    &--with-timer {
      padding-top: 2.5rem;
    }
  }

  &--use-bottom-bar {
    @include max-media(tablet) {
      padding-bottom: 52px;
    }
  }
  &__wrapper-content {
    min-height: 100vh;
  }
  &-background {
    &--light-blue {
      background-color: #f5f7f9;
    }
    &--grey {
      background-color: #f7f7f7;
    }
    &--light-grey {
      background-color: #f5f7f9;
    }
    &--light-orange {
      background-color: #fef3d7;
    }
  }

  .timer {
    height: $timer-height;
    position: fixed;
    top: 4rem;

    &-mobile {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 4004;
      height: $timer-mobile-height;

      @include max-media(mobile) {
        display: block;
      }
    }

    @include max-media(mobile) {
      display: none;
    }
  }

  .navbar {
    top: 0;

    &-with-timer {
      @include max-media(mobile) {
        top: $timer-mobile-height;
      }
    }
  }

  .download_content {
    position: fixed;
    top: 200%;
    z-index: 10000;

    // 16:9 ratio download
    height: 800px;
    width: 450px;
  }
}
</style>
