<script>
import { mapMutations } from "vuex";

import Notification from "@/api/notification";
import { NOTIFICATION_MENU, NOTIFICATION_ICON } from "@/constants/notification";

import { millisecondToTime } from "@/utils/datetime";
export default {
  name: "NotificationTabNavbar",
  props: {
    timestamp: Number,
    notificationUnreadCount: Number,
    internalInfoUnreadCount: Number,
    categoryList: {
      type: Array,
      default: () => []
    },
    notificationList: {
      type: Array,
      default: () => []
    },
    internalInfoList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // constants
      NOTIFICATION_MENU,
      NOTIFICATION_ICON,
      // configuration
      currentTab: "notification" // notification or internal-info
    };
  },
  computed: {
    displayedNotification() {
      if (this.currentTab == "notification" && this.notificationList.length > 0) {
        return this.notificationList;
      } else if (this.currentTab == "internal-info" && this.internalInfoList.length > 0) {
        return this.internalInfoList;
      }
      return [];
    }
  },
  methods: {
    ...mapMutations({
      setInternalInfoList: "Notification/setInternalInfoList",
      setNotificationList: "Notification/setNotificationList"
    }),
    getCategoryIcon(id_category) {
      const category = this.categoryList.find(el => el.id_category == id_category);
      if (category) return NOTIFICATION_ICON.get(category.name);
      return "";
    },
    generateTimeHistory(time) {
      let elapsedTime = this.timestamp - time;
      const { days, hours, minutes } = millisecondToTime(elapsedTime);

      if (days > 0) {
        if (days > 99) {
          return "99+ hari lalu";
        } else if (days === 1) {
          return "Kemarin";
        } else {
          return `${days} hari lalu`;
        }
      }

      if (hours > 0) {
        return `${hours} jam lalu`;
      }

      if (minutes > 0) {
        return `${minutes} menit lalu`;
      }

      return `Baru saja`;
    },
    readNotification(id_notification, link) {
      Notification.readNotification(id_notification);

      // read local (VUEX)
      const targetList =
        this.currentTab === "notification" ? this.notificationList : this.internalInfoList;
      const targetIdx = targetList.findIndex(el => el.id_notification === id_notification);

      if (targetIdx >= 0) {
        // if found
        // create a copy array to update VUEX
        const newNotification = [...targetList];
        this.$set(newNotification[targetIdx], "is_read", true);

        if (this.currentTab === "notification") this.setNotificationList(newNotification);
        else this.setInternalInfoList(newNotification);
      }

      // Redirect to some link
      if (link) window.open(link, "_self");
    },
    normalizeValue(val) {
      if (val > 99) return "99+";
      else return val;
    }
  }
};
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.menu">
      <div
        @click="currentTab = item.value"
        :class="[$style.menu__item, item.value === currentTab ? $style.active : '']"
        v-for="item in NOTIFICATION_MENU"
        :key="item.value"
      >
        <p :class="$style.menu__item__text">{{ item.label }}</p>
        <div
          v-if="
            (item.value === 'notification' && notificationUnreadCount) ||
            (item.value === 'internal-info' && internalInfoUnreadCount)
          "
          :class="$style.menu__item__unread"
        >
          {{
            item.value === "notification"
              ? normalizeValue(notificationUnreadCount)
              : normalizeValue(internalInfoUnreadCount)
          }}
        </div>
      </div>
    </div>

    <div :class="$style.content">
      <div :class="$style.content__empty" v-if="displayedNotification.length === 0">
        <img
          @contextmenu.prevent
          :class="$style.content__empty__image"
          src="https://assets.edukasystem.com/mascot/burhan-no-notification.png"
          alt="empty"
        />
        <p :class="$style.content__empty__title">Belum ada notifikasi</p>
        <p :class="$style.content__empty__subtitle">
          Semua notifikasi untuk kamu akan muncul disini ya!
        </p>
      </div>

      <div :class="$style.content__list" v-else-if="currentTab === 'notification'">
        <div
          :class="[$style.content__list__notification, !item.is_read ? $style.unread : '']"
          v-for="(item, idx) in displayedNotification"
          :key="idx"
          @click="readNotification(item.id_notification, item.link)"
        >
          <div :class="$style.dot"></div>
          <svg-icon :icon-class="getCategoryIcon(item.id_category)" :class="$style.icon" />
          <div :class="$style.body">
            <div :class="$style.body__top">
              <p :class="$style.body__top__title">{{ item.title }}</p>
              <div :class="$style.body__top__right">
                <p :class="$style.body__top__time">{{ generateTimeHistory(item.created_at) }}</p>
              </div>
            </div>
            <p :class="$style.body__desc">{{ item.description }}</p>
          </div>
        </div>
      </div>

      <div :class="$style.content__list" v-else-if="currentTab === 'internal-info'">
        <div
          :class="[$style.content__list__info, !item.is_read ? $style.unread : '']"
          v-for="(item, idx) in displayedNotification"
          :key="idx"
          @click="readNotification(item.id_notification, item.link)"
        >
          <div :class="$style.dot"></div>
          <div :class="$style.body">
            <div :class="$style.body__top">
              <p :class="$style.body__top__title">{{ item.title }}</p>
              <div :class="$style.body__top__right">
                <p :class="$style.body__top__time">{{ generateTimeHistory(item.created_at) }}</p>
              </div>
            </div>
            <div :class="$style.body__bottom">
              <p :class="$style.body__desc">{{ item.description }}</p>
              <img
                @contextmenu.prevent
                :class="$style.body__image"
                v-if="item.image_url"
                :src="item.image_url"
                :alt="`image-${idx}`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      class="btn btn-secondary"
      @click="$router.push({ name: 'notification' })"
      :class="$style.action"
    >
      Lihat semua notifikasi
    </button>
  </div>
</template>

<style lang="scss" module>
.container {
  width: 100%;
}

.menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: white;

  &__item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 1.5rem 1rem 0.5rem;
    border-bottom: 3px solid white;

    @include min-media(desktop) {
      padding: 0 1rem 0.5rem;
    }

    &__text {
      font-size: 0.875rem;
      color: #676767;

      @include min-media(desktop) {
        font-size: 1rem;
      }
    }

    &__unread {
      width: 1.5rem;
      height: 1.5rem;
      background: $purple;
      border-radius: 50%;
      font-size: 0.625rem;
      font-weight: bold;
      color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      @include min-media(desktop) {
        font-size: 0.875rem;

        width: 2rem;
        height: 2rem;
      }
    }

    &.active {
      border-bottom: 3px solid #7250ec;
      .menu__item__text {
        font-weight: bold;
        color: $purple;
      }
    }
  }
}

.content {
  margin-top: 0.5rem;

  max-height: 440px;
  overflow: auto;

  &__empty {
    padding: 1rem;

    @include min-media(desktop) {
      background: #f5f7f9;
    }

    &__image {
      width: 100%;
      max-width: 110px;
      margin: 0 auto;
      display: block;

      @include min-media(desktop) {
        max-width: 136px;
      }
    }

    &__title {
      text-align: center;
      margin-top: 1rem;
      font-size: 0.75rem;
      font-weight: bold;

      @include min-media(desktop) {
        font-size: 1rem;
      }
    }

    &__subtitle {
      text-align: center;
      font-size: 0.75rem;
      color: #676767;

      @include min-media(desktop) {
        font-size: 1rem;
      }
    }
  }

  &__list {
    &__notification,
    &__info {
      cursor: pointer;
      position: relative;

      padding: 1rem;
      background: white;

      display: flex;
      align-items: center;
      gap: 1rem;

      transition: all 0.3s ease-in-out;
      &:not(:first-child) {
        margin-top: 0.5rem;
      }

      &.unread {
        background: #f4f1ff;

        @include min-media(desktop) {
          border-radius: 8px;
        }

        .dot {
          display: block;
          position: absolute;

          right: 1rem;
          top: 50%;

          width: 10px;
          height: 10px;
          background: #c21e63;
          border-radius: 50%;

          @include min-media(desktop) {
            width: 12px;
            height: 12px;
          }
        }
      }

      .dot {
        display: none;

        &.show {
          display: block;
          color: #676767;
          @include min-media(desktop) {
            font-size: 1.5rem;
          }
        }
      }

      .icon {
        font-size: 2.5rem;

        @include min-media(desktop) {
          font-size: 4rem;
        }
      }

      .body {
        flex: 1;
        width: 100%;
        &__top {
          display: flex;
          justify-content: space-between;
          gap: 0.25rem;

          &__title {
            font-size: 0.75rem;
            font-weight: bold;
            width: 70%;

            @include min-media(desktop) {
              font-size: 1rem;
              width: 70%;
            }
          }

          &__right {
            display: flex;
            align-items: center;
            gap: 4px;
          }
          &__time {
            font-size: 0.625rem;
            color: #676767;

            @include min-media(desktop) {
              font-size: 0.875rem;
            }
          }
        }

        &__bottom {
          @include min-media(desktop) {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            margin-top: 0.5rem;
          }
        }

        &__desc {
          font-size: 0.75rem;
          color: #676767;
          width: 90%;

          @include min-media(desktop) {
            font-size: 1rem;
            width: 100%;
          }
        }

        &__image {
          border-radius: 8px;
          display: block;
          margin: 1rem auto 0;
          width: 100%;
          max-width: 264px;

          @include min-media(desktop) {
            margin: 0 auto;
            max-width: 214px;
          }
        }
      }
    }
  }
}

.action {
  margin-top: 1.5rem;
  width: 100%;
  font-weight: bold;
}
</style>
