<script>
export default {
  name: 'Tooltip',
  props: {
    placement: {
      // [top_start, top, top_end, right_start, right, right_end, bottom_start, bottom, bottom_end, left_start, left, left_end]
      default: 'top_start',
      type: String
    }
  }
}
</script>

<template>
  <div :class="$style.tooltip">
    <div :class="$style.tooltip__content">
      <slot></slot>
    </div>
    <div :class="[$style.tooltip__hover, $style[placement]]">
      <slot name="hover"></slot>
    </div>
  </div>
</template>

<style lang="scss" module>
@import "./index.scss";
</style>
