import service from "@/utils/request";

export default class Summary {
  static baseGroupURL = "content/topic";
  static examURL = "exam";

  static async getSummary(idTopic, idChapter, idConcept) {
    const resp = await service.get(
      `${this.baseGroupURL}/${idTopic}/chapter/${idChapter}/concept/${idConcept}/summary`
    );
    return resp;
  }

  static async getSummaryByID(idTopic, idChapter, idConcept, id_link) {
    const resp = await service.get(
      `${this.baseGroupURL}/${idTopic}/chapter/${idChapter}/concept/${idConcept}/summary/${id_link}`
    );
    return resp;
  }

  static async getAllChapterSummaryLength(idTopic, idChapter) {
    const resp = await service.get(
      `${this.baseGroupURL}/${idTopic}/chapter/${idChapter}/summary/length`
    );
    return resp;
  }

  static async getSummaryProgress(params) {
    const resp = await service.get(`${this.examURL}/summary-progress`, { params });
    return resp;
  }

  static async createSummaryProgress(data) {
    const resp = await service.post(`${this.examURL}/summary-progress`, data);
    return resp;
  }

  // Interactive Summary
  static async fetchAllInteractiveSummaries(id_topic, id_chapter, id_concept) {
    const resp = await service.get(
      `${this.baseGroupURL}/${id_topic}/chapter/${id_chapter}/concept/${id_concept}/interactive-summary`
    );
    return resp;
  }

  // Concept Video
  static async fetchAllConceptVideo(id_topic, id_chapter, id_concept) {
    const resp = await service.get(
      `${this.baseGroupURL}/${id_topic}/chapter/${id_chapter}/concept/${id_concept}/concept-video`
    );
    return resp;
  }
}
