<script>
import NoticeMixins from "@/mixins/NoticeMixin.js";

export default {
  name: "Message",
  mixins: [NoticeMixins],
  computed: {
    bodyClass() {
      return [
        this.$style.body,
        this.$style[this.position],
        this.$style[this.type],
        this.show ? this.$style.show : ""
      ];
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    triggerAction() {
      this.onAction();
      this.close();
    }
  },
  watch: {
    isActive(val) {
      if (val) {
        setTimeout(() => {
          this.show = true;
        }, 200);
        if (!this.indefinite) {
          setTimeout(() => {
            this.show = false;
          }, this.duration - 200);
        }
      } else {
        this.show = false;
      }
    }
  }
};
</script>

<template>
  <div :class="bodyClass">
    <svg-icon
      v-if="type === 'is-success'"
      icon-class="check-circle-green"
      :class-name="$style.icon"
    />
    <svg-icon v-if="type === 'is-danger'" icon-class="x-circle" :class-name="$style.icon" />
    <div :class="$style.message">
      <slot name="message"></slot>
      <span v-if="!$slots.message">{{ message }}</span>
    </div>
  </div>
</template>

<style lang="scss" module>
.body {
  position: fixed;
  background: rgb(37, 37, 25);
  display: flex;
  align-items: center;
  padding: 1rem;
  width: fit-content;
  z-index: 1000001; // more than crips
  transition: all 0.2s;
  opacity: 0;
  box-shadow: 0 3px 3px 3px rgba(black, 0.15);

  &.is-bottom-right {
    bottom: 0.5rem;
    right: 1rem;
  }
  &.is-bottom-left {
    bottom: 0.5rem;
    left: 1rem;
  }
  &.is-bottom {
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.is-top-right {
    top: 0.5rem;
    right: 1rem;
  }
  &.is-top-left {
    top: 0.5rem;
    left: 1rem;
  }
  &.is-top {
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.is-bottom.show,
  &.is-bottom-right.show,
  &.is-bottom-left.show {
    bottom: 1rem;
    opacity: 1;
  }

  &.is-top.show,
  &.is-top-right.show,
  &.is-top-left.show {
    top: 1rem;
    opacity: 1;
  }

  &.is-danger {
    background: #ffeae3;
    border: 1px solid #edb19d;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    color: #932a08;
  }

  &.is-success {
    background: #eeffe8;
    border: 1px solid #c4eeb5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    color: #185704;
  }

  @include max-media(mobile) {
    max-width: 90%;
  }
}

.message {
  margin-left: 1rem;
  font-size: 0.875rem;

  @include max-media(mobile) {
    font-size: 0.75rem;
  }
}

.icon {
  font-size: 28px;
}
</style>
